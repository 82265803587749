.add-media-modal-body, .added-media-list {
  min-height: 350px;
}

.added-media-list {
  display: flex;
  flex-direction: column;

  .back-btn {
    position: absolute;
    left: 12px;
    top: 15px;
  }

  .posts-list {
    flex-basis: 0;
    flex-grow: 1;
  }

  .post {
    position: relative;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .hide-btn {
      position: absolute;
      right: 5px;
      top: 0;
    }
  }
}

.add-media-upload {
  .input-group {
    width: 0;
    flex-grow: 1;
  }

  .media-type-select.dropdown-select {
    display: block;
    margin: 0px;
    border: 1px solid #d4d4dc;
    border-radius: 3px;
  }

  .media-posted-at {
    white-space: nowrap;
    font-size: 14px;
    padding: 8px;
    text-indent: 0;
    line-height: 1.4;
    cursor: pointer;

    &.date-picker {
      border: 1px solid #d4d4dc;
      border-radius: 3px;
      color: #8A7969;
      text-align: left;
    }

    &.expanded {
      border-color: $primary;
      outline: none;
    }
  }
}