.main-header {
  font-style: normal;
  font-weight: bold;
  font-size: 39px;
  line-height: 47px;
  text-align: center;
  letter-spacing: -1px;
}

.sub-header {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 37px;
  text-align: center;
  color: black;
  opacity: 0.5;
}

.calculator-results {
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
  @include media($small){
    width: 100%;
  }

  .calculator-line-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .calculator-line-item.expanded {
    align-items: flex-start;
    .calculator-line-item-label {
      margin-top: .6rem;
      @include media($small){
        margin-top: initial;
      }
    }
  }

  .calculator-input {
    width: 70px;
    font-weight: 700;
    margin-right: 10px;
    text-align: right;
    text-indent: 0;
    padding: 0 10px;
    -moz-appearance: textfield;

    &:disabled {
      background: #f5f0ec;
      border: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }

  .calculator-summary-number {
    font-size: 2rem;
    font-weight: bold;
    @include media($small){
      font-size: initial;
    }
  }

  .calculator-summary-results-container {
    @include media($small){
      display: flex;
      flex-direction: row;
    }
  }

  .calculator-cta-container {
    position: sticky;
    bottom: 0;
    padding: 1rem;
    background: #333;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    @include media($small){
      position: fixed;
      left: 0;
    }
  }

  .calculator-cta {
    max-width: 110px;
    margin-left: 1rem;
  }

  .calculator-result-inputs {
    margin-top: .5rem;
    display: block;
  }
}
