.profile {
  @include card;
  clear: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: $brown;
  margin-bottom: 50px;
  padding: 0;
}

.user-container {
  flex: 1;
  flex-basis: 300px;
  position: relative;

  @include media($medium) {
    width: 100%;
  }

  @include media($small) {
    font-size: 14px;
  }

  // animation after click-to-reveal
  &.revealed {
    @include media($small) {
      .view-details-container {
        opacity: 0;
        z-index: 0;
      }

      .traits {
        padding: 20px;
        height: 100%;
        transition: height .5s ease-out;
      }
    }
  }
}

.profile-top-level {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-height: 100%;
  padding: 12px 20px;
  border: 1px solid $borders;

  @include media($medium) {
    flex-direction: column;
  }

  .avatar {
    height: 42px;
    width: 42px;
    min-width: 42px;
    border-radius: 42px;
  }

  .user-actions {
    text-align: right;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 8px;

    .fa-refresh {
      color: $brown;
    }

    .hide-close {
      margin-right: 15px;
      vertical-align: middle;
    }

    @include media($medium) {
      margin-top: 12px;
      margin-left: 0;
      text-align: left;
      flex-direction: column;
      gap: 4px;
    }
  }
}


.user-basics {
  display: flex;
  flex-direction: row;
}

.user-avatar {
  flex: 1;
  position: relative;
  vertical-align: middle;
  max-width: 48px;
  min-width: 48px;

  @include media($small) {
    float: none;
    padding-right: 0;
    display: block;
    text-align: center;
  }

  &__type {
    @include profile-type-badge(18px, -6px, -6px);
  }
}

.user-blurb {
  flex: 1;
  margin-left: 8px;
  padding-top: 4px;
  vertical-align: middle;
  max-height: inherit;
  line-height: 16px;

  .name {
    @include truncate($brown);
    font-size: 16px;
    font-weight: 600;
    display: block;
    max-width: 180px;
    margin-bottom: 2px;

    &.hyperlink {
      color: initial;
      @include hyperlink($brown);
    }
  }
}

.pdf-single-pager-wrapper .user-blurb .name {
  max-width: 100%;
  white-space: wrap;
}

.username {
  @include truncate($gray);

  display: inline-block;
  color: $gray;
  opacity: 0.7;
  font-weight: 600;

  &:hover {
    opacity: 1;
    color: $gray;
  }
}

.profile-action {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-direction: row;
  align-items: center;

  @include media($medium) {
    width: 100%;
  }
}

.user-details {
  box-shadow: inset 0px 1px 1px 0px rgba(236,236,236,0.9);
}

.user-details, .averages {
  padding: 20px;
  border-bottom: 1px solid $borders;
  position: relative;
}

.view-details-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.9);
  z-index: 100;
  align-items: center;
  justify-content: center;
  display: none;
  transition-duration: .3s;
  transition-timing-function: ease-out;
  @include media($small) {
    display: flex;
  }
}

.profile-link {
  color: $brown-lighter;
  border-bottom: 1px dotted $brown-lighter;
  text-decoration: none !important;

  &:hover {
    border-bottom: none;
    color: $brown-lighter;
  }
}

.mentions-list .profile-link {
  word-break: break-word;
}

.profile-stat-labels, .profile-stats {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.stat-label, .bio-label {
  @include smallcaps;
  color: $brown-lighter;
  text-align: left;
  height: 18px;
}

.stat-count {
  font-weight: 700;
  color: $brown;
  font-size: 2rem;
  text-align: left;

  .stat-count-first-line {
    font-size: inherit;
  }

  &.gradient-1 {
    color: $primary-darkest;
  }

  @include media($small) {
    font-size: 1.4rem;
  }
}

.averages {
  color: $brown-lighter;

  .stat-label {
    text-align: center;
  }

  .stat-count {
    font-size: 1rem;
    color: $brown-lighter;
  }

  .profile-stats {
    margin: 15px 0 20px 0;
  }
}

.view-details-container .view-more-details-btn {
  padding: 10px 16px;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  border-radius: 5px;
}

.engagement-percent-symbol {
  font-size: 1.2rem;
  margin-left: 2px;
}

.media-gallery {
  flex: 1;
  flex-basis: 468px;
  max-width: 600px;
  margin: 0 auto;

  @media only screen and (min-width: $wide-width-min) {
    max-width: 900px;
  }
}

.media-flex-parent {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: hidden;
}

.media-container {
  flex-basis: 33.3333%;
  max-width: 200px;
  width: 100%;

  @media only screen and (min-width: $wide-width-min) {
    max-width: 300px;
    width: 300px;
  }

  .media-image {
    position: relative;
    text-align: center;
    height: 100%;
    max-height: 200px;

    @media only screen and (min-width: $wide-width-min) {
      max-height: 300px;
      width: 100%;
    }

    .media-image-object {
      display: block;
      max-width: 200px;
      max-height: 200px;
      margin: 0 auto;
      border: 1px solid white;
      width: 100%;
      object-fit: cover;

      &:before {
        content: "Deleted image";
        display: block;
        position: absolute;
        font-size: 11px;
        padding-top: 20px;
        left: 0;
        height: calc(100%); // not sure why this works
        width: 100%;
        background: $beige;
        color: $brown-lighter;
        border: 2px dotted rgb(200, 200, 200);
        border-radius: 5px;
      }

      @media only screen and (min-width: $wide-width-min) {
        max-width: 300px;
        max-height: 300px;
        width: 100%;
      }
    }
  }
}

.media-meta {
  color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.3));
  padding: 12px 5px 5px 0px;
  font-size: 12px;
  width: 100%;
  text-align: right;
  height: 30px;

  .likes-meta, .comments-meta {
    margin-right: 10px;
    font-weight: 500;
    text-shadow: 0px 1px 1px rgba(0,0,0,.15);
  }

  .views-meta {
    font-weight: 500;
    text-shadow: 0px 1px 1px rgba(0,0,0,.15);
  }
}

.hidden-for-mobile {
  @include media($small) {
    display: none;
  }
}

.traits {
  clear: left;
  padding: 20px;
  color: $brown-lighter;
  @include media($small) {
    height: 0; //View details to show
    padding: 0;
    overflow: hidden;
  }
  li {
    text-align: left;
    display: block;
    padding: 15px 0;

    &:last-child {
      border-bottom: none;
    }
  }

  li.editable {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .editable-wrapper {
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media($small) {
        max-width: 240px;
      }
    }

    .stat-tooltip {
      margin-top: 0;
      flex-shrink: 0;
    }

    &.editable-emails {
      align-items: start;
    }
  }
}

.email-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .email-item-texts {
    max-width: 175px;
    @include truncate();
  }
}

.external-link {
  @include truncate($brown-lighter);
  max-width: 350px;

  a {
    color: $brown-link;
    border-bottom: 1px dotted $brown-link;

    &:hover {
      color: $brown-link;
      border-bottom: none;
    }
  }
}

.sort-action {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 20px;
}

.stat-tooltip {
  @include smallcaps;
  float: right;
  color: $brown-lighter;
}

p.tagline {
  font-size: 14px;
  padding-bottom: 15px;
  max-width: 356px;
  word-break: break-word;
}

.ad-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0,0,0,0.5);
  color: white;
  padding: 4px 8px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 15px;
  font-weight: 500;
  border-radius: 3px;
  letter-spacing: 0.8px;
}

.audience-container .column-header {
  padding-top: 15px;
}

.sample-report-callout {
  font-size: 15px;
  color: $gray;
  line-height: 42px;
}

.detail-action-button {
  @include smallcaps;
  @include hyperlink($brown-lighter);
  text-transform: none;
  cursor: pointer;
  color: $brown-lighter;
  background: $beige;
  font-weight: 700;
  padding: 6px;
  border-radius: 3px;
  border: none;
  white-space: nowrap;

  &:hover {
    background: mix($beige, black, 90%);
  }
}

.engagement-tooltip-title {
  margin-bottom: 10px;
}

.engagement-tooltip-formula-top {
  display: inline;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
}

.engagement-tooltip-formula-bottom {
  padding-top: 5px;
}

.buy-insights-modal {
  text-align: center;
  padding: 0 20px;

  .user-avatar {
    display: flex;
    justify-content: center;
    max-width: 100%;
  }

  .buy-insights-headline {
    font-size: 24px;
    font-weight: 600;
    color: $brown;
    margin: 10px 0;
  }

  .subtitle {
    color: $brown-lighter;
    font-weight: 600;
  }

  .buy-insights-actions {
    display: flex;
    margin-top: 60px;
    justify-content: flex-end;

    button {
      flex: 1;
      max-width: 190px;
      margin-right: 10px;

      &:last-child {
        margin: 0;
      }
    }
  }
}
