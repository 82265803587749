.filters-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: $card-box-shadow;
  position: fixed;
  top: 0;
  left: 200px;
  width: 100%;
  border: 1px solid $light-bg;
  background: white;
  padding-right: 200px;
  padding-left: 30px;
  font-size: 14px;
  height: 60px;
  z-index: $filtersZ;
  overflow-x: auto;

  @include media($medium) {
    left: 80px;
    padding-right: 80px;
    overflow-x: auto;
    padding-left: 0px;

    &.larger-header {
      flex-direction: column;
      height: 120px;
    }

    .action-wrapper {
      margin: 0;
      padding: 0 15px;
    }
  }

  @include media($small) {
    left: 0;
    padding-right: 0;
    padding-left: 0;
    overflow-x: scroll;
  }

  .filter-phenotype {
    flex: 1;
    border-right: 1px solid $light-bg;
    border-bottom: 1px solid $light-bg;
    text-align: left;
    padding: 10px;
    color: $brown-lighter;
    font-size: 16px;
    line-height: 16px;

    @include media($medium) {
      font-size: 13px;
      padding: 0px;
      border-bottom: none;
    }
  }

  &.no-border {
    border: none;
  }
}

.admin-highlight {
  background: #e6efc2;
}

.text-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.15s ease background;

  span {
    margin-left: 5px;
    vertical-align: middle;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &:hover {
    background-color: scale-color($beige-light, $lightness: -10%);
  }

  &:active, &:focus, &:active:focus {
    background-color: scale-color($light-bg, $lightness: -5%);
  }

  @include media($medium) {
    width: 100%;
    height: 100%;
  }
}

.filter {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.location-select {
  margin: 10px 0;
  line-height: 24px;
}

.city-field {
  margin: 10px 0;
}

.filter-tag {
  font-size: 14px;
  display: inline-block;
  background-color: $beige;
  color: $brown-lighter;
  border-radius: 3px;
  height: 28px;
  line-height: 16px;
  margin: 6px 6px 0px 0px;
  padding: 5px;
  cursor: pointer;
  border: 1px solid $beige;
  font-weight: 500;

  &:hover {
    border: 1px solid $brown;
  }

  &:active {
    border: 1px solid $beige-dark;
    background-color: $beige-dark;
  }
}

.text-select {
  cursor: pointer;

  .react-select__control {
    background-color: white;
    font-weight: bold;
    border: 2px solid rgba($brown-lighter, 0.5);
    transition: none;
    cursor: pointer;
    box-shadow: none;

    &--is-focused {
      box-shadow: none;
    }

    &:hover {
      border-color: $brown;
    }
  }

  .reach-select__input-container {
    color: $brown;
  }

  .react-select__input {
    user-select: none;
    &:focus {
      box-shadow: none;
    }
    color: $brown;
  }

  .react-select__menu {
    background-color: white;
    border: 1px solid $borders;
  }

  .react-select__option {
    background-color: white;
    z-index: 200000;
    font-weight: bold;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: $beige;
    }

    &.react-select__option--is-selected {
      background-color: $beige;
      color: $brown;
    }
  }

  .react-select__indicator-separator {
    background-color: white;
  }

  .react-select__placeholder {
    opacity: 0.5;
  }

  .react-select__placeholder,
  .react-select__single-value {
    color: $brown;
  }
}
