.star {
  width: 28px;

  @include media($small) {
    width: 22px;
  }

  &.star-empty polygon {
    fill: #389d68;
  }

  &.star-full polygon {
    fill: #fff;
  }

  .star-text {
    @include smallcaps;
    isolation: isolate;
    fill: #f0ffe3;
    font-size: 12px;
  }
}
