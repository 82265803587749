.thumb {
  display: inline-block;
  border-radius: 40px;
  vertical-align: bottom
}

.date {
  min-width: 100px;
}

.report-name {
  max-width: 300px;
  @include truncate($primary);
}

.profile-thumbs {
  min-width: 200px;
}

.more {
  max-width: 480px;
  margin-top: 80px;

  p {
    margin-bottom: 15px;
  }

  .title {
    font-weight: 600;
  }

  .small {
    font-size: 12px;
  }
}

.report-header {
  margin: 20px 0;
  font-size: 18px;
  color: $brown;
  width: 100%;
  white-space: nowrap;

  span {
    vertical-align: middle;
  }

  .header-wrapper {
    margin-right: 5px;

    @include media($small) {
      @include truncate($brown);
      display: inline-block;
      max-width: 150px;
    }
  }

  @include media($medium) {
    white-space: nowrap;
    padding: 0 15px;
  }
}

.report-metrics {
  margin-bottom: 20px;
  text-align: center;
}

.pdf-export-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  .pdf-email {
    margin-bottom: 0px;
    margin-right: 5px;
  }

  .pdf-button {
    @include button-pill($primary, 15px);
    margin-right: 5px;
    width: auto;
  }

  .media-download-button {
    @include button-inverted-pill($brown, 15px);
    width: 100%;
    margin-right: 0;
  }
}

.spreadsheet-button {
  @include button-inverted($gray, 15px);
  width: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  &.active {
    @include button-inverted($brown, 15px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:last-child {
    margin-right: 0;
  }
}

.presentation-profiles .profile {
  margin-bottom: 0;
}

.wrapper-for-nav {
  padding-top: 80px;
}

.reports-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  @include media($medium) {
    text-align: center;
  }

  .create-new {
    width: 50%;
    margin-right: 1rem;
    font-weight: 500;

    .simple {
      line-height: 32px;
      font-size: 14px;
      background: white;
    }

    @include media($small) {
      width: 100%;
      margin: 0;
    }
  }


  .search {
    width: 50%;
    margin-bottom: 0;

    @include media($medium) {
      width: 100%;
      margin-right: 0;
      margin-top: 20px;
    }

    .input-cta {
      font-size: 16px;
    }

    .evaluator-cta {
      min-width: 100px;
      flex-basis: 100px;
      font-size: 16px;
    }
  }

  @include media($medium) {
    flex-direction: column;
  }
}

.pending-imports {
  background-color: $beige;
  padding: 3px 5px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 700;
  margin-left: 3px;
}

.report-url {
  @include truncate($brown);
  max-width: 250px;
  font-size: 14px;
  font-weight: 700;
}

.slide-up-module {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  transform: translateY(100%);
  transition: transform 0.5s ease-out;

  &.show {
    transform: translateY(0);
  }
  .slide-up-module-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
  .slide-up-content {
    text-align: left;
    flex: 1;
    padding-right: 20px;
    max-width: 400px;

    h2 {
      font-size: 18px;
      margin-bottom: 15px;
      color: $brown;
    }

    .cta-button {
      @include button-pill($primary, 15px);
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
    }
  }

  .slide-up-image {
    flex: 0 0 auto;

    img {
      max-width: 300px;
      max-height: 150px;
      width: auto;
      height: auto
    }
  }
}

.fillable-page-filter-button {
  @apply border-gray-200 text-gray-600 hover:border-gray-300 hover:bg-gray-50 px-3 py-1.5 rounded border shadow-sm;
  display: flex;
  align-items: center;
  background: white;
  cursor: pointer;

  &:hover {
    @apply text-gray-700;
  }

  &:active {
    @apply text-gray-800;
  }

  &.active {
    font-weight: bold;
    border-style: solid;
  }

  &.brown {
    &.active {
      @apply border-brown text-brown;
    }

    &:hover {
      @apply border-brown text-brown;
    }
  }

  &.blue {
    &.active {
      @apply border-blue-500 text-blue-600;
    }

    &:hover {
      @apply border-blue-500 text-blue-600;
    }
  }

  &.green {
    @apply text-gray-600; // override default .green uses

    &.active {
      @apply border-primary text-primary;
    }

    &:hover {
      @apply border-primary text-primary;
    }
  }

  &.yellow {
    &.active {
      @apply border-yellow-500 text-yellow-600;
    }

    &:hover {
      @apply border-yellow-500 text-yellow-600;
    }
  }

  &.red {
    &.active {
      @apply border-red-500 text-red-600;
    }

    &:hover {
      @apply border-red-500 text-red-600;
    }
  }
}

.sortable-header {
  @include hyperlink($brown);
  display: flex;
  align-items: center;
  gap: 3px;
  transition: color .15s ease;
}