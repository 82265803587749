p {
  font-size: inherit; //reset global p style
}

.blog-callout-teaser {
  font-style: italic;
}

.blog-index {
  .email-octopus-form {
    max-width: 600px;
    margin: 0 auto;
    .field {
      font-size: 18px;
    }
  }
}

.blog-header {
  max-width: 800px;
  margin: 60px auto 90px;
  text-align: center;
  color: $brown;

  h1 {
    font-size: 60px;
    line-height: 1.2;
    letter-spacing: -1px;
    margin-bottom: .2em;
  }

  .subtitle {
    font-size: 24px;
    line-height: 1.6;
    margin-bottom: 30px;
  }

  .step-wall {
    max-width: 600px;
    margin: 0 auto;
  }
}

.blog-post {
  img {
    margin: 0 auto;
    display: block;
    padding: 24px 0;
  }
  hr {
    margin-bottom: 20px;
  }
}

.blog-post-previews-wrapper {
  margin: auto;
  max-width: 740px;
}

.blog-post-previews {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  @media screen and (max-width: 800px){
    justify-content: center;
  }
}

.blog-post-preview-container {
  min-width: 240px;
  max-width: 240px;
  margin-bottom: 2rem;
  margin-right: 1%;
  transition: opacity 0.3s ease-in-out;

  &:nth-of-type(3n) {
    margin-right: 0;
    @media screen and (max-width: 800px) {
      margin-right: 1%;
    }
  }

  &:hover {
    opacity: .75;
  }
}

.blog-post-preview {
  margin-bottom: 5%;
  overflow: hidden;
  height: 300px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  box-shadow: $card-box-shadow;

  .blog-post-index-image {
    object-fit: cover;
    object-position: center;
    height: 100%;
  }

  // background-image gets set inline in the view

  .gradient-hack {
    background: linear-gradient(180deg, rgba(2,0,36,0) 55%, rgba(0,0,0,0.8) 100%);
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.blog-post-text {
  z-index: 3;

  .title {
    color: #5B5147;
    font-weight: 600;
  }
  
  .read-more {
    color: #1dadea;
  }
  
  .read-more-chevron {
    font-size: 10px;
  }
}

.blog-post {
  max-width: 760px;
  width: 100%;
  margin: 120px auto 100px;

  .back-button {
    margin-bottom: 30px;
    font-size: 12px;
  }

  .email-octopus-form {
    .field {
      font-size: 18px;
    }
  }
}

.blog-post-headline {
  color: $brown;
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -1px;
  margin-bottom: .2em;
  @include media($small){
    font-size: 40px;
  }
}

.blog-hero-image {
  text-align: center;
  margin: 20px auto;
  max-width: 1000px;
  width: 100%;
}

.blog-video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.blog-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blog-subhead {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
  color: $brown;
  @include media($small){
    font-size: 24px;
    letter-spacing: initial;
  }
}

.author-details {
  text-align: center;
  margin: 20px 0;
}

.author-name {
  font-weight: 600;
  font-size: 16px;
}

.author-avatar {
  img {
    display: inline-block;
    max-width: 48px;
    padding: 0;
    border-radius: 50%;
    margin: auto;
  }
}

.author-title {
  opacity: 0.7;
  font-size: 16px;
}

.posted-at {
  opacity: 0.7;
  text-transform: uppercase;
  font-weight: bold;
  display: none;
}

.blog-body {
  font-size: 24px;
  color: $brown;
  position: relative;

  p, ul, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
    line-height: 1.5;
  }

  ul {
    list-style: disc outside none;
    margin-left: 2rem;
  }

  ol {
    margin-left: 2rem;
  }

  li {
    display: list-item;
  }

  h2 {
    font-size: 36px;
    margin-bottom: 6px;
    margin-top: 36px;
    line-height: 1.2;
    scroll-margin-top: 70px;
  }
  h3 {
    scroll-margin-top: 70px;
    font-size: 24px;
    margin-bottom: 0;
  }
  blockquote {
    background: $beige;
    padding: 50px 50px 30px;
    border-radius: 5px;
    margin: 50px 0;
    a {
      color: $brown;
      text-decoration: underline;
    }
    a:hover {
      opacity: .5;
    }
  }
}

.toc-heading-tag-h3 {
  padding-left: .5rem
}

.social-media-share-button {
  &.facebook {
    @include button-pill(#3c5a96, 20px, 100%);
    text-align: left;

    @include media($small) { // repeat this for twitter and linkedin
      font-size: 0;
      text-align: center;
      line-height: 0;

      i {
        font-size: 20px;
      }
    }
  }

  &.twitter {
    @include button-pill(#1dadea, 20px, 100%);
    text-align: left;

    @include media($small) {
      font-size: 0;
      text-align: center;
      line-height: 0;

      i {
        font-size: 20px;
      }
    }
  }

  &.linkedin {
    @include button-pill(#313335, 20px, 100%);
    text-align: left;

    @include media($small) {
      font-size: 0;
      text-align: center;
      line-height: 0;

      i {
        font-size: 20px;
      }
    }
  }

  .social-media-share-button-text {
    margin-right: 20px;

    @include media($small) {
      margin: 0;
    }
  }
}


.blog-share-cta {
  margin: 100px auto;
  max-width: 580px;

  .share-cta-title {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  .social-media-buttons {
    margin: 20px 0 40px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    li {
      width: 32%;
    }
  }
}

.share-cta-callout {
  background: $beige;
  padding: 30px;
  border-radius: 6px;

  .title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.5;
  }
}

//Table of contents
.toc-container {
  position: absolute;
  left: -300px;
  top: 24px;
  font-size: 18px;
  @include media($medium) {
    display: none;
  }
  h5 {
    margin-bottom: 0;
  }
  .toc-list-container {
    font-size: 16px;
    margin-left: 0;
  }
  .toc-list-item {
    display: block;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    a {
      color: $brown;
    }
    a:hover {
      opacity: 50%;
    }
    a.active {
      color: $brown;
      font-weight: 700;
    }
  }
}

//Motion blogposts

.motion-blog-post {
  .blog-post-headline-container {
    text-align: center;
    max-width: 980px;
    z-index: 1;
    margin: 0 auto;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .blog-body {
    max-width: 1050px;
    margin: 0 auto;
  }
  .inner-content {
    max-width: 750px;
    margin: 0 auto;
  }
  .asset-container {
    margin: 60px auto;
  }
  .center-asset {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .asset-description {
    font-size: 1rem;
    opacity: .75;
    margin: 1rem 0;
    font-weight: 500;
  }
  .tiny-header {
    margin-bottom: 0;
    font-weight: bold;
  }
}

//Two panel graphic

.two-by {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1050px;
  margin: 100px 0;
}
.left-aside {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-aside {
  overflow: hidden;
  min-width: 400px;
}
.infinite-container {
  position: relative;
  left: -80px;
}
.blog-body .large-number-type {
  font-size: 6rem;
  letter-spacing: -4px;
  margin: 0;
  margin-bottom: -2rem;
}
.value-sublabel {
  text-transform: uppercase;
  opacity: .5;
  font-size: 14px;
  font-weight: bold;
  margin-top: -12px;
}

//Comparison table
.primary-comparison-table {
  max-width: 480px;
  display: inline-block;
}
.secondary-comparison-table {
  display: inline-block;
  max-width: 263px;
}
.comparison-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  padding: 12px;
  color: #fff;
  background: #5B5147;
  margin-bottom: 6px;
}
.secondary-comparison-header {
  background: #5B5147;
}
.primary-comparison-header {
  background: #4DAF7C;
}

.blog-body .comparison-body {
  list-style: none;
}
.comparison-body {
  background: #F5F0EC;
  padding: 20px;
}
.secondary-comparison-body {
  line-height: 2.5rem;
}

//Line of graphics

.collection-of-icons {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

//Icon animation

.heart-bg {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffc5ef;
  border-radius: 60px;
  animation: heartbeat 2s infinite linear forwards;
}
.heart-lifeline {
  max-width: 30px;
  animation: heartbeat 2s infinite linear forwards;
  margin-top: 4px;
}
@keyframes heartbeat {
  0% {transform: scale(1);}
  10% {transform: scale(1);}
  50% {transform: scale(1.15);}
  60% {transform: scale(1.05);}
  75% {transform: scale(1.2);}
}

//Cost calculator
.number-cruncher-container {
  margin: 70px 0 140px;
  text-align: center;
  position: relative;
}
.number-cruncher-container h4 {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 0;
}

.main-number-cruncher {
  font-size: 12rem;
  font-weight: 500;
  letter-spacing: -.75rem;
  margin: 0;
  padding: 0;
  line-height: 10rem;
  min-width: 490px;
}

.blog-body .main-number-cruncher {
  margin-bottom: 0;
}

.secondary-number-cruncher {
  margin-top: -4rem;
}
.accessory-1 {
  position: absolute;
  top: -10%;
  left: 0;
}
.accessory-2 {
  position: absolute;
  top: 40%;
  left: -25%;
}
.accessory-3 {
  position: absolute;
  bottom: -20%;
  left: 0;
}
.accessory-4 {
  position: absolute;
  bottom: -70px;
  left: 40%;
}
.accessory-5 {
  position: absolute;
  top: 0;
  right: 10%;     
}
.accessory-6 {
  position: absolute;
  bottom: -20%;
  right: 10%;
}
.accessory-7 {
  position: absolute;
  top: 40%;
  right: -20%;
}

//Phone
.ig-phone-container {
  display: inline-block;
}

.ig-first-phone {
  margin-right: 15px;
}

//Instagram action bar
.ig-icon {
  height: 20px;
}
.blog-body .photo-actions-container {
  list-style: none;
  padding: 0 6px;
  margin-bottom: 0;
  overflow: hidden;
}
.photo-actions-container li {
  float: left;
  padding: 0 6px;
}
.photo-actions-container .dm-container {
  padding-top: 1px;
}
.photo-actions-container .last-action {
  float: right;
}
.post-likes {
  font-weight: bold;
  font-size: 13px;
  padding: 0 12px;
  color: #262626;
}

//Instagram comment layout
.mobile-phone {
  position: relative;
}
.ios-top-bar {
  border-bottom: 1px solid #dfdfdf;
  height: 44px;
  line-height: 44px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #202120;
  position: sticky;
  z-index: 1;
  background: #fafafa;
}
.ios-top-bar .left-item {
  padding: 0 12px;
  text-align: left;
  width: 44px;
}
.ios-top-bar .right-item {
  padding: 0 12px;
  text-align: right;
  width: 44px;
}
.center-item {
  font-size: 17px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.back-button {
  width: 10px;
  float: left;
}
.dm-button {
  width: 22px;
  float: right;
}
.screen-content {
  z-index: 0;
}
.instagram-comments-container {
  margin: 0;
  padding: 0;
  list-style: none;
}
.instagram-comments-container li {
  padding: 12px;
}
.first-comment {
  border-bottom: 1px solid #dbdbdb;
}
.instagram-comment-avatar {
  width: 29px;
  height: 29px;
  border-radius: 29px;
  float: left;
}
.instagram-avatar-small {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.instagram-comment {
  font-size: 13px;
  font-family: "Helvetica Neue";
  line-height: 1.25;
  color: #262626;
  margin: 0;
}
.instagram-comment-content {
  margin-left: 38px;
}
.instagram-comment-content p{
  margin-bottom: 8px;
  display: inline-block;
  vertical-align: top;
}
.comment-replies .instagram-comment-content {
  margin-left: 29px;
}
.instagram-comment-content .timestamp-text {
  margin-bottom: 0;
  display: block;
  margin-top: -6px;
}
.comment-username {
  font-weight: bold;
}
.hashtag-text {
  color: #063A6D;
}
.timestamp-text {
  color: #999;
  margin-bottom: 0;
}
.reply-action {
  font-weight: 500;
  margin-left: 16px;
}
ul.comment-replies {
  padding: 0;
  margin: 12px 0 0 22px;
  list-style: none;
}
.comment-replies .instagram-comment-avatar {
  width: 20px;
  height: 20px;
}

.mobile-photo {
  display: none;
}

@media screen and (min-width: 480px) {
  .show-on-mobile {
    display: none;
  }
}

@media screen and (min-width: 800px){
  .show-on-tablet {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .secondary-comparison-table, .primary-comparison-table {
    max-width: 100%;
    width: 100%;
  }
  .motion-blog-post {
    .marvel-device.iphone5s.gold {
      padding: 0;
    }
    .marvel-device.iphone5s.gold:before {
      background: none;
    }
    .marvel-device.iphone5s .speaker {
      background: none; 
    }
    .marvel-device.iphone5s .sensor {
      background: none;
    }
    .marvel-device.iphone5s .camera {
      background: none;
    }
    .marvel-device.iphone5s.gold .home {
      box-shadow: none;
    }
    .marvel-device.iphone5s .sleep {
      background: none;
    }
    .marvel-device.iphone5s .volume {
      background: none;
    }
    .marvel-device .screen {
      box-shadow: 0 0 0 1px #F5F0EC;
    }
  }
  .ig-phone-container {
    display: block;
  }
  .ig-first-phone {
    margin: 0;
  }
  .second-phone-set {
    margin-top: 2rem;
  }
  .collection-of-phones {
    max-width: 320px;
  }
  .show-on-tablet {
    display: block;
  }
  .hide-on-tablet {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .landing-wrapper {
    padding-top: 80px;
    overflow-x: hidden;
  }
  .blog-body {
    blockquote {
      padding: 20px 20px 1px;
    }
  }
  .blog-post {
    margin-top: 60px;
  }
  .blog-header h1 {
    font-size: 40px;
  }
  .blog-post-preview {
    max-width: 100%;
    width: 100%;
    margin-right: initial;
  }
  .blog-share-cta {
    margin-top: 60px;
  }
  .input-group.embedded-button input[type="submit"].newsletter-submit {
    width: 100%;
    margin-top: 6px;
    font-size: 16px;
  }
  .motion-blog-post {
    .author-details {
      margin-top: 100px;
    }
    .two-by {
      flex-direction: column;
      margin: 50px 0;
    }
    .left-aside {
      text-align: center;
      margin-bottom: 50px;
    }
    .heart-bg {
      margin: 0 auto;
    }
    .large-number-type {
      font-size: 5rem;
      margin-bottom: -1.5rem;
    }
  }
  .mobile-photo {
    display: block;
  }
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: block;
  }
  .main-number-cruncher {
    font-size: 6rem;
    letter-spacing: -.25rem;
  }
  .secondary-number-cruncher {
    margin-top: -2rem;
  }
  .accessory-1 {
    left: 10%;
  }
  .accessory-2 {
    top: 80%;
    left: 10%;
    width: 100px;
  }
  .accessory-3 {
    display: none;
  }
  .accessory-7 {
    display: none;
  }
}
