.badge-container {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: white;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;

  .guest-badge {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: $badge-gray;
    display: flex;
    align-items: center;
  }
  .attention-badge {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }

  .badge {
    @include smallcaps;
    text-align: center;
    width: 100%;
    font-size: 10px;
    font-weight: 700;
    line-height: 0px;
    padding: 2px;

    &.guest {
      color: #5A5148;
    }

    &.attention {
      font-size: 12px;
      padding: 1px;
      color: $warn;
    }
  }
}

.influencers-table .badge-container {
  top: -2px;
  right: -2px;
}

.number-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 700;
  background: $badge-gray;
  color: white;

  &.active {
    background: $primary;
  }
}
