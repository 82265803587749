// adds a background circle to an icon
.icon-wrapper {
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  border-radius: 100px;
  color: white;
  line-height: 0px;
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.svg-inline--fa {
  &.with-padding {
    margin-right: 5px;
  }
}
