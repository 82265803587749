.super-bottom-bar {
  font-size: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #fff;
  border-top: 1px solid #ccc;
  z-index: 2;
  transform: translateY(61px);
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.super-bottom-bar.active {
  transform: translateY(0px);
}

.super-bottom-bar p {
  margin: 0;
  line-height: 20px;
}

.super-bottom-bar p:nth-child(1) {
  font-weight: bold;
}

.bottom-bar-signup-text {
  float: left;
  margin-right: 20px;
  padding: 10px 0;
}

.email-success {
  display: none;
  font-weight: bold;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.bottom-bar-signup-text.show-success {
  padding: 17px 0;
}

.bottom-bar-signup-text.show-success p {
  display: none;
}

.bottom-bar-signup-text.show-success .email-success {
  display: inline-block;
}


.email-octopus-form-wrapper {
  float: left;
  height: 32px;
  padding: 14px 0;
  height: 100%;
}

.email-octopus-form-wrapper.perma-hide {
  display: none;
}

.email-octopus-form-row {
  float: left;
  line-height: 32px;
  margin: 0;
  width: 270px;
}

.email-octopus-form-row input {
  line-height: 32px;
}

.email-octopus-form-row-subscribe {
  float: left;
  margin-top: 0;
}

.email-octopus-form-row-subscribe button {
  height: 32px;
  line-height: 32px;
  background-color: #54C088;
  color: #fff;
  border: none;
  padding: 0 16px;
  margin: 0;
  margin-left: -10px;
  border-radius: 0px 3px 3px 0px;
  cursor: pointer;
}

.bottom-share-button {
  display: inline-block;
  height: 32px;
  width: 32px;
  border: 1px solid #A09B97;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.bottom-share-button-container {
  list-style: none;
  float: right;
  margin: 0;
  height: 34px;
  padding: 14px 0;
  display: flex;
  justify-content: space-between;
  margin-right: -2px;
}

.bottom-share-button-container li {
  margin: 0;
  padding: 0 2px;
}

.mobile-form-btn {
  display: none;
  height: 32px;
  line-height: 32px;
  background-color: #54C088;
  max-width: 180px;
  font-size: 15px;
  margin: 14px 0;
  color: #fff;
  border: none;
  padding: 0 30px;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}

.mobile-form-btn.perma-hide {
  display: none !important;
}

/* CSS animate */

.mobile-btn-container {
  transform: translateX(0);
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.mobile-btn-container.active {
  transform: translateX(400px);
}

.mobile-cancel {
  display: none;
}

/* Larger than desktop 1000*/
@media (max-width: 1024px) {
  .super-bottom-bar .container {
    width: 100%;
    padding: 0 20px;
  }
  .bottom-bar-signup-text p:nth-child(1){
    display: none;
  }
  .bottom-bar-signup-text p:nth-child(2){
    font-weight: bold;
    max-width: 200px;
  }
}

/* Larger than tablet 750*/
@media (max-width: 750px) {
  .bottom-bar-signup-text {
    display: none;
  }
  .bottom-bar-signup-text.show-success {
    display: inline-block;
    max-width: 450px;
  }
}

/* Larger than phablet (also point when grid becomes active) */
@media (max-width: 550px) {
  .email-octopus-form-wrapper {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .mobile-form-btn {
    display: inline-block;
  }
}

/* Larger than mobile */
@media (max-width: 410px) {
  .super-bottom-bar .container {
    width: 90%;
    padding: 0;
  }
  .email-octopus-form-wrapper{
    display: inline-block;
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translateX(-400px);
  }
  .email-octopus-form-wrapper.active{
    transform: translateX(0);
  }
  .email-octopus-form-row {
    width: 170px;
    margin-left: 2%;
  }
  .mobile-cancel {
    display: inline-block;
    position: absolute;
    right: 2%;
    bottom: 14px;
    padding: 0px 10px;
    height: 32px;
    width: 32px;
    margin: 0;
    line-height: 32px;
  }
}