$gray: rgba(0, 0, 0, 0.3);
$dark-gray: rgba(0, 0, 0, 0.5);

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    display: inline-block;
    user-select: none;
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid $gray;
    border-radius: 4px;
    transition-duration: 0.1s;
    cursor: pointer;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 0;

    &:after {
      content: '';
      display: block;
      margin-left: 6px;
      margin-top: 1px;
      width: 5px;
      height: 12px;
      border: solid rgba(0, 0, 0, 0.1);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    &:hover {
      border:2px solid $dark-gray;
      color: black;
    }
    &:active:not(&.disabled) {
      transition-duration: 0;
      -webkit-filter: brightness(0.8); 
    }
    &.disabled {
      cursor: not-allowed;
      border: 2px dotted rgba(0, 0, 0, 0.4) !important;
      background: #e0e0e0;

      &:hover {
        border: 2px solid $gray;
      }
    }
  }

  input { display: none; }

  // Style default on/off states
  input:not(:disabled), input.on:disabled {
    &:checked + {
      label {
        color: #39b54a;
      }
      .checkbox-background {
        border-color: #178421;
        background: #39b54a;
        &:after{
          content: '';
          display: block;
          margin-left: 6px;
          margin-top: 1px;
          width: 5px;
          height: 12px;
          border: solid rgba(255, 255, 255, 1);
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  // Disabled state for 'on' also needs styling
  input.on:disabled:checked + .checkbox-background {
    border: 2px dotted rgba(0, 0, 0, 0.4) !important;
    background: #cde4cf !important;
  }
}

@keyframes boom {
  25% {
    transform: scale(1.25);
  }
}
