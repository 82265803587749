.settings {
  display: flex;
  gap: 40px;
  max-width: 960px;
  width: 100%;

  .title {
    margin-bottom: 20px;
    font-size: 24px;
  }
}

.settings-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 960px;
  width: 100%;
  gap: 20px;

  @include media($medium) {
    flex-direction: column;
  }

  .settings-body {
    width: 100%;

    .flash {
      max-width: 600px;
    }
  }

  .settings-navigation {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: sticky;
    max-width: 240px;
    top: 0;
    padding: 20px;
    box-shadow: $card-box-shadow;
    border-radius: 6px;
    background: white;
    z-index: $floatingNav;

    .settings-nav-list {
      display: flex;
      flex-direction: column;

      @include media($medium) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &.mini-nav {
      .nav-item {
        line-height: 50px;
      }
    }

    @include media($medium) {
      max-width: 100%;
    }
  }
}

.settings-info-wrapper {
  padding: 10px;
  border: 1px dotted $brown;
  color: $brown;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  opacity: 0.7;
}

.settings-card {
  max-width: 600px;
  box-shadow: $card-box-shadow;
  width: 100%;
  margin-bottom: 40px;
  background: white;
  border-radius: 6px;
  padding: 20px;

  @media only screen and (min-width: $wide-width-min) {
    max-width: 720px;
  }

  &.full-width {
    max-width: 100%;
  }
}

.current-card {
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 20px;
}
