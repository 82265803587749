.media-type-icon {
  svg {
    vertical-align: middle;
  }

  &.with-padding {
    svg {
      margin-right: 7px;
    }
  }
}
