.mini-nav {
  width: 100%;
  display: flex;

  .nav-item {
    line-height: 60px;
    margin-right: 20px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }

  .link {
    color: $brown;
    font-size: 16px;
    font-weight: 600;
    opacity: 0.7;

    &:hover {
      color: $brown-link;
    }

    &.active {
      opacity: 1;
      border-bottom: 6px solid $primary;
      border-color: $primary;
    }
  }
}

.filters-wrapper .mini-nav .link.active {
  padding-bottom: 16px;
}
