$base-line-height: 36px;
$white: rgb(165,156,156);
$off-white: rgba($white, 0.2);
$spin-duration: 0.75s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: .25rem solid $off-white;
  border-top-color: $primary;
  animation: spin $spin-duration infinite linear;
  margin: 0 auto;
}

.loading-overlay {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  height: 100%;
  background: rgba(255,255,255,0.7);
}

.button-pill .spinner {
  border-top-color: white;
  border-width: 0.15rem;
  width: 15px;
  height: 15px;
}
