.avatar.blank, .client-avatar.blank, .avatar.blank-inverted {
  width: 32px;
  height: 32px;
  outline: 2px solid white;
  min-width: 32px;
  font-size: 16px;
  border-radius: 100px;
  background: $brown;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

  &.medium {
    width: 64px;
    height: 64px;
    min-width: 64px;
    font-size: 24px;
  }

  &.inverted {
    background: transparent;
    color: $brown;
    border: 1px solid $brown;
  }

  &:hover {
    opacity: 0.8;
  }
}

.client-avatar {
  @include enforce-square-size(32px);
  border-radius: 32px;
  outline: 2px solid white;
  border-radius: 100%;
  object-fit: cover;

  &:hover {
    opacity: 0.8;
  }

  &.mini {
    @include enforce-square-size(20px);
    outline: 1px solid white;
    font-size: 11px;
    font-weight: 600;
  }

  &.nav {
    @include enforce-square-size(16px);
    outline: 1px solid white;

    @include media($medium) {
      @include enforce-square-size(32px);
      outline: 2px solid white;
    }

    @include media($small) {
      @include enforce-square-size(16px);
    }
  }
}

.impersonate-banner {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #fef9c3;
  border: 1px solid #facc15;
  color: #854d0e;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  z-index: 50;
}