$t-font-path:          "../fonts/";
$t-font-familyname:    "ProximaNova";
$t-font-name:          "ProximaNova-Light";
$t-font-name-thin:     "ProximaNova-Thin";
$t-font-name-thick:    "ProximaNova-Regular";
$t-font-name-semibold: "ProximaNova-Semibold";
$t-font-name-bold:     "ProximaNova-Bold";
$t-font-name-italic:   "proxima_nova_regularitalic-webfont";

@font-face {
  font-family: $t-font-familyname;
  src:url("#{$t-font-path}#{$t-font-name}.eot");
  src:url("#{$t-font-path}#{$t-font-name}.eot?#iefix") format("embedded-opentype"),
    url("#{$t-font-path}#{$t-font-name}.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $t-font-familyname;
  src:url("#{$t-font-path}#{$t-font-name-thin}.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $t-font-familyname;
  src:url("#{$t-font-path}#{$t-font-name-thick}.eot");
  src:url("#{$t-font-path}#{$t-font-name-thick}.eot?#iefix") format("embedded-opentype"),
    url("#{$t-font-path}#{$t-font-name-thick}.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $t-font-familyname;
  src:url("#{$t-font-path}#{$t-font-name-semibold}.eot");
  src:url("#{$t-font-path}#{$t-font-name-semibold}.eot?#iefix") format("embedded-opentype"),
    url("#{$t-font-path}#{$t-font-name-semibold}.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $t-font-familyname;
  src:url("#{$t-font-path}#{$t-font-name-bold}.otf");
  font-weight: 700;
  font-style: normal;
}