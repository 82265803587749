.date-picker {
  padding: 11px 10px;
}

.date-picker-content {
  max-width: 340px;
  background: white;
  border: 1px solid $gray;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  .react-calendar {
    width: initial;
    color: $brown;
    border: none;
    border-bottom: 1px solid $beige;
    font-family: $primary-font;
    padding: 15px;
  }

  button.react-calendar__navigation__label {
    font-weight: bold;
    font-size: 20px;
  }

  button.react-calendar__navigation__arrow {
    font-size: 34px;
  }

  button.react-calendar__navigation__label:disabled {
    background: none;
    color: $brown;
  }

  button.react-calendar__navigation__arrow:disabled {
    background: none;
    color: $brown-half;
  }

  .react-calendar__navigation button:enabled:focus, .react-calendar__navigation button:enabled:hover {
    background: none;
  }

  .react-calendar__navigation button:enabled:hover {
    color: $brown-half;
  }

  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }

  button.react-calendar__tile:disabled {
    background-color: initial;
    color: $brown-half;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: $brown-half;
  }
  .react-calendar--selectRange button.react-calendar__tile:enabled:hover, .react-calendar--selectRange .react-calendar__tile--hover, button.react-calendar__tile--active:enabled:hover, button.react-calendar__tile--hasActive:enabled:hover  {
    background-color: $primary-fill-half;
    color: $primary-darkest;
  }
  button.react-calendar__tile--active, button.react-calendar__tile:enabled:focus, .react-calendar__tile--hasActive {
    background: $primary-fill;
    color: white;
  }
  button.react-calendar__tile--highlight {
    // border: 1px solid $primary-fill;
    font-weight: 700;
    text-decoration: underline;
  }
}
