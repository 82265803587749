.terms-privacy {
  margin: 50px 0;

  > * {
    margin-bottom: 20px;
  }

  p.updated {
    font-style: italic;
    font-weight: 600;
  }

  p.bold {
    font-weight: 600;
  }

  h3 {
    font-size: 1rem;
    font-style: italic;
    font-weight: normal;
  }

  li {
    display: block;
    padding-left: 20px;
    list-style-type: circle;

    &:before {
      font-family: 'FontAwesome';
      content: '\f111';
      font-size: 8px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  ol {
    margin-top: 15px;
  }
}
