.about-header {
  margin: 80px 0;

  @include media($medium) {
    margin-top: 0;
  }

  h1 {
    margin: 40px 0;
  }
}

.about-subtitle {
  max-width: 700px;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  margin: 20px auto;
  line-height: 1.6;
  font-size: 20px;

  li {
    display: block;
    padding-left: 40px;
  }

  &.strong {
    font-weight: 700;
  }
}

.about-callout-line {
  font-size: 24px;
}

.about-team {
  @include media($small) {
    width: 100%;
  }
}

.about-filler {
  svg {
    width: 140px;
    height: 140px;
  }
}

.team-members {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  text-align: center;
  align-items: center;

  @include media($medium) {
    flex-direction: column;
  }

  .team-member {
    display: flex;
    flex-direction: column;
    border: 1px solid $borders;
    border-radius: 6px;
    margin-bottom: 40px;
    margin-right: 40px;
    max-width: 360px;
    width: 100%;

    &:last-of-type {
      margin-right: 0;
    }

    @include media($medium) {
      margin-right: 0;
    }
  }

  .team-member-identity {
    background: $beige;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0;
    padding: 15px;
    min-width: 240px;

    @include media($small) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 0;
    }
  }

  .team-member-name {
    font-weight: 700;
    font-size: 20px;
  }

  .team-member-subtitle {
    opacity: 0.7;
    text-align: left;
    font-size: 16px;
    line-height: 32px;
    padding: 15px;
    font-weight: 500;
  }

  .team-member-title {
    @include smallcaps;
    opacity: 0.7;
  }

  .team-member-avatar {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
  }

  .team-member-actions {
    display: flex;
    justify-content: center;
    padding: 15px;

    .access-button {
      padding: 0 10px;

      a {
        color: $brown;
        transition: color 0.25s ease-out;

        &:hover {
          color: $brown-lighter;
        }
      }
    }
  }
}

.about-cta {
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;

  a {
    max-width: 480px;
    display: block;
    margin: auto;
  }
}

.apply-cta {
  margin: 0;
  max-width: 150px;
}

.about-email-cta {
  background: $primary;
  width: 100%;
  padding: 40px 20px;
  text-align: center;
  border-radius: 6px;
  color: white;
  margin: auto;
  font-size: 32px;

  @include media($small) {
    font-size: 24px;
  }

  a {
    color: white;
  }
}

.about-quote {
  font-style: italic;
  font-size: 20px;
}
