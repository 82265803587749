$links: #539EF1;

.documentation {
  max-width: 1341px;

  p {
    color: #737373;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.documentation-nav {
  width: 200px;
  background-color: #F4F6F9;
  padding: 30px;
  padding-bottom: 200px;
  position: fixed;
  height: 100%;
  float: left;
  overflow: auto;

  .api-logo {
    margin-bottom: 30px;
  }

  li {
    display: block;
    margin-bottom: 20px;

    a {
      color: #2A3138;
      font-weight: 500;
      cursor: pointer;

      &.active {
        color: $links;
      }
    }

    ul {
      margin-left: 5px;
      margin-top: 5px;

      li {
        margin-bottom: 0;
        font-size: 14px;

        a {
          color: #737373;
          font-weight: 500;
          cursor: pointer;

          &.active {
            color: $links;
          }
        }
      }
    }
  }
}

.category-header {
  @include smallcaps;
  color: #8C939F;
  margin-bottom: 20px;
  font-weight: 700;
}

.documentation-body {
  width: 1440px;
  float: left;
  padding-left: 200px;
}

.documentation-section {
  display: flex;

  h1 {
    padding-bottom: 20px;
    border-bottom: 1px solid $borders;
    margin-bottom: 20px;
  }

  section {
    padding: 30px;
    background-color: white;
    width: 700px;
  }

  aside {
    width: 540px;
    background-color: #2A3138;
    padding: 30px;
    color: white;

    .badge {
      background-color: #22292F;

      &.get {
        background-color: #22292F;
        color: #5AA1F2;
      }

      &.post {
        background-color: #22292F;
        color: #7CCAA5;
      }

      &.put {
        background-color: #22292F;
        color: #c75af2;
      }
    }
  }
}

.badge {
  padding: 5px;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  margin-right: 5px;

  &.get {
    background-color: #5AA1F2;
  }

  &.post {
    background-color: #7CCAA5;
  }

  &.put {
    background-color: #c75af2;
  }
}

.endpoint-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.arguments {
  color: #a3acb9;
  padding-bottom: 12px;
  border-bottom: 1px solid $borders;
  @include smallcaps;
}

.parameter-row {
  display: flex;
  padding: 16px 0;

  &.parameter-header {
    border-bottom: 1px solid $borders;
  }

  .param-col {
    min-width: 120px;
    word-break: break-word;
    font-size: 14px;
    padding-right: 8px;

    &:last-child {
      padding-right: 0;
    }
  }

  .param-value {
    opacity: 0.7;
    font-weight: 600;

    &.attribute {
      opacity: 1;
      color: #444;
    }

    &.required {
      @include smallcaps;
      color: #e56f4a;
      opacity: 1;
      font-size: 9px;
      letter-spacing: 0.5px;
    }

    &.optional {
      @include smallcaps;
      opacity: 0.7;
      font-size: 9px;
      letter-spacing: 0.5px;
    }
  }
}

.path {
  margin-bottom: 20px;

  .pathname {
    font-size: 0;
    color: white;
    font-family: "Source Code Pro";

    span {
      font-size: 14px;
    }
  }
}


.highlight {
  border-radius: 4px;
  margin-top: 0 !important;
  margin-bottom: 20px !important;
  padding: 1rem;
  font-size: 14px;
  overflow-x: auto;
  background-color: #22292F !important;

  code {
    font-family: 'Source Code Pro' !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
  }
}

.last-section {
  min-height: 100vh;
}