.dropdown-select {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  white-space: nowrap;

  &-content {
    position: relative;
    width: auto;
    padding: 0px;
    overflow: hidden;

    &.hidden {
      display: none;
    }

    .dropdown-select-option {
      cursor: pointer;
      padding: 10px;

      &.active, &:hover {
        background-color: $beige;
      }
    }
  }

  &-option-row {
    @include no-highlight;
    line-height: 15px;
    font-size: 14px;
  }

  &.expanded {
    border-color: $primary !important;
    outline: none;
  }
}
