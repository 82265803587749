.section-block {
  padding: 7rem 1.25rem;
  @include media($medium) {
    padding: 5rem 1.25rem;
  }
}

.landing-section {
  .title {
    font-style: normal;
    font-size: 40px;
    line-height: 49px;
    letter-spacing: -1px;
    color: $brown;
    font-weight: 700;
    margin-bottom: .5rem;
  }
  p {
    font-size: 20px;
  }
  .shopify-integration-logo {
    margin-top: 1rem;
    margin-right: 1rem;
  }
  .woo-integration-logo {
    height: 37px;
  }
  .icon-integration-logo {
    margin-top: 1rem;
    height: 38px;
    margin-right: 1rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
  .icon-integration-logo-tiny {
    margin-top: 0;
    margin-bottom: 1rem;
    height: 24px;
  }
}

.section-subheadline {
  font-size: 20px;
  margin-top: .5rem;
}

.left-content {
  width: 40.2913%;
  float: left;
  margin-right: 2.3576%;
  @include media($medium){
    width: 100%;
    float: left;
    margin-right: 0;
  }
}
.right-hero {
  margin-left: 8.5298%;
  @include span-columns(6 of 12);
  @include media($medium){
    @include span-columns(12 of 12);
    @include shift(0);
    margin-top: 2em;
  }
}

.left-content-third {
  @include span-columns(4 of 12);
  @include media($medium){
    @include span-columns(12 of 12);
  }
}
.right-hero-two-thirds {
  margin-left: 8.5298%;
  @include span-columns(7 of 12);
  @include media($medium){
    @include span-columns(12 of 12);
    @include shift(0);
    margin-top: 2em;
  }
}

.section-inverted, .c-time.section-inverted {
  background: $light-bg;
}

.text-inverted {
  color: #fff;
}

#campaign-pricing {
  .pricing-container {
    @include media($small){
      margin: 30px auto;
    }
  }
}

.pricing-undertitle {
  margin-top: 4em;
  p {
    font-size: 20px;
  }
}

.vcenter-content {
  display: flex;
  align-items: center;
  @include media($medium) {
    display: block;
  }
}

.c-campaign {
  &__title {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -1px;
    max-width: 806px;
    margin: auto;
    color: $brown;
  }

  &__list{
    text-align: center;
    margin-top: 5rem;
    &-item {
      @include media($medium) {
        margin-top: 2.5rem;
      }
      &-image {
        margin-top: 3rem;
        width: 50px;
        height: 60px;
        margin-bottom: -1rem;
      }
      &-content {
        text-align: left;
        margin-top: 20px;
        &-title {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;
          color: $brown;
          margin-bottom: 10px;
        }

        &-description {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }

    .first {
      background-repeat: no-repeat;
      background-image: url('/images/landing/campaign/first.svg');
      @include media($medium) {
        margin-top: 0rem;
      }
    }
    .second {
      background-repeat: no-repeat;
      background-image: url('/images/landing/campaign/second.svg');
    }
    .third {
      background-repeat: no-repeat;
      background-image: url('/images/landing/campaign/third.svg');
    }
  }
}

.c-case-study {
  background-color: #333333;
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -1px;
    max-width: 806px;
    margin: auto;
    color: white;
  }
  &__description {
    margin: auto;
    margin-top: 5rem;
    &-item {
      border-radius: 5px;

      &-main {
        background-color: white;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        margin-bottom: 20px;

        .wrapping{
          padding: 20px;
        }

        @include media($bulma-tablet) {
          border-top-right-radius: 5px;
          border-bottom-left-radius: 0px;
        }
      }

      &-header {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;

        &-image {
          margin-right: 7px;
          border-radius: 5px
        }

        &-video {
          max-height: 136px;
          margin-bottom: -6px;
          @include media($small){
            max-width: 100%;
            max-height: initial;
          }
        }
      }

      &-content {
        &-title{
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          margin: 15px 0;
        }

        &-description {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          margin: 15px 0;
        }
      }

      &-footer {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #333;
        border-radius: 5px;

        @include media($small){
          flex-direction: column;
        }

        &-author {
          display: flex;
          padding: 1rem;
          @include media($small){
            width: 100%;
            display: block;
          }
          &-image {
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }
          &-video {
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            &-overlay {
              display: block;
              background: #333;
              height: 100%;
              width: 100%;
              position: absolute;
              opacity: .5;
            }
          }
          &-details {
            display: inline-block;
            color: #fff;
            @include media($small){
              display: block;
            }

            &-name {
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 23px;
            }

            &-role {
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 23px;
              opacity: 0.5;
            }
          }
        }

        &-action {
          &-link {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            color: #fff;
            padding: 12px;
            display: inline-block;
            border: 2px solid #fff;
            border-radius: 5px;
            margin-top: .5rem;

            @include media($small){
              display: block;
              text-align: center;
            }

            &-play-icon {
              margin-left: .5rem;
            }

            &:hover {
              text-decoration: none;
              color: #fff;
              opacity: .8;
            }
          }
        }
      }

      &-chart {
        background-color: $brown;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 20px;
        padding: 0;
        position: relative;

        @include media($bulma-tablet) {
          padding: 20px 20px 60px;
          margin-bottom: 60px;
          border-top-right-radius: 0px;
          border-bottom-left-radius: 5px;
        }

        &-title {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #FFFFFF;
          margin-top: 20px;
          padding: 0 20px;
        }

        &-content {
          margin-top: 10px;
          width: 100%;
          &-number {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 5px;
          }
          .chart{
            display:table;
            table-layout: fixed;
            width:80%;
            max-width:700px;
            height:300px;
            margin:0 auto;

            @include media($bulma-tablet) {
              width: 40%;
            }

            @include media($small) {
              width: 70%;
            }

            .chart-bar{
              position:relative;
              display:table-cell;
              vertical-align:bottom;
              height:300px;

              &:last-child {
                .faux-bar {
                  background: #4DAF7C;
                }
              }
            }
            .short-chart-bar {
              height: 278px;
            }
            .shorter-chart-bar {
              height: 225px;
            }
            .faux-bar {
              margin:0 1em;
              display: block;
              background: #F5F0EC;
              animation: draw 1s ease-in-out;

              &:before {
                position:absolute;
                left:0;right:0;top:100%;
                padding:5px 0em 0;
                display:block;
                text-align:center;
                content: attr(title);
                word-wrap: break-word;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #FFFFFF;
                margin-top: 5px;
              }
            }
          }
          .short-chart {
            height: 278px;
          }
          .shorter-chart {
            height: 225px;
          }
        }

        @keyframes draw{
          0%{height:0;}
        }
      }
    }
  }
}

.c-client {
  background-color: #F5F0EC;
  &__title {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -1px;
    max-width: 886px;
    margin: auto;
    color: $brown;
  }
  &__images {
    max-width: 821px;
    margin: auto;
    margin-top: 5rem;
    text-align: center;
  }
}

.nascar-normal {
  max-height: 50px;
  @include media($medium) {
    display: none;
  }
}
.nascar-mini {
  display: none;
  margin: auto;
  padding-top: 1rem;
  @include media($medium) {
    display: block;
  }
}

.c-faq {
  background: #F5F0EC;
  &__content {
    margin: 0 auto;

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      text-align: center;
      letter-spacing: -1px;
      color: $brown;
      margin: auto;
    }

    &-questions {
      margin-top: 5rem;
      padding: 0 20px;
      &-item {
        margin-bottom: 30px;
        &-title {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;
          color: $brown;
          margin-bottom: 10px;
        }

        &-answer {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 28px;

          color: $brown;
        }
      }
    }
  }
}

.c-footer {
  background: #333333;

  &__content {
    display: flex;
    justify-content: center;
    padding-top: 70px;
    position: relative;
    background-image: url('/images/landing/footer/paw.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    padding: 7rem 1.25rem;

    .footer-logo {
      @include media($medium) {
        height: 200px;
      }
    }
    &-right {
      &-item {
        padding: 10px;
        &-title {
          padding-top: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 143.85%;
          color: #FFFFFF;

          &:last-child {
            margin-top: 25px;
          }
        }
        &-text {
          padding-top: 7px;
          padding-bottom: 7px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          display: block;
          color: #FFFFFF;

          .footer-link {
            color: white;

            &:hover {
              color: scale-color(white, $lightness: -20%);
            }
          }
        }
      }
    }

    &-made-text {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      color: #FFFFFF;
      opacity: 0.5;
      position:absolute;
      bottom: 20px;
      width: 100%;
    }
  }
}

.c-footer-light {
  background: $light-bg;

  &__content {
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding: 5rem 1.25rem;

    .footer-logo {
      padding: 20px 10px;
      @include media($medium) {
        height: 200px;
      }
    }
    &-right {
      &-item {
        padding: 10px;
        &-title {
          padding-top: 10px;
          padding-bottom: 0px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 143.85%;
          color: $brown;
          text-transform: uppercase;

          &:last-child {
            margin-top: 25px;
          }
        }
        &-text {
          padding-top: 7px;
          padding-bottom: 7px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          display: block;
          color: $brown;

          .footer-link {
            color: $brown;

            &:hover {
              color: scale-color($brown, $lightness: -20%);
            }
          }
        }

        &.no-title {
          padding-top: 62px;
        }
      }
    }

    &-made-text {
      text-align: left;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      color: $brown;
      opacity: 1;
      width: 100%;
      margin-top: 2rem;
      padding: 0 10px;
    }
  }
}

.c-header {
  background-color: #54C088;
  text-align: center;
  padding: 7rem 1.25rem 0;

  &-movie-mode {
    background-color: #333;
  }

  &__description {
    padding-top: 5rem;
    padding-bottom: 2rem;
    max-width: 790px;
    margin: auto;
    @include media($medium){
      padding-top: 2rem;
    }

    &-top {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: black;
      opacity: 0.25;
    }

    &-main {
      color: white;
      font-style: normal;
      font-weight: bold;
      font-size: 49px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -1px;
      margin-top: .5rem;

      @include media($bulma-tablet) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &-additional {
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 42px;
      text-align: center;
      color: black;
      opacity: 0.5;
    }

    &-actions {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 15px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      &-get-price {
        @include button-pill($contrast, 15px);
        width: 190px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        min-width: 190px;
      }

      &-view-campaign {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: white;
        text-decoration: underline;
        padding-top: 5px;
        margin-left: 10px;

        @include media($small) {
          margin-top: 20px;
          display: block;
        }
      }
    }
  }

  &__images {
    width: 100%;
    background-size: contain;
    background-image: url(/images/landing/header_images.svg);
    margin: auto;
    display: flex;
    justify-content: center;
    background-position: center;
    padding: 10rem 1.25rem;
    @include media($small){
      padding: 7rem 0rem;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      &-actions {
        text-align: center;
        margin-top: 30px;
        &-get-price {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          min-width: 190px;
          max-width: 190px;
        }
      }

    }
  }
}

.product-landing .c-header-main-homepage {
  font-size: 41px;
  line-height: 50px;
  @include media($bulma-tablet) {
    font-size: 32px;
    line-height: 40px;
  }
}

.c-header-light {
  background-color: #fff;
  text-align: center;
  padding: 7rem 1.25rem 0;

  &__description {
    padding-top: 5rem;
    padding-bottom: 2rem;
    max-width: 790px;
    margin: auto;
    @include media($medium){
      padding-top: 2rem;
    }

    &-main {
      color: $brown;
      font-style: normal;
      font-weight: bold;
      font-size: 49px;
      line-height: 57px;
      text-align: left;
      letter-spacing: 0;
      margin-top: .5rem;

      @include media($bulma-tablet) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &-actions {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: fit-content;
      width: 100%;

      @include media($small) {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column
      }

      &-get-price {
        @include button-pill($primary, 15px);
        width: 190px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        min-width: 190px;
        margin-right: 15px;
      }

      &-link {
        height: fit-content;
        margin-left: 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: $brown;
        text-decoration: underline;

        @include media($small) {
          margin-left: 0;
          margin-top: 20px;
        }

        &:hover {
          color: $brown;
          opacity: .8;
        }
      }
    }
  }

  .right-hero {
    @include media($medium) {
      margin-bottom: 2rem;
    }
  }

  .tiny-accent-dark {
    margin-top: 4rem;
    font-size: .75rem;
    font-weight: bold;
    color: $black;
    margin-bottom: 1.5rem;
    opacity: .5;

    @include media($medium) {
      font-size: 1rem;
    }
  }
}


.c-quote {
  background: #4DAF7C;
  background-image: url("/images/landing/quotes/open_double_quote.svg");
  background-repeat: no-repeat;
  background-position: 7rem 5rem;

  &__content {

    &-text {
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 40px;
      color: #FFFFFF;

      @include media($bulma-tablet) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  &__author {
    margin-top: 20px;
    display: flex;
    align-items: center;

    &-image {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 20px;
    }

    &-detail {
      display: inline-block;

      h6 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;

        color: #FFFFFF;
      }

      p {
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        opacity: 0.5;
      }
    }
  }
}

.c-ready {
  background: $brown;
  &__content {
    max-width: 770px;
    margin: 0 auto;

    &-text {
      font-style: normal;
      font-weight: bold;
      font-size: 39px;
      line-height: 47px;
      text-align: center;
      letter-spacing: -1px;
      color: #FFFFFF;

      @include media($bulma-tablet) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &-actions {
      text-align: center;
      margin-top: 30px;

      &-get-price {
        max-width: 190px;
        margin-right: 15px;
      }

      &-view-more {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        text-decoration: underline;
      }

      &-text {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 19px;
        color: #333333;
        vertical-align: middle;
      }
    }
  }
}

.c-time {
  background-color: white;
  &__title {
    max-width: 878px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -1px;
    color: $brown;
    margin: auto;
  }

  &__content {
    margin: 5rem auto 0;
    &-item {
      &-image {
        width: 50px;
        height: 50px;
      }

      &-title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 10px;
        color: $brown
      }

      &-describer {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;

        color: $brown;
      }
    }
  }

  &__actions{
    text-align: center;
    margin-top: 40px;
    padding-bottom: 60px;

    &-how-btn {
      background-color: #4DAF7C !important;
      border-radius: 5px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: white !important;
    }
  }
}

.benefit-details {
  .c-time__content {
    margin-top: 0;
  }
}

.benefits-horizontal {
  .columns {
    flex-direction: column;
  }
  .column {
    display: flex;
    align-items: center;
  }
  h5 {
    margin-left: .5rem;
    margin-bottom: 0;
  }
}

.nav-width-container {
  max-width: 1170px;
  margin: auto;
}

.solutions, .alternatives {
  .c-header {
    padding: 7rem 1.25rem;
  }
  .c-time.section-inverted {
    background: $light-bg;
  }
  .c-time__title {
    margin-top: 0.5rem;
  }
  .description-additional {
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 37px;
    text-align: center;
    opacity: .75;
  }
  .left {
    text-align: left;
  }
  .c-case-study__description-item-chart-content .chart .chart-bar {
    height: 200px;
  }
  .campaign-pricing-containers {
    margin: 0 auto;
    border-top: 1px solid #ececec;
    @media (max-width: 753px){
      border: none;
    }
    .pricing-container {
      flex-basis: auto;
      border: none;
      border-radius: 0;
      max-width: 260px;
      .pricing-accent-subheader {
        font-size: 14px;
        opacity: .5;
        margin-bottom: .5rem;
      }
    }
    .pricing-container-center {
      border-left: 1px solid #ECECEC;
      border-right: 1px solid #ECECEC;
      @include media($bulma-tablet){
        border-left: none;
      }
      @media (max-width: 753px){
        border: none;
      }
    }
    .mobile-pricing-hide {
      @include media($bulma-tablet){
        display: none;
      }
    }
    .mobile-pricing-list-item {
      display: none;
      @include media($bulma-tablet){
        display: inline-block;
      }
    }
    .mobile-color-red {
      @include media($bulma-tablet){
        color: red;
      }
    }
  }
  .campaign-pricing {
    .pricing-box {
      margin-top: auto;
      border-top: 1px solid #F5F0EC;
      .list-item:last-child {
        height: 69.2px;
      }
    }
  }

  ul.about-subtitle {
    list-style-type: disc;
    padding-left: 40px;
    li {
      padding-left: 0;
      display: list-item;
    }
  }
}

.product-landing {
  .header-main {
    padding-top: 2rem;
  }
  .header-main-homepage {
    padding: 4rem 0;
  }
  .c-header__description-actions-get-price {
    margin-right: 0;
  }
  .c-client__images {
    margin-top: 2rem;
    max-width: 100%;
    display: inline-block;
  }
  ul.about-subtitle {
    list-style-type: disc;
    padding-left: 40px;
    li {
      padding-left: 0;
      display: list-item;
    }
  }
  .c-header-light__description-top {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: black;
    opacity: 0.25;
  }
}

.header-hero-action-items {
  display: flex;
  margin-top: 1rem;
}

.mleft-1 {
  margin-left: 1rem;
}

.tiny-accent-light {
  margin-top: 1rem;
  font-size: .75rem;
  font-weight: bold;
  color: #fff;
}

.tiny-accent-dark {
  margin-top: 1rem;
  font-size: .75rem;
  font-weight: bold;
  color: #000;
  opacity: .2;
  margin-bottom: 1rem;
}

.left-content {
  .c-header__description-top {
    text-align: left;
  }
  .c-header__description-main {
    text-align: left;
  }
  .c-header__description-additional {
    text-align: left;
  }
}

.c-about {
  padding: 0 1.25rem;

  &__content {
    width: 100%;
    background-color: #F5F0EC;
    border-radius: 25px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-left {
      padding: 50px 0 50px 70px;
      flex: 1;

      @include media($medium) {
        padding: 50px;
        width: 100%;
        flex: auto;
      } 

      &-title {
        color: $brown;
        font-size: 33px;
        font-style: normal;
        font-weight: bold;
        margin-bottom: .5rem;
      }

      &-description {
        font-size: 20px;
        margin-bottom: 20px;
        color: $brown;
        font-weight: normal;
        font-style: normal;
      }

      &-actions {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &-btn {
          width: fit-content;
        }
      }
    }

    &-right {
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;

      @include media($medium) {
        // padding: 0 30px;
        width: 100%;
      } 

      img {
        width: 330px;

        @include media($medium) {
          width: 100%;
        } 
      }
    }
  }
}

.calendly-inline-widget {
  position: relative;
  width: 100%;
  min-width:320px;
  height:750px;
}

.c-demo-cta {
  padding: 0 1.25rem;

  &__content {
    width: 100%;
    background-color: #F5F0EC;
    border-radius: 25px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4rem;

    &-left {
      padding: 50px 0 50px 70px;
      flex: 1;
      max-width: 500px;

      @include media($medium) {
        padding: 50px;
        width: 100%;
        flex: auto;
      } 

      &-title {
        font-size: 34px;
        font-weight: bold;
        line-height: 120%;
        color: $brown;
        margin-bottom: 0.5rem;
        font-style: normal;
      }

      &-description {
        font-size: 20px;
        margin-bottom: 20px;
        color: $brown;
        font-weight: normal;
        font-style: normal;
      }

      &-actions {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &-btn {
          width: fit-content;
        }
      }
    }

    &-right {
      padding: 50px 50px 50px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      min-width: 500px;

      @include media($medium) {
        // padding: 0 30px;
        width: 100%;
      } 

      img {
        width: 330px;

        @include media($medium) {
          width: 100%;
        } 
      }
    }
  }
}

.c-features {
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -1px;
    color: #5B5147;
    margin: auto;

    @include media($medium) {
      font-size: 34px;
    }
  }

  &__content {
    margin: 5rem auto 0;

    &-item {
      
      &__card {
        width: 100%;
        height: 100%;
        border: 1px solid #E2E2E4;
        padding: 1.5rem;
        border-radius: 5px;
      }

      &-img {
        background-color: $beige-light;
        width: 100%;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .75rem;
        border-radius: 10px;

        @media (max-width: 768px) {
          padding: 2rem .75rem;
        }

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      &-title {
        font-size: 18px;
        font-style: normal;
        font-weight: bold;
        color: $brown;
        margin-top: 15px;
        margin-bottom: .5rem;
      }

      &-describer {
        font-size: 18px;
        font-style: normal;
        font-weight: normal;
        color: $brown;
      }
    }
  }
}

.c-cta {
  padding: 0 1.25rem;

  &__content{
    background-color: $primary;
    padding: 4rem 5rem;
    border-radius: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include media($medium) {
      padding: 3rem;
    }

    &-left {
      width: 210px;

      &-video {
        width: 210px;
        height: 210px;
        position: relative;

        &-item {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          video {
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            object-fit: cover;
            object-position: center;
          }
        }
      }

      &-text {
        text-align: center;
        @media (max-width: 820px) {
          text-align: center;
        }

        &-title {
          width: 100%;
          font-style: normal;
          font-weight: bold;
          font-size: 19px;
          color: white;
          margin-top: 10px;
        }
        &-subtitle {
          width: 100%;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          color: white;
        }
      }
    }

    &-right {
      flex: 1;
      padding-left: 5rem;

      @media (max-width: 820px) {
        flex: auto;
        width: 100%;
        padding-left: 0;
        margin-top: 3rem;
      }

      &-title {
        width: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 120%;
        color: white;

        @include media($medium) {
          font-size: 30px;
        }

        @include media($small) {
          font-size: 25px;
        }
      }
    
      &-quote {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        color: white;
        margin-top: 20px;
        margin-bottom: 20px;

        @include media($medium) {
          font-size: 20px;
        }

        @include media($small) {
          font-size: 15px;
        }
      }

      &-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;

        &-item {
          width: fit-content;
          margin: 10px 15px 0 0;

          @include media($small) {
            width: 100%;
            margin: 10px 0 0 0;
          }

          &-icon {
            vertical-align: middle;
            margin-left: .5rem;
          }
        }
      }
    }


  }

}

.c-examples {
  &__title {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0;
    color: $brown;
    margin: auto;

    &-profit {
      background-color: $primary;
      padding: 0 5px;
      color: white;
    }
  }

  &__content {
    margin: 5rem auto 0;

    &-item {
      padding: .5rem .4rem;

      &-card {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        padding: 50px 30px;
        border-radius: 15px;

        &.black-bg {
          background-color: $black;
        }
        &.brown-bg {
          background-color: $brown;
        }
        &.gray-bg {
          background-color: #A09B97;
        }

        &-title {
          font-style: normal;
          font-weight: bold;
          font-size: 33px;
          line-height: 40px;
          color: white;
          margin-bottom: 15px;

          span {
            font-weight: normal;
          }
        }

        &-description {
          font-style: normal;
          color: white;
          font-size: 18px;
          margin-bottom: 20px;
        }

        &-video-container {
          max-width: 80px;
          position: relative;
        }
        &-play-icon {
          position: absolute;
          top: 50%;
          left: 0%;
          transform: translate(70%, -50%);
          color: white;
          z-index: 3;
        }

        &-img {
          height: 33px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            height: 100%;
            width: auto;
          }

          &-small {
            height: 33px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
              height: calc(100% - 10px);
              width: auto;
            }
          }

        }
      }
      &-logo-vid-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 220px;
      }
    }
  }
}

.c-examples__content {
  .c-case-study__description-item-footer-author-video-overlay {
    border-radius: 5px;
  }
  .c-case-study__description-item-header-video {
    width: 80px;
    border-radius: 5px;
    margin-bottom: 0;
  }
  .example-item-footer-action-link {
    text-decoration: underline;
    color: #fff;
    font-weight: bold;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: .8;
    }
  }
  .c-examples__content-item-card-img {
    margin-top: 2rem;
    max-width: 70px;
  }
}



.c-benefits {
  padding: 2rem 1.25rem 7rem 1.25rem;

  &__title {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 43px;
    text-align: center;
    letter-spacing: 0;
    color: $brown;
    margin: auto;

    @include media($medium) {
      font-size: 34px;
      line-height: 37px;
    }
  }

  &__subtitle {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    text-align: center;
    letter-spacing: 0;
    color: $brown;
    margin: 1rem auto 3.5rem auto;
  }

  &__item {
    padding: .5rem;
    
    &-card {
      width: 100%;
      height: 100%;
      background-color: $beige-light;
      display: flex;
      align-items: center;
      padding: 30px;

      &-icon {
        width: 75px;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 30px;

        img {
          width: 100%;
          height: auto;
        }
      }

      &-content {
        flex: 1;
        height: fit-content;

        &-title {
          font-size: 20px;
          line-height: 23px;
          font-weight: bold;
          color: $brown;
          margin-bottom: .25rem;
          font-style: normal;
        }

        &-description {
          font-size: 18px;
          font-style: normal;
          font-weight: normal;
          color: $brown;
        }
      }
    }

  }
}

.c-trial {
  &__content {
    width: 100%;
    height: fit-content;
    background-color: $beige;
    padding: 0 30px;
    border-radius: 20px;
    position: relative;

    &-item {
      &-img {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 10px 50px 30px;

        @include media($small) {
          padding: 0;
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }

      &-text {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 20px 30px 20px 10px;

        &-title {
          font-size: 34px;
          font-weight: bold;
          line-height: 120%;
          color: $brown;
          margin-bottom: .5rem;
          font-style: normal;

          @include media($medium) {
            font-size: 30px;
            margin-bottom: 20px;
          }

          @include media($small) {
            font-size: 27px;
          }
        }

        &-description {
          font-size: 21px;
          font-weight: normal;
          font-style: normal;
          margin-bottom: 25px;
          color: $brown;

          @include media($small) {
            font-size: 18px;
            margin-bottom: 20px;
          }
        }

        &-actions {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          width: 100%;;

          .email-capture-form {
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 400px;
            margin-bottom: 1rem;
            @include media($small) {
              flex-direction: column;
            }
            
            .email-input {
              width: 100%;
              padding: 10px 15px;
              border: 1px solid #ccc;
              border-radius: 5px 0 0 5px;
              font-size: 16px;
              background-color: white;

              @include media($small) {
                flex-grow: 1;
                margin-bottom: 10px;
                border-radius: 5px;
              }
      
              &:focus {
                outline: none;
                border-color: $primary;
              }
            }

            .button {
              @include button-pill($contrast, 15px);
              width: 240px;
              border-radius: 0 5px 5px 0;
              margin-left: -1px;
              @include media($small) {
                border-radius: 5px;
                margin-left: 0;
                width: 100%;
              }
            }

            .interested-more {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .blog-breakout-container {
    width: calc(100% + 200px);
    margin-left: -100px;
    margin-right: -100px;
    @include media($small) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.product-bento {
  overflow: hidden;
  
  .product-bento-section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .paws-left {
    position: absolute;
    width: 200px;
    height: 100%;
    left: 0;
    top: 50%;
    transform: translate(-75%, -25%);
    background-image: url('../images/landing/pawsleft.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
  }

  .paws-right {
    position: absolute;
    width: 100px;
    height: 100%;
    right: 0;
    top: 50%;
    transform: translate(120%, -25%);
    background-image: url('../images/landing/pawsright.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
  }

  &__title {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 43px;
    text-align: center;
    letter-spacing: 0;
    color: #5B5147;
    margin: auto;
  }

  &__subtitle {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    text-align: center;
    letter-spacing: 0;
    color: #5B5147;
    margin: 1rem auto 3.5rem auto;
  }

  &__image {
    &.desktop {
      display: block;
      @include media($small) {
        display: none;
      }
    }
  
    &.mobile {
      display: none;
      @include media($small) {
        display: block;
      }
    }
  }
} 

.c-faq-light {
  background: white;
  padding: 0 1.25rem 7rem 1.25rem;
  &__content {
    margin: 0 auto;

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      text-align: center;
      letter-spacing: 0;
      color: $brown;
      margin: auto;

      @include media($medium) {
        font-size: 34px;
        line-height: 37px;
      }
    }

    &-questions {
      margin-top: 5rem;
      padding: 0 20px;
      &-item {
        margin-bottom: 30px;
        &-title {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;
          color: $brown;
          margin-bottom: 10px;
        }

        &-answer {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 28px;

          color: $brown;
        }
      }
    }
  }
}

.special-promotion-tag {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 1rem;
  position: absolute;
  text-transform: uppercase;
  top: -1rem;
  left: -1rem;
}

.button-small {
  width: initial;
}

.light-description {
  font-size: 22px;
  line-height: 31px;
  color: $brown;
  opacity: 1;
  
  ul {
    list-style-type: disc;
  }

  .bullet-item {
    display: list-item;
    margin-left: 1.25rem;
    line-height: 42px;
  }
}

.mini-callout-section {
  max-width: 700px;
  padding: 0 0 7rem;
}

.feature-section {
  padding: 4rem 1.25rem;
}
.section-padding-top {
  padding-top: 7rem;
}
.section-bottom-padding {
  padding-bottom: 7rem;
}
.section-no-padding-top {
  padding-top: 0rem;
}
.section-no-padding-bottom {
  padding-bottom: 0rem;
}
.benefit-section-block {
  padding-top: .01rem;
  padding-bottom: 7rem;
}

.coming-soon-tag {
  padding: 0px 5px;
  background: #4daf7c;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 1rem;
}

.hiring-tag {
  margin-bottom: 0;
  margin-left: 5px;
  position: relative;
  top: -1px;
}

.anchor {
  display: block;
  position: relative;
  top: -200px;
  visibility: hidden;
}

