.editable-input-container {
  display: inline-block;
  vertical-align: middle;
}

.editable-setter {
  display: inline-block;

  a {
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    margin-top: 4px;

    &.cancel-editable {
      color: $gray;

      &:hover {
        color: mix($gray, black, 90%);
      }
    }

    &.editable-link {
      border-bottom: 1px dotted $links;
    }
  }

  .svg-inline--fa {
    cursor: pointer;
  }

  input {
    cursor: text;

    &:focus {
      outline: none;
      border: 1px solid $primary;
    }

    &.error {
      border-color: $warn;
    }
  }
}

.editable-action-container {
  display: flex;
  justify-content: space-between;

  a {
    border: none;
  }
}

.editable-value {
  margin-right: 5px;
}

.editable-default-text {
  margin-right: 5px;
}
