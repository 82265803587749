.todo-popover-action {
  @include button-pill($light-bg, 15px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: $brown;
  max-height: 39px;
}

.todo-popover {
  .popover-content {
    max-height: initial;
  }

  .date-picker-content {
    border: none;
    box-shadow: none;

    .react-calendar {
      padding-top: 0px;
    }
  }
}

.contact-todos--body {
  &.collapsed {
    position: relative;
    height: 116px;
    overflow-y: hidden;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 40px;
      left: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }
  }
}

.todo-complete {
  text-decoration: line-through;
  opacity: 0.5;
}

.todo-column-description {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 20px;
  width: 100%;
}

.user-row {
  .user {
    max-width: 200px;
  }
}

.assignment-type-icon {
  display: inherit;
  line-height: 0px;
  color: white;
  background: $brown;
  border-radius: 100%;
  margin-right: 10px;
  padding: 5px;
  font-size: 12px;

  &.conversation {
    background: #E8B6FF;
    color: #7000A5;
  }

  &.todo {
    background: #FFECCC;
    color: #965E00;
  }

  &.email {
    background: #DADADA;
    color: #505050;
  }

  &.mention {
    background: #CEF2FF;
    color: #00698E;
  }

  &.issue {
    background: #FFE0E0;
    color: #D30001;
  }
}
