$diagnostics-blue: #00d2ff;
$lighter-gray: #3f3e3e;

.diagnostics {
  margin-top: -60px;

  ::-webkit-scrollbar {
      width: 12px;
  }
   
  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
      border-radius: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $diagnostics-blue;
  }

  color: white;

  .diagnostics-heading, .diagnostics-link {
    color: $diagnostics-blue;
  }

  .diagnostics-heading {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 13px;
  }

  .diagnostics-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .row {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin: 20px 0;
  }

  .subcard {
    flex: 1;
    background-color: $lighter-gray;
    min-width: 200px;
    padding: 20px;

    .subcard-label {
      color: $diagnostics-blue;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 13px;
    }

    .value {
      font-size: 32px;
      font-weight: 700;
    }
  }

  .diagnostics-table-aside {
    width: 66.67%;
    margin-right: 20px;

    .custom-tooltip {
      background-color: $lighter-gray;
    }
  }

  .diagnostics-table-wrapper {
    width: 33.33%;
  }

  .diagnostics-table {
    background-color: inherit;
    max-height: 460px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;

    .diagnostics-table-cell {
      background-color: #3f3e3e;
      font-size: 14px;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 15px;
    }
  }

}

.led {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  vertical-align: middle;

  &.green {
    background-color: #ABFF00;
    animation: green-blink 1s infinite;
  }

  &.red {
    background-color: #F00;
    animation: red-blink 1s infinite;
  }
}


@keyframes green-blink {
  from { background-color: #ABFF00; }
  50% { background-color: #7ebb02; }
  to { background-color: #ABFF00; }
}

@keyframes red-blink {
  from { background-color: #F00; }
  50% { background-color: #A00; }
  to { background-color: #F00; }
}

.diagnostics-enter {
  opacity: 0.01;
}

.diagnostics-enter.diagnostics-enter-active {
  opacity: 1;
  transition: opacity 2s ease-out;
}

.diagnostics-appear {
  opacity: 0.01;
}

.diagnostics-appear.diagnostics-appear-active {
  opacity: 1;
  transition: opacity 2s ease-out;
}

.diagnostics-leave {
  opacity: 1;
}

.diagnostics-leave.diagnostics-leave-active {
  opacity: 0.01;
  transition: opacity 0.5s ease-out;
}

.cookie-net {
  padding: 5px;
  border: 1px solid transparent;
  margin: 2.5px;
  height: 80px;
  width: 80px;
  font-size: 11px;

  &.failing {
    background-color: #E03D35;
  }

  &.healthy {
    background-color: $primary;
  }
}

@mixin part($size, $border, $time, $animation) {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: $border solid transparent;
  border-left: $border solid;
  animation: stop 3s cubic-bezier(.28,1.9,1,.9), $animation $time linear 0s infinite forwards;
}

.diagnostics-loader {
  list-style: none;
  padding: 0;
  margin: 0;
  color: $diagnostics-blue;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  text-align: center;
  animation: scale 3.5s ease;
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -12px;
    margin-left: -12px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid;
    animation: rotation-z 2s linear 0s infinite;
  }
  
  .part {
    border-radius: 50%;
    display: inline-block;
  }

  & > .part{
    @include part(50px, 5px, 2s, rotation-left);
  }
  
  & > .part > .part{
    @include part(40px, 5px, 1s, rotation-right);
  }
  
  & > .part > .part > .part{
    @include part(30px, 4px, 3s, rotation-left);
  }
  
  & > .part > .part > .part > .part{
    @include part(20px, 3px, 10s, rotation-right);
  }
  
  & > .part > .part > .part > .part > .part{
    @include part(10px, 3px, 1s, rotation-left);
    border-right: 3px solid;
  }
    
}

@keyframes scale {
  0%{
    transform: scale(1,1);
  }
  50%{
    transform: scale(1.5,1.5);
  }
  100%{
    transform: scale(1,1);
  }
}

@keyframes stop {
  from{
    transform: rotate(360deg);
  }
  to{
    transform: rotate(0deg);
  }
}

@keyframes rotation-z {
  from{
    transform: rotateY(0deg);
  }
  to{
    transform: rotateY(360deg);
  }
}

@keyframes rotation-right {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

@keyframes rotation-left {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(-360deg);
  }
}
