.login {
  @include span-columns(4 of 12);
  @include shift(4);
  margin-top: 5em;

  h1 {
    margin-bottom: 40px;
  }

  @include media($medium) {
    @include span-columns(12 of 12);
    @include shift(0);
  }
}

.signup, .signup-helper {
  display: inline-block;
  padding: 40px;
  width: 100%;
  flex: 1;

  .button-cta, .button-pill {
    margin: 20px 0;
  }
}

.island {
  display: flex;
  flex-wrap: nowrap;
  margin: 40px 0;
  gap: 40px;
}

.signup {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: $brown;
  background: $light-bg;
  max-width: 480px;

  &.credit-card-form {
    background: white;
  }
}

.payment-header {
  margin: 40px 0;
}

.signup-helper {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background: white;
}

.testimonial {
  .quote {
    font-size: 20px;

    &.full {
      margin: 30px 100px 15px;
    }

    &.condensed {
      margin: 0 0 15px 0;
    }

    @include media($small) {
      margin: 30px 0 15px;
    }
  }

  .attribution {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .testimonial-avatar {
    width: 75px;
    height: 75px;
    border-radius: 100px;
    margin-right: 10px;
    border: 3px solid white;
  }

  .credit {
    p {
      font-size: 16px;
      font-weight: 700;
      opacity: 0.6;

      &:last-of-type {
        font-weight: 400;
      }
    }
  }
}


.step-two {
  @include card;
  padding: 40px;
  margin-top: 40px;
}

.landing-wrapper .step-two {
  background: $beige;
}

.step-two-submit {
  width: 33%;
  margin: auto;

  @include media($medium) {
    width: 100%;
  }

  .input-group {
    margin-bottom: 40px;
  }
}
