.payment-island {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 30px;
  box-shadow: $card-box-shadow;
  padding: 40px;
}

.plan-detail {
  margin-bottom: 20px;
  width: 100%;
}

.plan-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.expiration-date {
  @include span-columns(4 of 6);

  .exp-month, .exp-year {
    @include span-columns(2 of 4);
  }
}

.security-code {
  @include span-columns(2 of 6);
  @include omega();
}

.refund, .contact {
  @include span-columns(6 of 12);
  margin-top: 50px;
  margin-bottom: 100px;

  @include media($medium) {
    @include span-columns(12 of 12);
    margin: 40px 0;
  }
}

.signup-crumbs {
  margin: 20px 0 10px 0;

  span {
    padding-right: 10px;
  }

  .crumb-icon {
    padding-right: 10px;
  }
}

.thank-you-action {
  width: auto;
}

.payment-pills {
  .payment-pill {
    display: flex;
    margin-bottom: 30px;

    .payment-pill-image {
      width: 48px;
      margin-right: 15px;

      &.avatar {
        height: 48px;
        border-radius: 48px;
      }
    }
  }
}

.plan-cell  {
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 2px solid $borders;

  &.active {
    border: 2px solid $primary;
  }

  &:hover {
    border-color: $primary-darker;
  }

  &:focus:active {
    border-color: $primary;
  }

}

.select-plan {
  .pricing-container {
    border: none;
    flex-basis: auto;
    min-width: 300px;
    width: 100%;
    margin-right: 20px;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .plan-details {
    padding: 15px;
    background: $beige-light;

    &.active {
      background: $beige;
    }
  }
}

.checkout-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    width: 300px;
  }
}
