.discovery-content {
  margin-top: 60px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  position: relative;

  .sticky-header {
    position: sticky;
    top: 60px;
    padding: 20px 0;
    z-index: 50;
    background-color: hsla(0, 0%, 100%, .05);
    backdrop-filter: blur(8px);
  }
}

.discovery-page-search-filters {
  @include card;
  padding: 20px;

  .followers-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .discovery-search__input {
    font-size: 14px;
    line-height: 18px;
    color: #333;
    border-radius: 3px;
    background: white;
    padding: 10px;
    border: 1px solid #d4d4dc;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }

  .discovery-search__input, .dropdown-select {
    border: 1px solid #d4d4dc;
    border-radius: 3px;
    padding: 10px;
    width: 150px;
  }

  .small-input {
    width: 75px;
  }

  .large-input {
    width: 250px;
  }
}
