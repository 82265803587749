.profile-type-dd {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0px 5px;
  padding: 10px;

  .text-wrapper {
    display: flex;
    align-items: center;
    padding: 2px 5px 0px;
    border-bottom: 2px solid;
    border-radius: 0px;

    .profile-type-row {
      margin-right: 8px;
    }
  }

  .profile-type-row {
    @include no-highlight;
    line-height: 15px;

    svg {
      margin-right: 3px;
    }
  }

  &-content {
    width: 130px;
    padding: 0px;
    overflow: hidden;
    margin-top: 3px;

    &.hidden {
      display: none;
    }

    .profile-type-option {
      padding: 10px;

      &.active, &:hover {
        background-color: $beige;
      }
    }
  }

  &.no-label {
    margin: 0px;

    .text-wrapper {
      justify-content: center;
      padding: 0px;
      border-bottom: none;

      &:hover {
        color: black;
        background-color: white;
      }

      .profile-type-row {
        margin-right: 0px;
      }

      > svg {
        margin-left: 4px;
      }
    }

    .profile-type-row {
      line-height: 15px;

      svg {
        margin-right: 0px;
      }
    }

    .profile-type-dd-content {
      width: auto;
    }
  }
}
