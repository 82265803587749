.super-wide-container {
  max-width: 1286px;
}
.top-resource-landing {
  background: $beige;
  color: $brown;
  font-size: 20px;
  min-height: 100vh;
}
.top-resource-landing .header {
  background: $brown;
  padding: 120px 0;
}
.top-resource-landing .blog-post-headline {
  color: #fff;
}
.top-resource-landing .blog-subhead {
  color: #fff;
  opacity: .5;
  font-size: 24px;
  line-height: 1.6;
  letter-spacing: 0;
}
.resource-section-container {
  padding: 60px 0;
  display: grid;
  grid-template-columns: 460px auto;
  grid-template-rows: auto;
  grid-template-areas: 
    "head side" 
    "main side" 
    ". side";
}
 .resource-section-title:before {
  content: '';
  display: block;
  position: relative;
  width: 0;
  height: 3em;
  margin-top: -3em;
}
.resource-section-title {
  font-size: 40px;
  letter-spacing: -1px;
}
.resource-description-content {
  max-width: 460px;
  grid-area: head;
}
.resource-thought-container {
  max-width: 460px;
  grid-area: main;
}
.resource-items-container {
  margin-left: 60px;
  width: 774px;
  grid-area: side;
}
.resource-item-card {
  display: inline-block;
  width: 250px;
  height: 400px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 1px 5px rgba(0,0,0,.15);
  overflow: hidden;
  margin: 0px 8px 0px 0px;
  color: $brown;
  transform: translateY(0);
  transition: all .15s ease-in-out;
}
.resource-item-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 2px 10px rgba(0,0,0,.15);
  color: $brown;
}
.resource-item-hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  border-bottom: 1px solid $beige;
  img {
    max-width: 185px;
  }
}
.resource-item-description {
  padding: 12px;
}
.resource-item-tag {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 4px;
}
.resource-item-price {
  font-size: 12px;
  font-weight: bold;
  opacity: .5;
  margin-bottom: 12px;
}
.collection-quick-nav {
  margin-top: 64px;
}
.collection-quick-nav li {
  margin: 0 6px;
  white-space: nowrap;
}
.collection-quick-nav a {
  font-weight: bold;
  text-decoration: underline;
}
.view-more-btn-container {
  text-align: center;
}
.view-more-btn {
  max-width: 112px;
}
.subtle-link {
  font-size: 18px;
  color: $brown;
  text-decoration: underline;
}
.gold {
  color: #F6B03E;
}
.green {
  color: $primary;
}
.orange {
  color: #FF6242;
}
.black {
  color: #333333;
}
.thoughts-container {
  overflow: hidden;
}
.thought-item {
  font-size: 14px;
  display: block;
}
.thought {
  margin-bottom: 4px;
}
.thought-creator {
  display: flex;
  align-items: center;
}
.thought-creator img {
  margin-right: 4px;
}
.thought-creator-name {
  font-weight: bold;
}
.right-submit-button {
  border: 2px solid #4daf7c;
  width: 100%;
  display: inline-block;
  padding: 10px 16px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 18px;
  background-image: none;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 5px;
  -webkit-transition: background-color .15s ease;
  -moz-transition: .15s ease background-color;
  transition: background-color .15s ease;
  color: #fff;
  background-color: #4daf7c;
  width: 120px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;  
}
.large-email-form {
  max-width: 360px;
  margin: 0 auto;
  input {
    font-size: 20px;
    height: 50px;
  }
}

@media screen and (max-width: 1300px) {
  .collection-quick-nav {
    margin-bottom: -2em;
  }
  .resource-section-container {
    max-width: 700px;
    margin: auto;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: 
      "head" 
      "side"
      "main";
  }
  .top-resource-landing .header {
    padding: 120px 0 60px;
  }
  .resource-section-title {
    font-size: 32px;
  }
  .resource-section-title, .resource-section-description {
    padding: 0 15px;
  }
  .resource-description-content {
    max-width: 100%;
  }
  .resource-items-container {
    max-width: initial;
    width: initial;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .resource-items {
    overflow-x: auto;
    display: flex;
  }
  .resource-items .resource-item-card:first-child {
    margin-left: 15px;
  }
  .resource-items .resource-item-card:last-child {
    margin-right: 15px;
  }
  .resource-item-card {
    flex-shrink: 0;
  }
  .thoughts-container {
    overflow-x: auto;
    display: flex;
  }
  .resource-thought-container {
    max-width: 100%;
  }
  .thought-item {
    float: left;
    min-width: 280px;
    margin-right: 20px;
  }
  .thoughts-container .thought-item:first-child {
    padding-left: 15px;
  }
  .thoughts-container .thought-item:last-child {
    padding-right: 15px;
  }
  .view-all-link {
    display: none;
  }
  .view-more-btn-container {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .large-email-form {
    .input-group.embedded-button input[type="submit"] {
      width: 100%;
      margin-top: 4px;
    }
    input {
      text-align: center;
    }
  }
  .resource-item-card:hover {
    transform: initial;
    box-shadow: 0px 1px 5px rgba(0,0,0,.15);
    color: $brown;
  }
}

@media screen and (max-width: 480px) {
  .large-email-form {
    padding: 15px;
  }
}