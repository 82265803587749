.swal2-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between !important;

  .button-pill, .button-pill-inverted {
    width: 40%;
  }
}

.swal2-html-container {
  color: $brown;
  font-size: 16px;
  line-height: 1.4;
}
