.demo-callout {
  width: 100%;
  background: white;
  border-top: 2px solid $borders;
  text-align: left;
  z-index: 999;
  overflow: hidden;
  max-height: 110px;
  position: absolute;
  left: 0;

  @include media($small) {
    max-height: 220px;
  }

  p {
    font-size: 20px;

    @include media($medium) {
      font-size: 16px;
    }
  }

  .cta-subtitle {
    font-size: 16px;

    @include media($medium) {
      font-size: 14px;
    }
  }
}

.demo-callout-container {
  @include outer-container;
  padding: 30px;
  width: 100%;
  margin: auto;
  max-height: 110px;

  @include media($small) {
    max-height: 220px;
  }
}

.demo-callout-teaser, .demo-callout-form {
  @include span-columns(6 of 12);

  @include media($medium) {
    @include span-columns(12 of 12);
  }
}

.demo-callout-teaser {
  color: $brown;
  text-shadow: none;

  @include media($medium) {
    margin-bottom: 20px;
  }
}

.post-submit .thank-you-text {
  color: $brown;
  text-shadow: none;
}

.demo-callout-form {
  .input-cta {
    margin-bottom: 0;

    @include media($medium) {
      font-size: 16px;
    }
  }
}

.default-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: $brown;
  cursor: pointer;
}

.demo-cta {
  padding: 18px 16px;
}

.post-submit .close-demo-callout {
  color: $links;
}