.profile-insights {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  position: relative;
  padding: 20px;
  box-shadow: inset 0px 1px 1px 0px rgba(245,240,236, 0.9);

  @include media($desktop-min) {
    flex-direction: column;
    display: block;
  }

  @include media($medium) {
    padding-top: 40px;
    padding-left: 0;
    padding-right: 0;
  }

  &.has-overlay {
    opacity: 0.1;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      z-index: 9;
    }
  }
}

.insight {
  @include media($desktop-min) {
    flex-basis: 50%;
    width: 100%;
    padding: 20px;

    &:nth-of-type(n+5) {
      margin-top: 0;
    }
  }

  &.engagement-legitimacy-container {
    flex-basis: 250px;

    @include media($small) {
      flex-basis: auto;
    }
  }

  &.audience-gender-container {
    flex-basis: 180px;

    @include media($small) {
      flex-basis: auto;
    }
  }

  &.audience-locations-container, &.audience-languages-container {
    flex-basis: 200px;

    @include media($small) {
      flex-basis: auto;
    }
  }

  &.audience-ages-container {
    flex-basis: 220px;
    display: flex;
    flex-direction: column;

    .insight-title {
      margin-bottom: 10px; // hardcode to ensure proper baseline
    }

    @include media($small) {
      flex-basis: auto;
    }
  }
}

.audience-table-row-item {
  display: flex;
  align-items: center;
}

.insight-title {
  @include smallcaps;
  text-align: center;
}

.engagement-legitimacy {
  background-color: $primary-fill;
  border-radius: 5px;
  padding: 20px;
  margin-top: 10px;
  color: white;
  position: relative;
  text-align: left;
  min-height: 166px;
  z-index: $wonkyStackingOrderZ; // needed to preserve proper svg stacking order

  @include media($medium) {
    min-height: 152px;
  }

  .background-svg {
    z-index: -1;
    right: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .large-ac {
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    margin-right: 5px;
  }

  .small-ac {
    font-weight: 700;
    font-size: 30px;
  }
}

.audience-genders {
  display: flex;
  align-items: center;
  height: 100%;
}

.insights-list {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  text-align: left;

  .background-svg {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .insights-list-table {
    background: inherit;

    td {
      padding: 5px 0;

      .emoji-label {
        position: relative;
        max-width: 20px;
        margin-top: 2px;
        margin-right: 5px;
      }

      .emoji-value {
        position: absolute;
        top: 2px;
      }
    }
  }

  .insight-list-label {
    font-size: 14px;
    font-weight: 700;
  }

  @include media($desktop-min) {
    max-width: 200px;
    margin: 10px auto;
  }
}

.audience-ages {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-end;

  @include media($desktop-min) {
    max-width: 200px;
    margin: 10px auto;
  }
}

.bar {
  display: flex;
  width: 30px;
  padding: 3px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.age-label {
  @include smallcaps;
  text-align: center;
  font-size: 12px;
}

.audience-pie-chart-gender {
  display: block;
  color: $brown;
  font-size: 14px;
  font-weight: 700;;
}

.profile-insights-overlay {
  position: absolute;
  top: 95px;
  left: 30%;
  width: 400px;
  text-align: center;

  @include media($medium) {
    left: 24%;
  }

  @include media($small) {
    max-width: 300px;
    width: 100%;
    left: 50%;
    margin-left: -150px;
  }

  .profile-insights-overlay-header {
    white-space: nowrap;

    @include media($small) {
      white-space: initial;
    }

    img {
      vertical-align: bottom;
      padding-left: 3px;
    }
  }
}

.profile-insights-animation-wrapper {
  width: 100%;
  background-color: white;
}

.insights-reveal-enter {
  transform: scaleY(0);
  transform-origin: 50% 0; /* X Y */
}

.insights-reveal-enter.insights-reveal-enter-active {
  transform: scaleY(1);
  transition: transform 1.5s ease-out;
}

.bar-grow-appear {
  transform: scaleY(0);
  transform-origin: 0 100%; /* X Y */
}

.bar-grow-appear.bar-grow-appear-active {
  transform: scaleY(1);
  transition: transform 0.75s ease-out;
}

.graphs-container {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  @include media($medium) {
    max-width: 100%;
  }
}

.insight-graph-label {
  position: absolute;
  top: 0;
  left: 5px;
  @include smallcaps;
}

.profile-insights-wrapper {
  position: relative;
  width: 100%;
}

.profile-insights-wrapper .insight {
  &:nth-of-type(n + 4) {
    margin-top: 40px;
    margin-bottom: 20px;

    @include media($medium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .audience-ages {
    margin-top: 9px; // weird hardcode is because this element (bar charts) is currently hard to baseline/center in flexbox
  }
}

.profile-insight-hide {
  @include hyperlink($brown);
  position: absolute;
  top: 10px;
  right: 10px;
}

// THIS IS AN OVERRIDE FOR RECHARTS
.recharts-wrapper {
  margin: 0 auto;
} 
