.contact-choose-products-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background: $brown;
  color: white;
  font-weight: 600;
  height: 73px;
  align-items: center;
  width: 100%;
  position: sticky;
  z-index: 500;
  top: 0;

  .action {
    width: 140px;
  }
}

.contact-choose-products-body {
  background: $beige;
  min-height: calc(100vh - 75px);

  .contact-choose-products-breadcrumbs {
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid $darker-borders;
    padding-top: 12px;
    padding-bottom: 12px;
    position: sticky;
    top: 73px;
    background: $beige;
    z-index: $floatingNav;

    display: flex;
    justify-content: center;

    span {
      margin: 0 4px;
    }
  }

  .contact-choose-products-content {
    padding-top: 30px;
    padding-bottom: 250px;

    .card {
      background: white;
      margin-top: 8px;
      padding: 20px;
    }
  }

  .floating-action-bar {
    min-width: 385px;
    max-width: 50vw;

    @include media($small) {
      max-width: 80vw;
    }

    .actions {
      overflow-x: auto;

      .action  {
        width: 115px;

        span.chosen-product-title {
          max-width: 100%;
        }
      }
    }
  }
}

.product-choice-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:first-child) {
    margin-top: 12px;
  }

  img {
    border-radius: 5px;
  }

  .pointer-down {
    color: $black;
    margin-left: 4px;
    font-size: 16px;
    font-weight: 700;
  }

  .buttons-container {
    display: flex;
    height: 30px;
  }

  .description {
    display: inline-block;
    vertical-align: bottom;
    max-width: calc(66vw - 600px);
  }

  .quantity-added-container {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid $borders;
    border-radius: 3px;
    margin: 0 5px;
    width: 28px;
  }
}

@media screen and (max-width: 480px) {
  .product-choice-list-item {
    .description {
      max-width: 200px;
    }
  }
}

.recipient-shipping-details-summary,
.recipients-shipping-details-summary {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid $darker-borders;
  border-radius: 5px;
}

.recipients-shipping-details-summary {
  .user-avatar {
    position: relative;

    .recipients-avatar-summary {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      background: rgba($black, 0.8);
    }

    .recipients-avatar-summary-count {
      color: white;
    }
  }

  .recipients-names-summary {
    max-width: 250px;
  }
}

.recipient-shipping-details-container +  .recipient-shipping-details-container {
  margin-top: 25px;
  border-top: 1px solid $darker-borders;
  padding-top: 25px;
}

.no-image-container {
  background: $beige;
  border: 1px solid $borders;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.contacts-send-product-modal {
  .contact-choose-products-header-wrapper {
    position: sticky;
    top: 0;
    z-index: $floatingNav;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid $darker-borders;
    height: 75px;
  }
}

.slide-transition-container .contact-choose-products-body .floating-action-bar {
  transform: translateX(25%);
}
