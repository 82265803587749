.search {
  width: 100%;
  margin-bottom: 40px;

  form {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -10px 0 0 -10px; // allow child flexboxes to handle their own margins before and after wrap
  }

  .input-group {
    flex: 2;
    flex-basis: 311px;
    vertical-align: middle;
    margin: 10px 0 0 10px;
    min-width: 220px;

    .input-cta {
      margin-bottom: 0;
    }
  }

  .evaluator-cta {
    flex: 1;
    flex-basis: 190px;
    align-items: center;
    text-indent: 0 !important;
    font-size: 20px;
    margin: 10px 0 0 10px;
    min-width: 220px; 
  }
}

.message {
  margin: 15px 0;
}

.evaluator {
  @include card;
  width: 100%;
  max-width: 960px;
  margin-bottom: 40px;
  font-weight: 500;
}

.avatar-cell {
  width: 48px;
}

.avatar {
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  text-align: center;
  vertical-align: bottom; // gets rid of extra whitespace on bottom when enclosed by an anchor tag

  &.mini {
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 32px;

    &:before {
      width: 32px;
      height: 32px;
      border-radius: 32px;
    }
  }
}

.score {
  width: 60px;

  &.gradient-1 {
    color: #1bb91b;
  }

  &.gradient-3 {
    color: #e42e2e;
  }
}

.actions {
  display: flex;
  gap: 10px;
}

.history {
  clear: both;

  @include media($small) {
    overflow-x: scroll;
  }
}

.hide-profile-leave {
  opacity: 1;
}

.hide-profile-leave.hide-profile-leave-active {
  opacity: 0.01;
  transition: opacity 250ms ease-in;
}

.email {
  font-size: 14px;
}

.purchase-tag {
  font-size: 12px;
  opacity: 1;
  vertical-align: middle;
}

.rating-cell {
  min-width: 115px;
}

.star-rating {
  line-height: 16px;
}

.lookup-filters {
  display: flex;
  line-height: 20px;
}

.lookup-filters-items {
  li {
    padding: 0 10px;
    border-right: 1px solid $borders;

    &:last-child {
      border: none;
    }
  }
}

.zero-state-headline {
  font-weight: 400;
  color: $brown;
  text-align: center;
  margin-bottom: 60px;
}

.zero-state-insights-container p {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
} 
