$google-btn-xy-ratio: 4.15;

#google-sign-in {
  cursor: pointer;
  width: 250px;
  height: calc(250px / $google-btn-xy-ratio);

  background-image: url('/images/oauth/google-sign-in/normal.png');
  background-position: center;
  background-size: contain;

  &:hover {
    background-image: url('/images/oauth/google-sign-in/focus.png');
  }
  &:active {
    background-image: url('/images/oauth/google-sign-in/pressed.png');
  }
}
