.contact-todos {
  padding: 20px;
  gap: 20px;
  display: flex;

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--body {
    margin-top: 20px;

    &.has-list {
      margin-left: -20px;
    }
  }
}

.contact-todos .todo-row, .todo-row-dragging .todo-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  @include media($small) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .checkbox {
    @include media($small) {
      margin-right: 10px;
      margin-left: -35px;
    }
  }

  .todo-row-description-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    width: 0;

    @include media($small) {
      width: 100%;
    }
  }

  .todo-row-description {
    margin-left: 10px;
    cursor: pointer;
    width: 0;
    flex-grow: 1;

    @include media($small) {
      margin-left: 5px;
    }
  }

  .todo-row-actions {
    display: flex;
    align-items: center;

    @include media($small) {
      margin-top: 5px;
    }
  }

  .due-date-column {
    text-align: right;
    cursor: pointer;
    white-space: nowrap;
    width: 70px;
  }
}

.todo-row-draggable {
  display: flex;
  align-items: center;

  .todo-drag-handle {
    cursor: grab;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .todo-row {
    flex-grow: 1;
  }
}

.todo-row-dragging {
  z-index: 1000;
}
