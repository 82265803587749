.thread-box {
  .toolbar {
    @include sticky-header;
    @include toolbar-layout;

    .actions {
      display: flex;
      align-items: center;
    }
  }

  .email-row {
    &:not(:first-child) {
      border-top: 1px solid $b-block-border;
    }

    .date-time {
      font-size: 12px;
      text-wrap: nowrap;
    }

    .summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 12px;

      .sender, .date-time {
        flex-shrink: 0;
        flex-grow: 0;
      }
      .snippet {
        width: 0;
        flex-grow: 1;
        margin-left: 5px;
        margin-right: 16px;
      }

      &:hover {
        background-color: rgba(0,0,0,0.07);
      }
    }

    .details {
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 12px;
        background-color: rgba(0,0,0,0.07);

        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .directions {
        margin-left: 12px;
      }

      .body {
        padding: 12px;
      }
    }

    .reply-container {
      position: relative;
      margin: 12px;
      border-radius: 8px;
      box-shadow: $card-box-shadow;
    }
  }
}
