.contacts-popover {
  z-index: calc($floatingNav + 1);

  .list-window {
    position: relative;
    z-index: calc($floatingNav + 1);
  }
}

.contact-select {
  .contact-card {
    .avatar-cell {
      position: relative;

      &__type {
        @include profile-type-badge(18px, -4px, -4px);
      }
    }
  }
}

.address-line {
  font-weight: 500;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid $darker-borders;
  background: $beige;
  border-radius: 6px;
  margin-bottom: 20px;
}