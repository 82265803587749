:root {
  accent-color: $brown;
}

html, body {
  height: 100%;
}

body {
  font-family: $primary-font, 'HelveticaNeue', serif;
  line-height: 1.4;
  background-color: #fff;
  font-weight: 400;
  color: $brown;

  &.is_presentation {
    background-color: $beige;
  }
}

img {
  max-width: 100%;
}

.wrapper {
  background-color: $beige;
  min-height: 100vh;
  padding: 20px 20px 20px 220px;
  transition: 1s background-color ease-out;
  overscroll-behavior: contain;

  @include media($medium) {
    padding-left: 100px;
  }

  @include media($small) {
    padding-left: 20px;
    padding-bottom: 100px;
  }
}

.influencer-wrapper {
  background-color: $beige;
  min-height: 100vh;
}

.landing-wrapper {
  background: white;
  padding: 80px 15px 0 15px;
  min-height: 100%;
  margin-bottom: -60px; // equal to footer height for sticky footer

  // for sticky footer
  &:after {
    content: "";
    display: block;
  }

  &.no-padding {
    padding: 0;
  }

  &.reports-wrapper {
    background-color: $beige;
  }

  @include media($small) {
    padding-top: 160px;
  }

  .filters-wrapper {
    top: 60px;
    left: 0;
    padding-right: 0;
  }
}

footer, .landing-wrapper:after {
  height: 60px;
}

footer {
  background: white;
  text-align: center;
  line-height: 60px;
  clear: both;

  p {
    font-size: 14px !important;
  }
}

.absolute {
  position: absolute;
}

.m0 {
  margin: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

.mt5 {
  margin-top: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb5 {
  margin-bottom: 5px;
}

.mt10 {
  margin-top: 10px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.gap5 {
  gap: 5px;
}

.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 20px;
}

.pl10 {
  padding-left: 10px;
}

.inverted-background {
  background-color: $beige;
}

.brown-lighter {
  color: $brown-lighter;
}

.brown-background {
  background-color: $brown;
}

.black-background {
  background-color: #333;
}

.green-background {
  background-color: $primary;
}

.green-color {
  color: $primary;
}

.pink {
  color: $pink;
}

.red {
  color: $red;
}

.max-container {
  @include fill-parent;
}

.container {
  @include outer-container;

  @media only screen and (min-width: $wide-width-min) {
    @include outer-container(1440px);
  }
}

.campaign-media-container {
  @include outer-container;

  // 4-columns if viewpoint is 1535px or wider but less than 1921px ($wide-width-min)
  @media only screen and (min-width: 1535px) and (max-width: 1920px) {
    @include outer-container(1280px);
  }

  // 5-columns if viewpoint is 1921px or wider
  @media only screen and (min-width: $wide-width-min) {
    @include outer-container(1600px);
  }
}

.pricing-wider-container {
  max-width: 1180px;
  margin: 0 auto;

  .c-header__description-main {
    color: $brown;
  }
}

.min-height-0 {
  min-height: 0;
}

.max-height {
  height: 100%;
}

.max-width {
  width: 100%;
}

.main-content-container {
  margin: auto;
  margin-bottom: 5%;
  @include media($small){
    margin-top: 20px;
  }
}

.relative-container {
  position: relative;
  clear: both;
}

.relative-inline-container {
  display: inline-block;
  position: relative;
  clear: both;
}

.single-page-view {
  max-width: 960px;
  margin: auto;
}

p {
  font-size: 16px;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

ul {
  margin: 0;
  padding: 0;

  &.block {
    li {
      display: block;
    }
  }
}

li {
  display: inline;
}

a {
  color: $links;
  text-decoration: none;
  cursor: pointer;

  &:active {
    color: $links-darkest;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    color: $links-darker;
  }

  &.primary {
    color: $primary;

    &:active {
      color: $primary-darkest;
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
      color: $primary-darker;
    }
  }
}

.primary {
  color: $primary;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.thin {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.strong {
  font-weight: 600;
}

.strongest {
  font-weight: 700;
}

.del {
  text-decoration: line-through;
}

.pointer {
  cursor: pointer;
}

.inline {
  display: inline-block;
}

ul.vertical-list li {
  display: block;
}

.smallcaps {
  @include smallcaps;
}

.text-uppercase {
  text-transform: uppercase;
}

.error-message {
  color: $warn;

  a {
    @include hyperlink($warn);
  }
}

.warning-message {
  color: #F9B150;

  a {
    color: #F9B150;
  }
}

.button-marketing {
  @include button-pill($primary, 16px);
}

.button-cta {
  @include button-pill($primary, 24px);
}

.button-contrast {
  @include button(#7ED321, 24px);
}

.button-pill {
  @include button-pill($primary, 15px);
}

.button-pill-contrast {
  @include button-pill($contrast, 15px);
}

.button-pill-link {
  @include button-pill($links, 15px);
}

.button-pill-minor {
  @include button-pill($gray, 15px);
}

.button-pill-dark {
  @include button-pill(#575A7D, 15px);
}

.button-pill-minor-theme {
  @include button-pill($beige, 15px);
  color: $brown;

  &:hover, &:active:focus {
    color: $brown;
  }
}

.button-pill-inverted {
  @include button-inverted-pill($gray, 15px);
}

.button-pill-inverted-light {
  @include button-inverted-pill(white, 15px);
}

.button-light-primary {
  @include button-pill-light($primary, 15px);
}

.button-inverted {
  @include button-inverted-pill($brown, 15px);

  &.no-borders {
    border-color: transparent;
  }
}

.button-sub-action {
  @include button-pill($black, 15px);

  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: white;
  }
}

.button-pill-brown {
  @include button-pill($brown, 15px);
}

.button-plain-warn {
  color: $warn;

  &:hover {
    color: scale-color($warn, $lightness: -10%);
  }
}

.attached-buttons {
  display: flex;

  .button-attach-left {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .button-attach-right {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -2px;
  }
}

.one-third {
  flex: 1 0 33.33% !important;

  @include media($medium) {
    flex: 1 0 100% !important;
  }
}

.two-third {
  flex: 1 0 64.66% !important;

  @include media($medium) {
    flex: 1 0 100% !important;
  }
}

.mobile {
  display: none;

  @include media($medium) {
    display: block;
  }
}

.mobile-flex {
  display: none;

  @include media($medium) {
    display: flex !important;
  }
}

.mobile-inline {
  display: none;

  @include media($medium) {
    display: inline-block !important;
  }
}

.desktop {
  display: block;

  @include media($medium) {
    display: none;
  }
}

.desktop-flex {
  display: flex;

  @include media($medium) {
    display: none !important;
  }
}

.desktop-inline {
  display: inline-block;

  @include media($medium) {
    display: none !important;
  }
}

.de-emphasize {
  opacity: 0.6;
}

.italicize {
  font-style: italic;
}

.half {
  @include span-columns(6 of 12);
}

.small {
  font-size: 12px;
}

.smallish {
  font-size: 14px;
}

.space-bottom, .mb20 {
  margin-bottom: 20px !important;
}

.space-bottom-half {
  margin-bottom: 10px !important;
}

.space-top, .mt20 {
  margin-top: 20px !important;
}

.space-right {
  margin-right: 20px;
}

.padding {
  padding: 20px;
}

.brown {
  color: $brown;
}

.contrast {
  color: $contrast;
}

.bg-beige {
  background: $beige;
}

.bg-brown {
  background: $brown;
}

.gray {
  color: $gray;
}

.card {
  @include card;

  &.with-padding {
    padding: 60px;
    @include media($small){
      padding: 30px;
    }
  }

  &.with-normal-padding {
    padding: 20px;
  }

  &.inverted-background {
    background-color: $beige;
  }
}

.text-only-card {
  max-width: 700px;
}

.disabled {
  cursor: text;
  color: $gray;

  &:hover {
    color: $gray;
  }
}

.disabled-noaction {
  cursor: not-allowed;
  user-select: none;
}

.nowrap {
  white-space: nowrap;
}

.pre-line {
  white-space: pre-line;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-wrap-break-word {
  max-width: 100%;
  overflow-wrap: break-word;
}

.single-action-page-button {
  width: 400px;
}

.with-borders {
  border: 1px solid $borders;
}

.border-top {
  border-top: 1px solid $borders;
}

.border-radius-small {
  border-radius: 5px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-align-center {
  align-items: center;
}

.flex-align-baseline {
  align-items: baseline;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-column {
  flex-direction: column;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column-mobile {
  @include media($medium) {
    flex-direction: column;
  }
}

.flex-column-desktop-min {
  @include media($desktop-min) {
    flex-direction: column;
  }
}

.flex-align-center-mobile {
  @include media($medium) {
    align-items: center;
  }
}

.flex-one {
  flex: 1;
}

.flex-ellipsis {
  flex-shrink: 1;
  flex-grow: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flex-filler {
  width: 0;
  flex-grow: 1;
}

.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 20px;
}

.no-data-text {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.full-width {
  width: 100%;
}

.content-box-wrapper {
  margin: 40px 0;

  .content-box {
    padding: 20px;
    margin: -20px;
    background: $beige;
    color: $brown;
  }
}

.stackable-wrapper {
  column-width: 320px;
    column-gap: 15px;
    width: 90%;
    max-width: 1100px;
    margin: 50px auto;

  .stackable-item {
    display: inline-block;
    column-break-inside: avoid;
  }
}

.hyperlink {
  @include hyperlink($brown);

  &.underline {
    text-decoration: underline;
  }
}

.blue-hyperlink {
  @include hyperlink($links);

  &.underline {
    text-decoration: underline;
  }
}

.gray-hyperlink {
  @include hyperlink($gray);
}

.primary-hyperlink {
  @include hyperlink($primary);
}

.white-hyperlink {
  @include hyperlink(white);
}

.no-highlight {
  @include no-highlight;
}

.popover {
  z-index: $modalZ;
}

.popover-content {
  overflow: auto;
  max-height: 400px;
  max-width: 75vw;
}

.pad-right {
  margin-right: 8px;
}

.cap-text {
  text-transform: capitalize;
}

.card-section {
  padding-bottom: 20px;
  border-bottom: 1px solid $borders;
}

.line-height-one {
  line-height: 1;
}

.line-height-zero {
  line-height: 0; // to remove the excess height in wrapping entities
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.auto-width, .fit-content {
  width: fit-content;
}

.word-break-all {
  word-break: break-all;
}

.grabbable {
  cursor: move; // fallback if grab cursor is unsupported
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.dragged-item {
  z-index: 1000;

  input[type="text"] {
    background-color: white;
  }
}

.theme-box-shadow {
  box-shadow: $card-box-shadow;
}

.symbol-icons {
  font-family: 'arial';
  background-color: inherit;
  filter: brightness(0.8);
  padding: 2px 4px;
  border-radius: 5px;
}

.how-it-works-card {
  text-align: center;

  .how-cell-wrapper {
    display: flex;
    justify-content: space-between;
    @include media($small){
      display: block;
    }
  }

  .button-pill {
    width: auto;
    @include media($small){
      width: 100%;
    }
  }

  .button-inverted {
    width: auto;
    @include media($small){
      width: 100%;
    }
  }

  .how-cell-wrapper .how-cell {
    flex-basis: 250px;
    margin: 0 0 40px;
  }
}

.new-feature-title {
  font-size: 60px;
  padding: 60px;
  color: $brown;
  text-align: center;
  @include media($small){
    font-size: 50px;
    padding: 40px 0;
    line-height: 1.2;
  }
}
