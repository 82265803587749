@import "neat-helpers";

// Change the grid settings
$column: 90px;
$gutter: 30px;
$grid-columns: 12;
$max-width: 960px;
$wide-width-min: 1921px;

// Define your breakpoints
$desktop-min: new-breakpoint(max-width 1080px 8);
$medium: new-breakpoint(max-width 768px 8);
$small: new-breakpoint(max-width 480px 4);

// Extra custom breakpoints
$desktop-large-special-graphic: new-breakpoint(max-width 1300px 8);
$desktop-1150: new-breakpoint(max-width 1150px 8);
$desktop-medium-special-graphic: new-breakpoint(max-width 950px 8);
$bulma-tablet: new-breakpoint(max-width 1024px 8);
