$base-spacing: 1.5em !default;
$flashes: (
  "alert": #fff6bf,
  "error": #fbe3e4,
  "notice": #e5edf8,
  "success": #dce8af,
) !default;

@each $flash-type, $color in $flashes {
  .flash-#{$flash-type} {
    background-color: $color;
    color: shade($color, 80%);
    display: block;
    margin-bottom: 20px;
    padding: $base-spacing / 2;
    text-align: center;
    border: 1px solid mix($color, black, 90%);

    a {
      color: shade($color, 70%);
      text-decoration: underline;

      &:focus,
      &:hover {
        color: shade($color, 90%);
      }
    }
  }
}

.flash {
  position: relative;

  .close-button {
    position: absolute;
    right: $base-spacing / 4;
    top: $base-spacing / 4;
  }
}

.floating-notification {
  position: fixed;
  top: 20px;
  z-index: $floatingNotification;
  width: calc(100% - 40px);
  right: 20px;
  left: 20px;

  &.admin {
    top: auto;
    bottom: 20px;
  }

  &.expiring {
    opacity: 0;
    animation: fadeOut 5s cubic-bezier(1,0,1,0) 1s;
  }
}

.undo-action {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: $floatingNotification + 1;

  .undo-button {
    @include button-inverted($brown, 14px, $width: auto);
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.15);
    border-radius: 5px;
    background-color: white;

    &:hover {
      background-color: white;
    }

    &:active:focus {
      background-color: white;
    }
  }
}

@keyframes fadeIn {
  0% { opacity: 0; visibility: hidden; }
  100% { opacity: 1; visibility: visible; }
}

@keyframes fadeOut {
  0% { opacity: 1; visibility: visible; }
  100% { opacity: 0; visibility: hidden; }
}