.invite-forms-table {
  .limit-width {
    @include truncate;
    max-width: 100px;
  }
}

.invite-form-edit {
  .toggle-field {
    border: 1px solid $gray;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    margin-top: 6px;
  }

  .invite-form-edit-question {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $darker-borders;

    .react-autosuggest__suggestions-container {
      width: 200px;

      .suggestion-content {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 10px;
      }
    }
  }

  input[type=text], textarea {
    background: white;
  }

  .simple.field {
    border: 1px solid $gray;
  }
}

.invite-form-review {
  padding: 20px;
  display: flex;
  justify-content: center;

  > div {
    flex: 1;
    max-width: 750px;
  }

  .card {
    padding: 20px;
  }

  .crm-url {
    padding: 10px;
    border: 1px solid $darker-borders;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .crm-url-link-icon-wrapper {
      display: inline-block;
      padding: 10px;
      line-height: 0px;
      color: $black;
      background: $beige;
      border-radius: 100%;
    }

    .crm-url-link-display {
      font-weight: bold;
    }

    .crm-url-note {
      color: $gray;
    }
  }
}

.popover .popover-content {
  &.crm-url-popover-content {
    padding: 15px;
  }

  .disabled-field {
    opacity: 0.5;
  }
}

.invite-form {
  padding: 20px;
  padding-bottom: 100px; // offer viewport slack when scrolled to the bottom

  .header-accent {
    @include smallcaps;
    line-height: 14px;
    font-size: 14px;
    color: $gray;
  }

  .header-text {
    text-align: center;
  }

  .invite-form-question {
    @include card(0);

    height: auto;
    margin-top: 20px;
    padding: 20px;
    transition: height 400ms ease-out, margin-top 450ms;

    &.invite-form-question-hidden {
      height: 0;
      margin-top: 0;
      padding: 0;
      overflow: hidden;
    }

    &.invite-form-question-highlight {
      box-shadow: 0px 1px 1px $primary, 0px 0 1px $primary-fill;

      .strong, .strong-label {
        color: $primary;
      }
    }
  }

  .thanks-card {
    @include card(20px);
    margin-top: 20px;
  }

  button {
    width: 300px;
  }
}

.invite-form-influencer-page-container .invite-form-question {
  transition: none;
}

.invite-form-influencer-actions-container {
  margin: 20px auto;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .dot {
    height: 10px;
    width: 10px;

    cursor: pointer;

    background-color: $black;
    border-radius: 50%;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &.active {
      background-color: $primary;
    }
  }
}

.profile-application-reason {
  font-size: 14px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: normal;
}

.profile-screenshots-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  padding: 20px 20px 20px 0;

  .media-hero-img-wrapper {
    width: 300px;
    min-height: 210px;
  }
}

.identity-external-link {
  @include truncate;

  color: $brown-lighter;
  border-bottom: 1px dotted $brown-lighter;
  display: inline-block;
  width: fit-content;
  max-width: 250px;

  &:hover {
    border-bottom: 1px dotted transparent;
    color: $brown-lighter;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.media-hero-img-wrapper {
  .screenshots-thumbnail {
    height: 50px;
    min-width: 50px;
  }
}

.submissions-filters-popup {
  .submissions-select-filter__control {
    margin: 10px 0;
    line-height: 24px;
  }
}

.submission-card {
  @include card;
  display: flex;
  flex-direction: column;
  color: $brown;
  padding: 0;

  .submission-content {
    display: flex;
    flex-direction: row;
    gap: 10px;

    @include media($desktop-min) {
      flex-direction: column;
      gap: 20px;
    }
  }

  .submission-top-level {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $darker-borders;
    padding: 12px 20px;

    .mini-nav {
      margin-top: 8px;

      .nav-item {
        line-height: 0;
      }
    }
  }

  .submission-aside {
    display: flex;
    flex-direction: column;
    max-height: 598px;
    overflow-y: scroll;
    flex: 1 0 350px;
    color: $brown-lighter;

    .submission-profile-stats-wrapper {
      border-bottom: 1px solid $borders;
      padding: 20px;
    }

    .submission-profile-stats {
      max-width: 350px;

      .user-details, .averages, .traits {
        padding: 0;
        border: 0;
        box-shadow: none;
      }

      .stat-label {
        display: flex;
        align-items: center;
      }

      .profile-stat-labels {
        margin-bottom: 20px;
      }

      .stat-count {
        font-size: 18px;
      }

      .profile-stats .stat-count {
        font-size: 16px;
      }
    }

    .submission-card-details {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;

      .strong-label {
        margin-bottom: 0;
      }

      @include media($small) {
        height: 0; //View details to show
        padding: 0;
        overflow: hidden;
      }
    }

    // animation after click-to-reveal
    &.revealed {
      @include media($small) {
        .view-details-container {
          opacity: 0;
          z-index: 0;
        }

        .submission-card-details {
          height: 100%;
          padding: 20px;
          transition: height .5s ease-out;
          overflow: visible;
        }
      }
    }

    @include media($desktop-min) {
      border-bottom: 1px solid $darker-borders;
      margin-bottom: 20px;
    }
  }
}

.editable-identity-field {
  cursor: pointer;
  min-width: 100px;

  .placeholder {
    .default {
      display: inline-block;
    }

    .display-on-hover {
      display: none;
    }
  }

  .placeholder:hover {
    .default {
      display: none;
    }

    .display-on-hover {
      display: inline-block;
    }
  }
}

.submission-card-details .editable-identity-field {
  white-space: pre-line;
  word-wrap: break-word;
}

.submissions-table .editable-identity-field {
  max-width: 250px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: normal;
}

.edit-identity-long-text {
  display: block;
  width: 100%;
  min-width: 300px;
  outline: 2px solid $brown;
}
