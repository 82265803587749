.profile-editable-cell {
  min-height: 44px;
}

.profile-editable-cell:hover .placeholder {
  visibility: visible;
}

.profile-editable-edit.popover .button-pill {
  width: auto;
  float: right;
}
