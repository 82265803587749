#campaign-new {
  margin-top: -60px;
}

.new-campaign-title {
  font-size: 60px;
  margin: 60px;
  color: $brown;
  text-align: center;
}

.headline-helper-text {
  margin-top: -60px;
  margin-bottom: 60px;
}

.new-campaign-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  text-align: left;

  .intro-body-text {
    font-size: 24px;
    font-weight: 500;
    text-align: left;
  }

  .campaign-start, .campaign-start-aside {
    max-width: 385px;
    width: 100%;
  }
}

.campaign-start-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;

  a {
    width: 50%;
    font-size: 20px;

    &.next-button {
      @include button-pill($primary, 20px);
      width: 50%;
    }
  }
}

.campaign-setup-container {
  padding: 30px;
  max-width: 630px;
  width: 100%;
  margin: auto;

  .form-image-container {
    text-align: center;
    margin-bottom: 2em;
  }

  .setup-body-text {
    font-weight: 500;
    margin-bottom: 6px;
  }

  .setup-body-text-subtitle {
    margin-bottom: 6px;
  }

  .campaign-setup-actions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    .next-button {
      @include button-pill($primary, 15px);
      width: auto;
    }

    .cancel-button {
      @include button-inverted-pill($brown, 15px);
      width: auto;
    }
  }
}

.campaign-setup-target-item {
  align-items: center;
  background: $beige;
  border-radius: 3px;
  display: flex;
  font-weight: 600;
  grid-gap: 10px;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 15px;

  .entered-target-container {
    display: flex;
    max-width: 100%;
    min-width: 0;
    width: 100%;
  }

  &.campaign-label:not(.flex-width-icon) {
    .svg-inline--fa {
      width: 1.25em;
    }
  }
}

.campaign-setup-brand-list .target-input-wrapper {
  display: flex;
  align-items: center;
}

.target-input-wrapper {
  min-width: 320px;
  display: flex;
  align-items: center;
}

.enter-username-textarea {
  min-height: 140px;
}

.remove-target {
  color: $gray;
  cursor: pointer;

  &:hover {
    color: $brown;
  }
}

#campaign-intro {
  margin-top: -60px;
}

.small-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.fillable {
  color: $brown;
  margin-top: 120px;
  margin-bottom: 120px;

  @include media ($small) {
    margin: 80px 0;
  }
}

.campaign-report-title {
  font-size: 32px;
  text-align: center;
  margin: 30px 0;
}

.campaign-report-subtitle {
  text-align: center;
  margin-bottom: 30px;

  .subtitle {
    color: $brown;
    font-weight: 500;
    font-size: 22px;
  }
}

.campaign-stat-value {
  font-size: 36px;
  font-weight: 700;

  @include media($small) {
    font-size: 24px;
    font-weight: 600;
  }
}

.campaign-stat-value-subtitle {
  font-size: 14px;
  color: rgba(91, 81, 71, 0.5);

  &.manual-opacity {
    opacity: 1;
  }
}

.tracking-results {
  display: flex;
  border-top: 1px solid $brown;
  margin-top: 15px;
  @include media($small){
    overflow-x: scroll;
  }
  .post-panel {
    width: 50%;
    min-width: 300px;

    &:first-of-type {
      margin-right: 10px;
    }

    .small-title {
      padding: 30px 0;
    }
  }
}

.add-profile-tracking-button {
  border: 2px dashed $brown;
  border-radius: 6px;
  color: $brown;
  font-size: 16px;
  width: 100%;
  background: transparent;
  padding: 20px 15px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    border-style: solid;
  }
}

.tracking-list {
  display: flex;
  flex-direction: column;
}

.action-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 15px;
  width: 100%;

  &.mobile {
    display: none;

    @include media($medium) {
      display: flex;
    }
  }

  &.desktop {
    display: flex;

    @include media($medium) {
      display: none;
    }
  }

  .action {
    width: auto;
    margin-right: 8px;
    border: transparent;

    &:last-child {
      margin-right: 0;
    }
  }
}

.campaign-filter-calendar-wrapper {
  z-index: 1;
  border-radius: 6px;
  margin-top: 20px;

  .react-calendar {
    border: none;
    width: initial;
  }

  button.react-calendar__navigation__label {
    font-weight: bold;
    font-size: 20px;
  }

  button.react-calendar__navigation__arrow {
    font-size: 34px;
  }

  button.react-calendar__navigation__label:disabled {
    background: none;
    color: $brown;
  }

  button.react-calendar__navigation__arrow:disabled {
    background: none;
    color: $brown-half;
  }

  .react-calendar__navigation button:enabled:focus, .react-calendar__navigation button:enabled:hover {
    background: none;
  }

  .react-calendar__navigation button:enabled:hover {
    color: $brown-half;
  }

  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }

  button.react-calendar__tile:disabled {
    background-color: initial;
    color: $brown-half;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: $brown-half;
  }
  .react-calendar--selectRange button.react-calendar__tile:enabled:hover, .react-calendar--selectRange .react-calendar__tile--hover, button.react-calendar__tile--active:enabled:hover, button.react-calendar__tile--hasActive:enabled:hover  {
    background-color: $primary-fill-half;
    color: $primary-darkest;
  }
  button.react-calendar__tile--active, button.react-calendar__tile:enabled:focus, .react-calendar__tile--hasActive {
    background: $primary-fill;
    color: white;
  }

  .campaign-filter-calendar {
    color: $brown;
    border: none;
    border-bottom: 1px solid $beige;
    font-family: $primary-font;
  }

  .calendar-footer {
    display: flex;
    flex-direction: column;
    border-top: none;
    padding: 20px 15px 0 15px;
    background: white;
    color: $brown;
  }

  .calendar-footer-actions {
    display: flex;
    text-align: right;
    justify-content: space-between;
  }

  .date-dot {
    position: absolute;
    color: $blue;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tracking-media-card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 8em;
  page-break-inside: 'avoid';
  @include media($desktop-1150){
    justify-content: center;
  }
}

.tracking-story-card-container {
  justify-content: space-evenly;
}

#campaign-presentation .tracking-media-card-container {
  margin-top: 0; // no margin-top for presentation view
}

.media-grid-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; // so on mobile, media item heights won't align (due to additional metrics)
  flex-wrap: wrap;
}

.media-grid-meta-container {
  margin-top: 8px;
  margin-bottom: 40px;

  @include media($small){
    margin: 0;
    padding: 0;
    background: white;
  }

  .profile-stats {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;

    @include media($small){
      padding: 10px 10px 5px 10px;
      font-size: 14px;
      margin-bottom: 0px;
    }
  }

  .grid-line-item {
    display: flex;
    line-height: 2.2;
    justify-content: space-between;
    align-items: center;
  }
  .media-grid-meta-followers {
    @include media($small){
      display: none;
    }
  }
  .media-grid-mobile-label-squeeze {
    @include media($small){
      line-height: 1.2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
    }

    .media-grid-item-meta-label {
      @include media($small) {
        @include smallcaps;
      }
    }
  }
  .posted-time-meta-item {
    @include media($small){
      display: none;
    }
    .media-grid-item-meta-value {
      font-weight: 400;
    }
  }
}

.media-grid-item-mobile-header {
  display: none;
  padding: 10px;
  border-top: 1px solid #eee;
  align-items: center;
  font-size: 14px;
  background: white;

  @include media($small) {
    display: block;
    width: 100%;
  }

  .fa-users {
    font-size: 9px;
  }
}

.media-avatar {
  max-width: 20px;
  height: 20px;
  margin-right: 6px;
  border-radius: 3px;
}

.media-grid-mobile-avatar-container {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}

.meta-separator {
  color: $brown-half;
  padding: 0 5px;
}

.media-grid-mobile-item-followers {
  margin-right: 2px;
}

.media-grid-item-headline {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @include media($small){
    display: none;
  }

  .media-avatar {
    max-width: 24px;
    width: 24px;
    height: 24px;
  }

  .media-grid-item-avatar-container, .media-grid-item-settings-container {
    display: flex;
    align-items: center;
  }

  .media-grid-item-type {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: $brown-half;
    text-align: right;
    margin-right: 0px;
  }

  .media-creator-username {
    margin-left: 6px;
  }
}

.media-grid-item-settings-container {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  color: $brown-half;
  text-align: right;

  .profile-type-icon {
    font-size: 16px;
    margin-right: 5px;
  }
}

.grid-item-profile-stat {
  @include smallcaps;
  font-size: 12px;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  @include media($small) {
    font-size: 14px;
  }
}

.campaign-media-item-settings {
  display: inline-block;
  font-weight: 400;
  text-align: left;

  .campaign-media-item-settings-trigger {
    @include hyperlink($brown-half);
    cursor: pointer;
  }
}

.media-grid-item-meta-value {
  font-weight: 600;
  display: flex;

  .link {
    color: $brown-half;
    font-size: 14px;
  }
}

.media-hero-container {
  @include no-highlight;
  display: inline-block;
}

.media-meta-container {
  max-width: 500px;
  width: 100%;
  @include media($desktop-1150){
    margin-top: 1em;
    max-width: 100%;
  }
}

.media-creator {
  width: 100%;
}

.story-meta-container {
  text-align: center;
  width: initial;
  min-width: 335px;

  .media-creator {
    margin-top: 1em;
  }
  @include media($desktop-medium-special-graphic){
    margin-right: 1em;
  }
  @include media($medium){
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 1em;
    width: 100%;
  }
}

.media-type-title {
  text-transform: uppercase;
}

.media-creator-container {
  display: flex;
  grid-template-columns: 50px 350px;
  grid-template-rows: auto;
  align-items: center;
  grid-column-gap: 5px;

  .media-avatar-container {
    max-height: 50px;
    display: flex;
  }

  @include media($small) {
    grid-template-columns: 50px 295px;
  }
}

.media-creator-followers {
  color: $brown-half;
  font-weight: bold;
}

.media-creator-username {
  color: $brown;
}

.media-avatar-container {
  padding: 4px;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.15);
  filter: opacity(1);
  display: inline-block;
  position: relative;

  .profile-type-icon {
    @include profile-type-badge(18px, -3px, -3px);
  }
}

.story-avatar-container {
  border-radius: 100px;
  padding: 6px;
  margin-top: 1.5em;
  .media-avatar {
    max-width: 100px;
    border-radius: 100px;
  }
}

.media-avatar {
  max-width: 42px;
  width: 42px;
  border-radius: 42px;
  display: block;
  height: 42px;
}

.media-caption {
  margin-top: 1em;
  color: $brown;

  // chrome-only: these rules add vertical truncation to caption text. Our PDF exporter uses headless chrome, so this browser-specific rule is intentional
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.media-stats {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -5px;

  @include media($small){
    width: 100%;
    overflow-x: auto;
    padding-bottom: 3px;
  }
}

.story-meta-container .media-stats {
  justify-content: center;
}

.media-stat-meta {
  margin-bottom: 10px;
  width: 105px;
  min-width: 105px;
  text-align: center;
  background: #fff;
  height: 60px;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.15);
  filter: opacity(1);
  margin: 0 5px 5px 0;
}

.media-stat-meta-label {
  font-size: 14px;
  font-weight: 700;
  color: $brown-half;
}
 .media-stat-meta-value {
  font-size: 20px;
  font-weight: 700;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}

.dropdown-caret {
  margin-left: 2px;
}

.presentation {
  background-color: $beige;

  a {
    border-bottom: none !important; // dotted links aren't displaying correctly in pdf mode
  }

  .campaign-header-section {
    margin-top: 0;
  }

  .content-summary-header {
    position: absolute;
    width: 100%;
    top: 30px;
  }
}

.fillable .how-it-works-card, .campaign-tracking-landing {
  .how-cell {
    text-align: left;
  }
  .how-cell-wrapper .how-cell {
    margin: 0 0 40px;
  }
  .how-cell-wrapper .icon {
    margin-bottom: 20px;
  }
}

.campaign-settings-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .flavor-text {
    text-align: left;

    .example-item {
      margin-right: 2px;
      word-break: break-all;

      &:last-child {
        margin-right: 0;

        &:after {
          content: '';
        }
      }
    }
  }

  a {
    max-width: 35%;

    &.campaign-activate {
      font-size: 12px;
    }
  }
}

.pause-button {
  color: $warn;

  &:hover {
    color: scale-color($warn, $lightness: -10%);
  }
}

.last-refreshed-tag {
  @include smallcaps;
  opacity: 0.5;
  margin-right: 8px;
}

.campaign-header-section {
  margin: 3em 0 2em;
  border-bottom: 1px solid $brown;
  padding-bottom: .25em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dropdown-inner {
    @include hidden-dropdown-inner;
  }
}

.header-sort {
  @include hyperlink($brown);
  @include no-highlight;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 0;
  white-space: nowrap;
}

.content-summary-header {
  margin-top: 1em;
  margin-bottom: -3.5em;
}

.campaign-data-table-fixed-height {
  height: 745px;
}

.data-table-header {
  font-size: 13px;
  font-weight: 700;

  &.right {
    text-align: right !important;
  }
}

.campaign-content-profile-table {
  background: #fff;
  box-shadow: $card-box-shadow;
  border-radius: 5px;
  &.-striped .rt-tr.-odd {
    background-color: $beige-light;
  }
  .rt-table {
    .rt-thead .rt-th {
      padding: 12px 8px 10px 8px;
      font-weight: 600;
      outline: none;
      text-align: left;
    }
    .rt-thead .row-index-header {
      border-right: none;
      font-size: 13px;
      font-weight: 700;
    }

    // removes the extra bar set by ReactTableFixedColumns
    .-headerGroups {
      display: none !important;
    }

    // Cover the gap between the header and the table
    .rt-thead.-header {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: white;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: white;
        z-index: 1;
      }
    }

    // ensures ReactTableFixedColumns empty headers don't have bleedover when scrolling
    .rt-thead .invisible-header {
      color: white;
    }

    // override ReactTableFixedColumns border colors with what ReactTable uses
    .rt-th.rthfc-th-fixed-left-last {
      border-right-color: rgba(0, 0, 0, 0.05) !important;
    }

    // removes border-right when the next cell over is the profile menu column
    .data-table-header:nth-last-child(2) {
      border-right: 0 !important;
    }

    .rt-th.rthfc-th-fixed-right-first {
      border-left: 0;
    }

    .rt-td.rthfc-td-fixed-right-first {
      border-left: 0;
    }

    .rt-thead .rt-tr {
      text-align: left;
    }
    .rt-thead.-header {
      background: white;
      box-shadow: none;
      border-top: none;
      border-bottom: 1px solid $beige;

      &.is-floating {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        z-index: 500;
      }
    }
    .rt-tr {
      align-items: center;
    }
    .rt-tbody .rt-td {
      border-right: none;
      padding: 7px 7px 7px 10px;
      font-weight: 500;

      &.no-padding {
        padding: 0;
      }
    }
    .row-index {
      color: $gray-text;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
    }
    .profile-type-icon {
      font-size: 14px;
    }
    .account-cell {
      display: flex;
      align-items: center;
    }
    .avatar-cell {
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-left: -5px;

      img {
        border-radius: 5px;
        display: block;
      }
    }
  }

  .restricted {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.rt-noData {
  display: none !important;
}

.example-notify {
  margin: 0 15px;
  font-weight: 500;
  width: 100%;

  i {
    color: $links;
  }

  @include media($medium) {
    margin-top: 10px;
    width: auto;
  }
  @include media($small){
    text-align: center;
  }
}

.view-more-media-button {
  display: block;
  margin: 40px auto;
  max-width: 200px;
}

.campaign-media-grid .view-more-media-button {
  margin-top: 0;
}

.campaign-media-grid__window {
  will-change: auto !important;
  border-bottom: 1px solid $darker-borders;
  padding-bottom: 100px; // buffer for virtualized windows, which can have weird stacking issues
}

.table-preview-container {
  position: relative;
  min-height: 150px;
}

.preview-overlay {
  position: absolute;
  top: 0;
  left: -5%;
  height: 105%;
  width: 110%;
  background-image: linear-gradient(rgba(255, 255, 255, 0), #f5f0ec 75%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.green-highlight {
  color: $primary;
}

.simple.editable-amount-field {
  width: auto;
  text-align: right;
  height: 30px;
  line-height: 30px;
  text-indent: 0px;
  padding: 0 10px;
  overflow: hidden;
}

.campaign-intro-cta {
  margin-right: 6px;
}

.new-campaign-actions {
  .campaign-intro-cta {
    @include media($small){
      margin-bottom: 12px;
    }
  }
}

.custom-tooltip {
  @include card(10px);

  &:active, &:focus {
    outline: 0;
  }

  .custom-tooltip-label {
    @include smallcaps;
    margin-bottom: 10px;
  }
}

.recharts-cartesian-axis-tick {
  @include smallcaps;
}

// recharts has an issue with responsive resizing when in print mode https://github.com/recharts/recharts/issues/1114
.campaign-charts {
  .recharts-wrapper, .recharts-surface {
    @media print {
      width: calc(100%) !important;
      min-width: calc(100%) !important;
    }
  }
}

.editable-metric {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.deleted-overlay {
  @include deleted-post-overlay;
  padding: 20px;

  .deleted-text {
    @include smallcaps;
    padding: 10px;
    background: white;
    border-radius: 3px;
  }
}

.play-video-overlay {
  @include play-video-overlay;
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  pointer-events: none;

  .overlay-action {
    padding: 0 20px;
    cursor: pointer;
    color: $brown;
    pointer-events: auto;

    &:hover {
      color: scale-color($brown, $lightness: -10%);
    }
  }
}

.campaign-charts {
  display: flex;

  .subcard {
    flex: 1;
    min-width: 25%;

    @include media($medium) {
      width: 100%;
      min-width: 100%;
    }

    .subcard-label {
      text-transform: lowercase;

      &.brands {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .brand-selector {
          > span:not(:first-child) {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.custom-cover-page {
  .logos {
    display: flex;
    align-items: center;
    justify-content: center;

    .presentation-separator {
      margin: 0 100px;
    }

    .presentation-logo {
      width: 180px;
      height: 180px;
      border-radius: 90px;
      padding: 10px;
      background: white;
      border: 1px solid $borders;
      box-shadow: $card-box-shadow;
      filter: opacity(1);
    }
  }
}

.monitor-divider {
  border-top: 1px solid $borders;
  border-bottom: none;
}

.monitor {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 10px 0;
  cursor: pointer;

  h2 {
    transition: color 0.25s ease-out;
  }

  &:hover h2 {
    color: $primary;
  }

  .monitor-icon-wrapper {
    padding-right: 20px;
  }

  .monitor-select-wrapper {
    padding-left: 20px;
  }

  .monitor-icon {
    width: 60px;
    height: 60px;
    display: block;
  }

  .monitor-icon-caret {
    width: 20px;
    height: 20px;
    display: block;
  }

  .monitor-content {
    width: 100%;
    text-align: left;
  }
}

.help-toggle {
  font-size: 14px;
  display: inline-block;
}

.help-section {
  p {
    font-size: 14px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.presentation-page-logo {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;

  span {
    @include logo-typography(18px);
    color: $brown;
    margin-left: 5px;
  }

  .campaign-presentation-logo-image {
    max-width: 140px;
  }
}

.campaign-label {
  opacity: 1;
  cursor: pointer;
  padding: 10px;
  width: 100%;

  &.active {
    border: 2px solid transparent;
  }

  &.inactive {
    background-color: transparent;
    border: 2px solid $brown-lighter;
    opacity: 0.5;
  }

  &:hover:not(.edit-mode) {
    background-color: transparent;
    border: 2px solid $brown-lighter;
    opacity: 1;
  }

  .media-type-icon svg {
    margin-left: 2px;
  }

  &:focus-visible {
    outline: $primary;
    border: 1px solid $primary;
  }
}

.label-name-wrapper {
  max-width: 400px;
  display: block;
  @include truncate();

  &.flex {
    display: flex;
  }
}

.remove-label {
  margin-left: 10px;
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.primary-nav-item {
  display: inline-block;
  font-weight: 500;

  a {
    color: $brown;
  }
  a:hover {
    color: $black;
  }
  a.active {
    border-bottom: 4px solid $primary;
    border-color: $primary;
  }
}

.shortlink-domain-addon {
  font-weight: 700;
  text-align: center;
  border: 1px solid $gray;
  display: inline-block;
  line-height: 40px;
  padding: 0 15px;
  border-radius: 3px 0 0 3px;

  + input {
    margin-left: -1px;
    border-radius: 0 3px 3px 0;
  }
}

.media-summary-link-cta {
  opacity: 0.7;
  font-weight: 500;
  font-size: 14px;
  max-width: 330px;

  .link {
    color: $brown-half;
  }
}

.engagement-stat-item {
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 6px;
  }

  .svg-inline--fa {
    margin-right: 3px;

    &::before {
      font-size: 10px;
    }
  }
}

.campaign-media-sub-nav {
  @include media($medium) {
    display: flex;
    flex-direction: column;
  }
}

.campaign-sub-nav-item {
  @include hyperlink($brown);
  font-weight: 600;
  opacity: 0.7;

  &.active {
    opacity: 1;
    font-weight: 700;
  }

  &:hover {
    opacity: 1;

    &::after {
      opacity: 0.7;
    }
  }

  &::after {
    content: "|";
    padding: 10px;
    font-size: 24px;
    vertical-align: middle;
    font-weight: 300;

    @include media($medium) {
      content: "";
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &:last-child::after {
    content: '';
    padding: 0;
  }
}

.tracking-media-with-context {
  width: 300px;
  margin-bottom: 20px;
  position: relative;

  .tracking-media-subcard {
    @include card(0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    text-align: left;
    padding: 20px;
    margin: 5px;
    background: white;
    width: 100%;
    max-width: 100%;
    margin-top: -6px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .non-tracked-actions {
    height: calc(100% - 30px); // 2x 'top' value
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    .non-tracked-approve {
      z-index: $tooltipsZ;
    }
  }

}

.label-count {
  background-color: $brown;
  color: white;
  padding: 1px 5px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 700;
  margin-left: 3px;
}

.hide-bar {
  @include card();
  z-index: $floatingNav;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  padding: 15px;
  max-width: 960px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media($medium) {
    justify-content: flex-start;
    left: 80px;
  }

  @include media($small) {
    justify-content: flex-start;
    left: 0;
    bottom: 50px;
  }

  .button-pill {
    @include button-pill($brown, 15px, 300px)
  }
}

.influencers-actions {
  .action {
    @include hyperlink($brown);
    margin-right: 20px;
    opacity: 0.6;
    cursor: not-allowed;
    font-weight: 500;
    white-space: nowrap;

    &.active {
      opacity: 1;
      cursor: pointer;
    }
  }

  .influencers-action-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    @include media($medium) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .input-cta {
      margin-bottom: 0;
      width: 400px;

      @include media($medium) {
        width: 100%;
      }
    }

    .input-group {
      @include media($medium) {
        width: 100%;
      }
    }
  }

  .influencers-actions-filtering {
    display: flex;
    align-items: center;

    @include media($medium) {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 20px;
      width: 100%;
    }
  }
}

.stat-item-button {
  @include hyperlink($brown-half);
}

.campaign-influencers-filters {
  width: 420px;
  padding: 10px;

  @include media($medium) {
    width: 100%;
  }
}

.campaign-status-picker {
  width: 250px;
}

.campaign-influencers-shortlink-window {
  max-width: 320px;

  .flash-success, .flash-error {
    text-align: left;
    margin: 5px 0;

    a {
      font-weight: bold;
    }
  }

  hr {
    opacity: 0.2;
  }

  .window-list {
    font-size: 12px;
    color: #777;
  }

  .list-title {
    @include smallcaps;
    font-size: 12px;
    margin-top: 10px;
  }

  .window-list-item {
    display: flex;
    margin-top: 10px;
    line-height: 14px;
    position: relative;

    .shortlink-details {
      width: 60%;
      word-break: break-all;
    }

    .tooltip-items {
      position: absolute;
      top: 7px;
      right: 0;

      .tooltip-item {
        display: inline-block;
        margin-left: 5px;

        .remove-label {
          margin: 0;
          position: relative;
          top: 4px;
          opacity: 0.6;

          &.inactive {
            opacity: 0.2;
          }
        }
      }
    }
  }
}

.window-form {
  font-size: 16px;
}

.window-form-label {
  height: 42px;
  border: 1px solid $gray;
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.window-form-input {
  height: 32px;
  margin: 3px 0;
  border: 1px solid $gray;
  padding-top: 2px;

  &:disabled { // match with react-select
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    color: hsl(0, 0%, 60%);
  }
}

.window-form-input-nested {
  border: none;
  border-left: 1px solid $light-gray;
  border-radius: 0px;
  height: 100%;
  padding-top: 2px;
  margin-top: -1px;
  margin-left: 5px;
  width: 65%;
}

.window-form-button {
  height: 24px;
  line-height: 2px;
  margin-top: 5px;
}

.influencers-table {
  margin-bottom: 400px;
}

.influencer-search-container {
  position: relative;
  width: 100%;

  @include media($medium) {
    width: 100%;
    margin: 20px 0;
  }

  .avatar-cell {
    position: relative;

    &__type {
      @include profile-type-badge(18px, -4px, -4px);
    }
  }
}

.influencer-search-helper {
  position: absolute;
  top: -20px;
  right: 0;
  opacity: 0.7;
}

.media-link-cta {
  margin-right: 8px;
  max-width: 180px;
}

.profile-menu-header {
  box-shadow: -10px -2px 10px -5px #fff !important;
  filter: opacity(1);
}

.profile-menu-cell {
  padding: 18px 10px;
  cursor: pointer;
}

.campaign-media-tracked-view-icons {
  display: flex;
  align-items: center;
  margin-right: 40px;

  .custom-icon {
    font-size: 18px;
  }

  .table-list-icon {
    // one-off to account for icon width differences
    margin-left: 2px;
  }

  .icon {
    opacity: 0.4;

    &.active {
      opacity: 1;
    }
  }
}

.campaign-media-headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @include media($small) {
    flex-direction: column;
  }

  // subnav should always be middle-positioned
  .flex-child-2 {
    width: 50%;
  }

  .campaign-media-tracked-header {
    display: flex;
    justify-content: flex-end;
    margin-left: 20px;

    @include media($small) {
      margin: 0;
    }

    .action {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .campaign-media-tracked-view-icons {
    margin-right: 20px;

    @include media($small) {
      margin: 0;
    }
  }

  .campaign-media-tracked-view-icons, .campaign-media-tracked-header {
    width: calc(25% - 5px);
    display: flex;
    align-items: center;

    @include media($small) {
      width: 100%;
      justify-content: center;
    }
  }
}

.campaign-end-date-picker {
  max-width: 338px;
}

.campaign-refresh {
  margin-bottom: 10px;
  margin-top: -20px; // to account for the 30px margin-bottom on the header
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
}
