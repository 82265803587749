.compact-assignees-column {
  display: flex;
  cursor: pointer;
  margin-left: 20px;
  width: 65px;

  .client-avatar:first-child {
    z-index: 5;
  }

  .client-avatar:nth-child(2) {
    margin-left: -20px;
    z-index: 4;
  }

  .client-avatar:nth-child(3) {
    margin-left: -5px;
    z-index: 3;
    opacity: 0.7;
  }
}
