.chatbox {
  .toolbar {
    @include sticky-header;
    @include toolbar-layout;
  }

  .chats {
    padding: 16px;
    height: 60vh;
    overflow-y: auto;

    .message-row {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 16px;

      .content {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;

        .person {
          margin-right: 8px;
        }

        .msg {
          width: fit-content;
          max-width: 450px;
          padding: 8px;
          border-radius: 5px;
          background-color: $beige;
          color: $brown;
          border-bottom-right-radius: 0px;
        }

        .media-cell {
          .media-caption, .hyperlink {
            color: white;
          }
        }
      }

      .meta {
        .created_at {
          font-size: 10px;
          text-align: right;
        }

        .unsend {
          font-size: 10px;
        }
      }

      &.received {
        align-items: flex-start;

        .msg {
          background-color: $gray;
          color: white;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 5px;
        }

        .meta {
          margin-left: 40px;
        }
      }

      &.failed {
        .content .msg {
          color: red;
        }

        .meta .created_at {
          color: red;
        }
      }

      &.delivered {
        .deliver-check {
          color: $primary;
        }
      }
    }
  }

  .input-box {
    display: flex;
    align-items: center;
    padding: 8px;
    border-top: 1px solid $b-block-border;
    border-bottom: 1px solid $b-block-border;

    .input-field {
      position: relative;
      flex-basis: 0;
      flex-grow: 1;
      padding: 8px;
      font-size: 14px;
      margin-right: 8px;
      cursor: text;
      outline: 1px solid $borders;
      border-radius: 3px;
      color: $brown;

      &:focus-visible {
        outline: 2px solid $borders;
      }
    }

    &.empty .input-field:before {
      position: absolute;
      content: 'Message...';
      opacity: 0.6;
    }
  }

  .assignees {
    padding: 16px;
    border-bottom: 1px solid rgba(91, 81, 71, 0.1);
    background-color: $beige;
  }
}
