.subcard-container {
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  margin: -5px;
  margin-bottom: 15px;

  @include media($small){
    overflow-x: scroll;
    padding-bottom: 3px; //fixes dropshadow issue due to overscroll
  }
}

.subcard {
  @include card(0px);
  flex-grow: 1;
  text-align: left;
  padding: 20px;
  margin: 5px;
  background: white;
  min-width: 180px;

  .subcard-label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: $brown-half;
  }

  &:last-of-type {
    border-right: none;
  }

  &.yellow {
    background-color: #FFF9EF;
    color: #ECA01D;
  }

  &.no-margin {
    margin: 0;
  }
}

.auto-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

// if subcard has filter: opacity(1), then modals opened within cards don't work properly
// but presentation needs filter: opacity(1) to remove box-shadows
.presentation {
  .subcard {
    filter: opacity(1);
  }
}
