table {
  width: 100%;
  border-collapse: collapse;

  &.with-borders {
    border: 1px solid $borders;

    th, td {
      border-bottom: 1px solid $borders;
    }
  }

  &.without-borders {
    border: 0;

    th, td, tbody:last-child td {
      border: 0;
    }
  }

  th {
    @include smallcaps;
    text-align: left;
    background: #f6f6f6;
  }

  td {
    background: white;
  }

  td, th {
    padding: 10px;
    vertical-align: middle;
    white-space: nowrap;

    &.auto-min-width {
      width: 0px;
    }
  }

  tr {
    &.hyperlink {
      cursor: pointer;

      &:hover {
        background: #f6f6f6;
      }
    }
  }

  .sortable-header-using-border {
    cursor: pointer;

    &.sorted-asc {
      border-top: 2px solid $primary;
    }

    &.sorted-desc {
      border-bottom: 2px solid $primary;
    }
  }
}

.scroll-table {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  max-width: 100%;

  .sticky-right-column {
    position: sticky;
    right: 0;
    width: 25px;
    background-clip: padding-box;
  }
}

.ReactTable {
  .rthfc-td-fixed-left {
    align-items: center;
    align-self: stretch;
    display: flex;
  }
}

.select-table-row {
  padding: 20px 0;
}

.virtualized-table {
  .rt-tr.-stable-even {
    background: rgba(0,0,0,0.03);

    .rt-td {
      background-color: #f7f7f7;
    }
  }
}
