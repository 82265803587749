.modal-backdrop {
  z-index: $modalZ;
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);

  &.active-transparent {
    visibility: visible;
    opacity: 0;
  }
}

.modal-backdrop .modal-dialog {
  margin: 100px auto;
  width: 100%;
  position: relative;
  max-width: 420px;

  @media only screen and (min-width: $wide-width-min) {
    max-width: 720px;
  }
}

.modal-backdrop .modal-content {
  border-radius: 6px;
  background-color: #fff;
  overflow-y: auto;
  max-height: calc(100vh - 210px);
  padding: 25px 20px;
  color: $brown-lighter;
  white-space: initial;
  font-size: 16px;
  font-weight: normal;
  text-transform: none;
  cursor: initial;

  .field {
    color: $brown-lighter;
  }

  @include media($small) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.modal-close-icon, .modal-expand-icon {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 10px;

  @include media($small) {
    right: 18px;
    top: 8px;
  }
}

// custom modals

.seed-navigation {
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;

  a {
    color: $brown-lighter;
    padding: 5px 10px;

    &.active {
      border-bottom: 2px solid $brown-lighter;
    }
  }
}

table.added-names {
  margin-top: 20px;
  margin-bottom: 20px;

  .name {
    @include truncate($brown-lighter);
    width: 160px;

    @include media($small) {
      width: 120px;
    }
  }

  .avatar-cell {
    width: 48px;
    padding: 0;

    .guest-badge-container {
      top: 0;
    }
  }
}

.names-container {
  display: block;
  max-height: calc(100vh - 400px);
  overflow-y: scroll;
  height: 100%;
  position: relative;

  &.format-for-table {
    display: table-row-group;
  }
}

table.campaign-setup-profiles-list-window {
  margin-bottom: 0;
}

.seed-modal-actions {
  position: relative;

  .submit {
    font-weight: 700;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.snip-hide {
  text-align: right;
  width: 100%;
}

@include media($small){
  .snip-post-count {
    display: none;
  }
}

.pending, .table-scroll-container {
  position: relative;

  .scroll-gradient {
    position: sticky;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 80px; /* Height of the gradient effect */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none; /* Prevents the pseudo-element from interfering with scrolling */
  }
}

.follower-form {
  width: calc(50% - 5px);
  margin-right: 10px;
  display: block;
  float: left;
  margin-bottom: 20px;

  &:last-of-type {
    margin-right: 0;
  }
}

.large-modal {
  .modal-dialog {
    max-width: 630px;

    @media only screen and (min-width: $wide-width-min) {
      max-width: 720px;
    }
  }

  h3 {
    text-align: center;
  }
  .modal-content {
    padding: 60px;
    width: 100%;
    margin: auto;
  }
  .form-image-container {
    text-align: center;
    margin: 0 auto;
    height: 107px;
  }
  .round-image {
    width: 100%;
    max-width: 100px;
    border-radius: 100px;
  }
}

.screen-modal-overlay {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1000%;
  position: fixed;
  display: block;
  z-index: $modalZ;
}

.screen-modal {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 66%;
  height: 100%;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  z-index: $popoutWindowZ;

  .screen-modal-wrapper {
    overflow: auto;
    height: 100%;
  }

  .modal-content {
    padding: 25px 20px;
    width: 480px;
    margin: auto;

    @include media($medium) {
      width: 100%;
    }

    &.max-width {
      width: 100%;
      padding: 0;
    }
  }

  .close-icon-text-buffer {
    @include media($small) {
      margin-top: 50px;
    }
  }

  @include media($medium) {
    width: calc(100vw - 80px);
  }

  @include media($small) {
    width: 100%;
  }
}

$modal-header-height: 75px;
$modal-aside-width: 300px;
.modal-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  &.modal-aside-right {
    flex-direction: row-reverse;

    .modal-aside-container {
      border-left: 1px solid $darker-borders;
      border-right: none;
    }
  }

  @include media($medium) {
    flex-direction: column;

    .modal-aside-container {
      border-left: none;
      border-right: none;
    }

    &.modal-aside-right {
      flex-direction: column;

      .modal-aside-container {
        border-left: none;
        border-right: none;
      }
    }
  }

  .modal-aside-container {
    display: flex;
    flex-direction: column;
    width: $modal-aside-width;
    background: $beige;
    border-left: none;
    border-right: 1px solid $darker-borders;
    border-bottom: none;

    @include media($medium) {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $brown-half;
      align-items: center;
      justify-content: center;
    }

    .primary-nav-item {
      padding: 6px 0;
    }

    .modal-aside-header {
      @include sticky-header;
      border-bottom: 1px solid $darker-borders;
      height: calc($modal-header-height - 1px); // account for border-bottom
      background: $beige;

      padding: 15px 20px;
      width: 100%;
      display: flex;
      align-items: center;

      @include media($medium) {
        justify-content: center;
      }
    }

    .modal-aside-content {
      width: 100%;
      padding-bottom: 100px; // offer viewport slack when scrolled to the bottom

      @include media($medium) {
        padding-bottom: 0;
      }

      .modal-aside-nav {
        display: flex;
        flex-direction: column;

        @include media($medium) {
          text-align: right;
        }
      }
    }
  }

  .modal-body-container {
    width: calc(66vw - $modal-aside-width);
    display: flex;
    flex-direction: column;

    &:only-child {
      width: 66vw;
    }

    @include media($medium) {
      width: 100%;

      &:only-child {
        width: 100%;
      }
    }

    .modal-body-header {
      @include sticky-header;
      border-bottom: 1px solid $darker-borders;
      height: calc($modal-header-height - 1px); // account for border-bottom

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      flex-shrink: 0;
      padding: 15px 20px;
      background: $brown;
      color: white;
      font-weight: 600;
      width: 100%;

      .action-container {
        width: 20%;
      }
    }

    .modal-body-content {
      background: $beige;
      min-height: calc(100vh - $modal-header-height);
      flex-grow: 1;

      &.with-white-bg {
        background: white;
      }

      &:only-child {
        min-height: 100vh;
      }

      @include media($medium) {
        width: 100%;
      }

      .modal-body-breadcrumbs {
        @include sticky-header;
        top: calc($modal-header-height - 1px);
        background: $beige;

        border-left: none;
        border-top: none;
        border-right: none;
        border-bottom: 1px solid $darker-borders;
        padding-top: 12px;
        padding-bottom: 12px;

        display: flex;
        justify-content: center;
        gap: 8px;
      }
    }
  }

  &.modal-separate-scroll {
    .modal-aside-content, .modal-body-content {
      height: calc(100vh - $modal-header-height);
      overflow-y: auto;
    }

    .modal-body-breadcrumbs {
      top: 0 !important;
    }

    @include media($medium) {
      .modal-aside-content {
        height: calc(50vh - $modal-header-height);
      }

      .modal-body-content {
        height: 100%;
      }
    }
  }
}

.screen-modal-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: calc(10px + 66vw);
  color: white;
  background-color: $brown;
  border: 0;
  font-size: 48px;
  border-radius: 50%;
  z-index: $popoutWindowZ;

  @include media($medium) {
    right: 0;
    left: -55px;
  }

  @include media($small) {
    left: 16px;
  }
}

.screen-modal-enter {
  opacity: 0;
}

.screen-modal-enter-active {
  opacity: 1;
  pointer-events: visible;
  transition: opacity 200ms;
}

.screen-modal-exit {
  opacity: 1;
}

.screen-modal-exit-active {
  opacity: 0;
  pointer-events: visible;
  transition: opacity 200ms;
}

