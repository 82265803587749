.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 400px;
  border: 1px solid $borders;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  box-shadow: $card-box-shadow;
  max-height: 480px;
  overflow-y: auto;
  z-index: $filtersZ;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  display: block;
  cursor: pointer;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0C7EAF;
  color: #fff;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $borders;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: $borders;
  }
}

.as-name {
  font-weight: 600;
}

.as-highlight {
  color: #ee0000;
  font-weight: 600;
}

.react-autosuggest__suggestion--focused .as-highlight {
  color: #120000;
}
