.email-composer {
  .toolbar {
    @include sticky-header;
    @include toolbar-layout;

    .actions {
      display: flex;
      align-items: center;
    }
  }
}

.email-editor {
  > div {
    border-bottom: 1px solid $b-block-border;
    padding: 8px 8px 8px 16px;
  }

  .block-label {
    font-size: 14px;
    font-weight: 500;
    color: $b-block-label;
    min-width: 30px;
    margin-right: 20px;
  }

  .recipients {
    .primary-to, .cc, .bcc {
      display: flex;
      align-items: center;
    }

    .cc, .bcc {
      margin-top: 8px;
    }
  }

  .senders {
    display: flex;
    align-items: center;
  }

  .subject {
    display: flex;
    align-items: center;

    .input-field {
      position: relative;
      flex-basis: 0;
      flex-grow: 1;
      padding: 8px;
      font-size: 14px;
      color: $brown;
      font-weight: 500;
      border-radius: 5px;
      outline: 1px solid $borders;
      transition: outline 0.1s ease;

      &:hover {
        outline: 2px solid $borders;
      }
      &:focus {
        outline: 2px solid $borders;
      }
    }
  }

  .content {
    padding: 8px;

    textarea {
      width: 100%;
      resize: none;
      font-weight: 500;
      color: $brown;
      border: none;
      outline: 1px solid $borders;
      transition: outline 0.1s ease;
      border-radius: 5px;

      &:hover {
        outline: 2px solid $borders;
      }

      &:focus {
        outline: 2px solid $borders;
      }
    }
  }

  .editor-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }

  .multi-email-select {
    &__multi-value {
      background-color: $beige;
      font-weight: 500;
    }
  }
}

.email-select {
  @include hyperlink-button($beige);
  background-color: $beige;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;

  &.strong {
    font-weight: 600;
  }
}

.email-select-dd {
  &-content {
    padding: 0px;
    overflow: hidden;
    margin-top: 15px;
    width: auto;

    &.hidden {
      display: none;
    }

    .email-select-option {
      font-size: 14px;
      padding: 5px 10px;
      white-space: nowrap;

      &.active, &:hover {
        background-color: $beige;
        font-weight: 500;
      }
    }
  }
}

.draft-saved {
  padding: 12px;
  font-size: 12px;
  color: $primary;
}

.show-all-emails {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 15px;
  margin-top: 3px; // factors in the fact that the emailrow above it has a padding of 12px

  &:after {
    border-top: 1px solid rgba(100,121,143,0.122);
    border-bottom: 1px solid rgba(100,121,143,0.122);
    content: "";
    height: 8px;
    width: 100%;
    display: block;
  }
}

.show-all-emails-divider {
  height: 32px;
  display: block;
  position: relative;
}

.show-all-emails-label {
  -webkit-font-smoothing: auto;
  font-size: .75rem;
  letter-spacing: normal;
  -webkit-align-items: center;
  align-items: center;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 0 1px #dadce0;
  box-shadow: inset 0 0 0 1px #dadce0;
  color: #5f6368;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 32px;
  -webkit-justify-content: center;
  justify-content: center;
  left: 12px;
  padding: 0;
  position: absolute;
  top: 0;
  width: 32px;
  line-height: 20px;
  background: white;
  font-weight: 600;
  cursor: pointer;
}