@mixin button-base($color, $font-size, $width) {
  border-width: 2px;
  border-style: solid;
  border-color: $color;
  width: $width;
  display: inline-block;
  padding: 10px 16px;
  margin-bottom: 0;
  font-size: $font-size;
  line-height: $font-size;
  background-image: none;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 5px;
  transition: background-color 0.15s ease;

  &.auto {
    width: auto;
  }

  &:hover {
    background-color: scale-color($color, $lightness: -10%);
    border-color: scale-color($color, $lightness: -10%);
    color: white;
  }

  &:focus {
    outline: none;
  }

  &:active {
    color: white;
    background-color: scale-color($color, $lightness: -20%);
    border-color: scale-color($color, $lightness: -20%);
  }

  &:focus:active {
    background-color: scale-color($color, $lightness: -20%);
    border-color: scale-color($color, $lightness: -20%);
    color: white;
  }

  &[disabled] {
    background-color: mix($color, white, 40%);
    border-color: mix($color, white, 40%);
    cursor: not-allowed;

    &:hover {
      background-color: mix($color, white, 40%);
    }
  }
}

@mixin button($color, $font-size, $width: 100%) {
  @include button-base($color, $font-size, $width);
  color: white;
  background-color: $color;

  &:active {
    color: white;
  }

  &[disabled] {
    background-color: mix($color, white, 40%);
    border-color: mix($color, white, 40%);
    cursor: not-allowed;

    &:hover {
      background-color: mix($color, white, 40%);
    }
  }
}

@mixin button-inverted($color, $font-size, $width: 100%) {
  @include button-base($color, $font-size, $width);
  color: $color;
  background-color: transparent;
  border-color: $color;

  &:hover {
    border-color: scale-color($color, $lightness: -15%);
    color: scale-color($color, $lightness: -15%);
    background-color: transparent;
  }

  &:active {
    border-color: scale-color($color, $lightness: -25%);
    color: scale-color($color, $lightness: -25%);
    background-color: scale-color($color, $lightness: 55%);
  }

  &:focus:active {
    background-color: scale-color($color, $lightness: 55%);
    border-color: scale-color($color, $lightness: -25%);
    color: scale-color($color, $lightness: -25%);
  }
}

@mixin button-pill($color, $font-size, $width: 100%) {
  @include button-base($color, $font-size, $width);
  color: white;
  background-color: $color;
}

@mixin button-light($color, $font-size, $width: 100%) {
  @include button-base($color, $font-size, $width);
  color: $color;
  background-color: white;
  border-color: white;

  &:hover {
    background-color: scale-color(white, $lightness: -15%);
    border-color: scale-color(white, $lightness: -15%);
    color: $color;
  }

  &:active {
    color: scale-color($color, $lightness: -25%);
    background-color: scale-color(white, $lightness: 55%);
    border-color: scale-color(white, $lightness: 55%);
  }

  &:focus:active {
    background-color: scale-color(white, $lightness: 55%);
    border-color: scale-color(white, $lightness: 55%);
    color: scale-color($color, $lightness: -25%);
  }
}

@mixin button-pill-light($color, $font-size, $width: 100%) {
  @include button-light($color, $font-size, $width);
}

@mixin button-inverted-pill($color, $font-size, $width: 100%) {
  @include button-inverted($color, $font-size, $width);
}

@mixin card($padding: 5px) {
  background: white;
  border-radius: 3px;
  padding: $padding;
  box-shadow: $card-box-shadow;
}

@mixin truncate($color: $blue) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}

@mixin hidden-dropdown-inner($top: -150px, $right: -20px, $width: 210px) {
  display: none;
  background: white;
  border: 1px solid $borders;
  border-radius: 6px;
  position: absolute;
  padding: 10px;
  transition: transform .3s;
  z-index: $floatingNotification;
  top: $top;
  right: $right;
  width: $width;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);

  li {
    display: block;
    padding: 10px;
    font-weight: 600;
    font-size: 16px;
  }
}


// parent needs to have position: relative
@mixin deleted-post-overlay() {
  background-color: rgba(255, 255, 255, .6);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

// parent needs to have position: relative
@mixin play-video-overlay() {
  background-color: rgba(255, 255, 255, .5);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

@mixin logo-typography($font-size: 24px) {
  font-size: $font-size;
  color: #fff;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0.5px;
}

@mixin no-highlight() {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
}

// Typographic mixin for subtle headers, headings, labels
@mixin smallcaps() {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
  font-weight: 600;
}

@mixin reset-smallcaps() {
  text-transform: none;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: normal;
}

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

@mixin hyperlink($color) {
  color: $color;
  cursor: pointer;

  &:hover {
    color: scale-color($color, $lightness: -20%);
  }

  &:active {
    color: scale-color($color, $lightness: 20%);
  }
}

@mixin hyperlink-button($background-color) {
  background-color: $background-color;
  cursor: pointer;

  &:hover {
    background-color: scale-color($background-color, $lightness: -5%);
  }

  &:active {
    background-color: scale-color($background-color, $lightness: -10%);
  }
}

@mixin no-highlight() {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@mixin profile-type-badge($size, $top, $left) {
  position: absolute;
  top: $top;
  left: $left;
  width: $size;
  height: $size;
  padding: 2px;
  border-radius: 50%;
  background: white;

  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
}

@mixin enforce-square-size($size) {
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
}

@mixin sticky-header {
  position: sticky;
  top: 0;
  z-index: $floatingNav;
}

@mixin toolbar-layout {
  padding: 16px;
  color: white;
  background-color: $brown;
  height: 73px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  width: 100%;
  gap: 10px; // adjust the gap between columns as needed

  > *:first-child {
    grid-column: 1 / 2;
    justify-self: start;
  }

  > *:nth-child(2):last-child {
    grid-column: 2 / 3;
    justify-self: center;
  }

  > *:nth-child(2) {
    justify-self: center;
  }

  > *:nth-child(3) {
    grid-column: 3 / 4;
    justify-self: end;
  }

  .action {
    width: fit-content;
  }

  .draft-saved {
    color: $primary;
    padding: 0;
  }
}
