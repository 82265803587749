.pagination {
  display: block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  font-size: 16px;
  overflow: hidden;

  span {
    position: relative;
    float: left;
    margin-left: -1px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: $links;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    font-weight: strong;
  }

  > .current, > .current:hover, > .current:hover, .current:focus {
    z-index: 2;
    color: #fff;
    cursor: default;
    font-style: initial;
    background-color: $links;
    border-color: $links;
  }

  > a:hover, > span:not(.disabled):hover {
    color: $links;
    background-color: #eee;
    border-color: #e6e6e6;
  }

  > .disabled {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #e6e6e6;
  }
}
