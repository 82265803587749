.floating-action-bar {
  position: fixed;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  transition-timing-function: ease-in;
  transition: 0.3s bottom;
  font-weight: 500;
  min-width: 300px;
  z-index: $floatingNav;

  &.visible {
    transition-timing-function: ease-out;
    bottom: 50px;
  }

  &.fit-content {
    min-width: 0px;
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: white;
    font-size: 13px;
    background-color: #272727;
    border-radius: 5px;
    border: 1px solid black;
  }

  .floating-action-bar-hide {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;

    &:hover {
      color: #fff;
    }
  }

  .actions {
    display: flex;
    align-items: stretch;
    margin-top: 10px;
    padding: 10px;
    color: white;
    background-color: #5A5147;
    border-radius: 5px;
    border: 1px solid black;

    .action {
      margin-right: 10px;
      cursor: pointer;
      background-color: #897969;
      border-radius: 5px;
      user-select: none;

      &:hover {
        background-color: scale-color(#897969, $lightness: -10%);
      }

      &:active {
        background-color: scale-color(#897969, $lightness: 10%);
      }

      &:last-child {
        margin-right: 0;
      }

      .icon-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 90px;
        height: 100%;
        padding: 10px;
        color: white;

        &:hover {
          color: white;
        }

        svg {
          margin-right: 0px;
          margin-bottom: 10px;
        }

        span {
          font-size: 13px;
          font-weight: 500;
          line-height: 15px;
        }
      }
    }
  }
}
