.profile-snip-row {
  .avatar-cell {
    position: relative;

    &__type {
      @include profile-type-badge(18px, 6px, 6px);
    }
  }
}

.seed-modal-profile-snip-row {
  .avatar-cell {
    position: relative;
    padding-left: 5px !important;

    &__type {
      @include profile-type-badge(18px, 4px, 1px);
    }
  }
}

.snip-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}