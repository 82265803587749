.profile-input {
  position: relative;
  flex-grow: 1;

  input {
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #333;
    border-radius: 3px;
    background: white;
    height: 50px;
    padding: 12px;
    border: 1px solid #d4d4dc;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }

  &__platform, &__prepend {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__platform {
    display: flex;
    align-items: center;
    height: 100%;
    border-right: 1px solid #d4d4dc;

    + input {
      padding-left: 65px;
    }
  }

  &__prepend {
    line-height: 0px;
    padding-left: 15px;

    + input {
      padding-left: 37px;
    }
  }

  &__platform + &__prepend {
    left: 50px;

    + input {
      padding-left: 90px;
    }
  }

  &.md {
    input {
      height: auto;
      padding-top: 8px;
      padding-bottom: 9px;
    }
  }
}
