.profile .profile-notes-row {
  padding: 20px;
}

.influencer-modal-aside .profile-notes-row {
  padding-bottom: 15px;
}

.rt-table {
  .profile-notes-row {
    align-items: stretch;
    min-height: 44px;

    &__truncated.placeholder {
      visibility: hidden;
    }

    &:hover .profile-notes-row__truncated.placeholder {
      visibility: visible;
    }

    @include media($medium) {
      &__truncated.placeholder {
        visibility: visible;
      }
    }
  }
}

.profile-notes-row {
  .notes-header {
    @include smallcaps;
    color: $brown-lighter;
    height: 18px;
  }

  p.notes-body {
    font-size: 14px;
  }

  &__clickable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
}

.profile-notes-row__truncated {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: normal;
}

.profile-notes-section {
  padding: 20px;
  display: flex;
  gap: 20px;

  p.notes-body {
    font-size: 16px;
    color: $brown;
    margin-bottom: 5px;

    &:hover {
      color: scale-color($brown-lighter, $lightness: 10%);
    }
  }
}

.profile-notes-block__truncated {
  position: relative;
  max-height: 80px;
  overflow:hidden;
  white-space:pre-wrap;

  &:before {
    content:'';
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    background:linear-gradient(transparent 40px, white);
  }
}

.profile-notes-input {
  display: block;
  width: 100%;
  min-width: 300px;

  &:focus-visible {
    outline: 2px solid $brown;
  }
}

.profile-notes--action-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .primary-nav-item {
    color: $brown;
  }

  .actions i:not(:last-child) {
    margin-right: 5px;
  }
}

.popover-content.profile-notes-popover {
  padding: 2px;
}
