// colors
$primary: #4DAF7C;
$primary-darker: mix($primary, black, 90%);
$primary-darkest: mix($primary, black, 80%);
$primary-lightest: mix($primary, white, 20%);
$contrast: #F79B20;
$orange: #F79B20;

$primary-fill: #54ae7f;
$primary-fill-half: rgba($primary-fill, 0.5);

$pink: #FF5166;
$red: #FF6242;

$light-bg: #F5F0EC;
$brown: #5B5147;
$brown-half: rgba($brown, 0.5);
$brown-lighter: #8A7969;
$brown-link: rgba($brown, 0.75);
$beige: #f5f0ec;
$beige-dark: #e8d6cc;
$beige-light: rgba($beige, 0.25); // #fdfbfa

$gray: #9A9BA5;
$light-gray: #d4d4dc;
$gray-text: #A09B97;
$blue: #1C233F;
$links: #4990E2;
$links-darker: mix($links, black, 90%);
$links-darkest: mix($links, black, 80%);
$black: #333;
$warn: #F23A05;
$borders: #ECECEC;
$darker-borders: #D8D8D8;
$badge-gray: #C4C4C4;
$b-block-border: rgba(91, 81, 71, 0.1);
$b-block-label: #979797;

$card-box-shadow: 0px 1px 1px #091e4240, 0px 0px 1px #091e424f;

// fonts

$primary-font: 'ProximaNova';

// z-indices
$tooltipsZ: 10;
$filtersZ: 100;
$wonkyStackingOrderZ: 9;
$floatingNotification: 500;
$floatingNav: 500;
$modalZ: 1000;
$popoutWindowZ: 2000; // popout windows use modal backdrops so they need to be on a higher z-index
