.pdf-single-pager-wrapper {
  position: relative;
  page-break-before: always;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.single-pager {
  page-break-inside: avoid;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.full-height {
    height: 100vh;
  }
}
