$pricing-box-width: 260px;

#campaign-pricing {
  margin-top: 5rem;
}

.campaign-pricing {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .pricing-box {
    flex-basis: $pricing-box-width;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 0;
    margin-top: 10px;

    @include media($medium) {
      flex-basis: auto;
    }
  }
}

.campaign-pricing-containers {
  display: flex;

  .pricing-container {
    margin: 0px auto;
    flex-basis: $pricing-box-width;
    border: 1px solid $borders;
    padding: 15px;
    padding-bottom: 0; // line items should provide buffer at bottom of containers
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    @include media($medium) {
      border-radius: 6px;
      margin-bottom: 20px;
      flex-basis: auto;
    }

    .subtitle {
      height: 70px;
    }

    .frequency {
      font-size: .7em;
    }

    .plan-label {
      @include smallcaps;
      text-align: center;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  @include media($medium) {
    flex-direction: column;
  }
}

.campaign-pricing-boxes {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media($medium) {
    display: none;
  }
}

.pricing-box {
  background: white;
  text-align: center;
  margin: 0px -15px;

  .vertical-list {
    text-align: left;

    .list-item {
      font-weight: 500;
      font-size: 14px;
      padding: 15px;
      border-bottom: 1px solid $borders;

      @include media($medium) {
        text-align: center;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.campaign-pricing-figure {
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  color: $brown;
  padding: 20px 0;
  text-align: center;

  &.enterprise {
    font-size: 44px;
    line-height: 60px;
  }
}

.signup-helper.pricing {
  .figure {
    color: $brown;
    font-weight: normal;

    .price {
      font-weight: normal;
      font-size: 32px;
    }
  }

  .pricing-box {
    display: none;
    background: transparent;
    width: 100%;
    margin: 20px 0;

    &.active {
      display: block;
    }
  }
}
