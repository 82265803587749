.media-hero-img-wrapper {
  background: #fff;
  border-radius: 5px;
  box-shadow: $card-box-shadow;
  filter: opacity(1);
  position: relative;
}

.media-hero-img {
  padding: 6px;
  width: 100%;
  align-self: center;
  vertical-align: top;
  position: relative;

  &.thumbnail {
    background: #fff;
    border-radius: 5px;
    box-shadow: $card-box-shadow;
    filter: opacity(1);
    width: 100px;
    min-height: 100px;
    min-width: 100px;
    display: block;
    margin: 0 auto 20px auto;
  }

  // used in media table
  &.mini-thumbnail {
    width: 50px;
    min-height: 50px;
    min-width: 50px;
  }

  &.deleted {
    @include deleted-post-overlay;
  }

  @include media($small) {
    max-width: 100%;
  }
}

// MEDIA GRID ITEM
// these are always squares

.media-grid-wrapper {
  width: 300px;
  max-width: 300px;
  flex-basis: 300px;

  &.larger {
    width: 400px;
    max-width: 400px;
    flex-basis: 400px;
  }

  &.smaller { // used in Notifications table
    width: 70px;
    max-width: 70px;
    flex-basis: 70px;

    .media-hero-img-wrapper {
      min-width: 70px;
      min-height: 70px;
      width: 100%;
    }

    .media-hero-img {
      display: block;
      object-fit: contain;
      width: 70px;
      height: 70px;
      padding: 4px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .media-hero-container {
    width: 100%;
  }

  .media-hero-img-wrapper {
    min-width: 300px;
    min-height: 300px;
    width: 100%;

    @include media($small){
      min-width: 320px;
      min-height: auto;
    }
  }

  .media-hero-img {
    display: block;
    object-fit: contain;
    width: 300px;
    height: 300px;

    @include media($small) {
      background: none;
      box-shadow: none;
      padding: 0;
      width: 100%;
      height: 170px;
      object-fit: cover;
      border-radius: 0;
      display: inherit;
    }

    &.thumbnail {
      @include media($small){
        width: 100px;
        margin: 0;
      }
    }
  }

  &.compact-view {
    width: 225px;
    max-width: 225px;
    flex-basis: 225px;

    .media-hero-img-wrapper {
      min-width: 225px;
      min-height: 225px;
    }

    .media-grid-item-visual .media-hero-img {
      width: 225px;
      height: 225px;
      margin-bottom: 18px;
    }
  }

  @include media($small) {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    background: #fff;
    margin-bottom: 15px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: $card-box-shadow;
    position: relative;
    min-height: 236px;

    &.invisible:nth-child(2n+1) {
      display: none; // hide js-generated empty cells (for flex positioning) when in mobile view to avoid extra row)
    }
  }
}

// MEDIA SETTINGS MODAL
// these are basically .thumbnails, but we still need to override because technically these can belong in media grid wrapper, etc.

.media-grid-item-settings-container {
  .media-hero-img {
    width: 100px;
    height: 100px;
  }
}

// MEDIA SUMMARY
// these can be long and thin, short and fat, etc.
// we generally want to cap the width at 360px but the height can vary

.tracking-media-card-container {
  .media-hero-img-wrapper {
    width: 360px;
    min-height: 200px;
  }

  .media-hero-img {
    max-width: 360px;

    &.thumbnail {
      @include media($medium) {
        height: auto;
      }
    }
  }
}

// PROFILE DETAILS MODAL
// these are like media summary images but smaller

.activity-feed-item-media {
  display: flex;

  .media-hero-container {
    margin-right: 20px;

    .media-hero-img-wrapper {
      width: 300px;
      min-height: 174px;

      .media-hero-img {
        width: 300px;
      }
    }

    @include media($small) {
      margin-right: 0;
    }
  }
}

// NON-TRACKED + HIDDEN MEDIA
.non-tracked-media, .campaign-media-hidden {
  .media-hero-img-wrapper {
    width: 300px;
    min-height: 174px;
  }
}

// IMPORT MEDIA MODAL ADDED LIST

.posts-list {
  .media-hero-img {
    object-fit: contain;
    margin-bottom: 0;
  }
}
