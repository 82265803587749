.tracking-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 15px;
  box-shadow: $card-box-shadow;
  background: white;
  border-radius: 6px;
  margin-bottom: 10px;
  width: 100%;

  .avatar {
    margin-right: 10px;
    min-width: 36px;
    width: 36px;
    height: 36px;
    display: block;
  }

  .tracking-details {
    p {
      font-size: 14px;
    }
  }

  &.stack {
    box-shadow:
    /* The top layer shadow */
    0 1px 1px rgba(0,0,0,0.15),
    /* The second layer */
    0 10px 0 -5px #eee,
    /* The second layer shadow */
    0 10px 1px -4px rgba(0,0,0,0.15),
     /* The third layer */
    0 20px 0 -10px #eee,
    /* The third layer shadow */
    0 20px 1px -9px rgba(0,0,0,0.15);
  }
}

.cell-with-media {
  display: block;
}

.tracking-cell-actions {
  position: absolute;
  top: 10px;
  right: 10px;

  a {
    color: $gray;
    font-size: 16px;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.tracking-thumbs {
  display: flex;
  margin-left: auto;
}

.tracking-thumb {
  height: 36px;
  width: 36px;
  border-radius: 3px;
  margin-right: 5px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.deleted {
    &:after {
      @include deleted-post-overlay;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.tracking-action {
  .deleted {
    color: #E03D35;
  }

  .posted {
    color: $primary;
  }
}

.tracking-details {
  a.username {
    opacity: 1;
    color: $brown;

    &:hover {
      color: $brown;
    }
  }
}

.tracking-avatar {
  position: relative;
}
.tracking-cell-footer {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  padding-left: 46px;
  .activity-time {
    margin-right: 8px;
  }
}
.tracking-details .tracking-cell-footer {
  padding-left: 0;
}
.mini-ig-icon {
  margin-right: 2px;
  margin-bottom: -1px;
}

.remove-cell {
  position: absolute;
  top: 5px;
  right: 5px;
  color: $brown;

  &:hover {
    color: $brown-lighter;
  }
}
