$switch-height: 22px;

.switch { height: $switch-height; }

.toggle-switch {
  display: inline-block;
  margin: 0;

  &__input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;

    &:checked + .toggle-switch__slider {
       background-color: $primary;

      &::before {
        transform: translate(16px, -50%);
      }
    }

    &:disabled + .toggle-switch__slider {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__slider {
    box-sizing: border-box;
    width: 36px;
    height: $switch-height;
    border-radius: 30px;
    background-color: rgba(32, 37, 50, 0.14);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    display: inline-block;
    position: relative;
    cursor: pointer;

    &::before {
      transition: transform 75ms ease-in-out;
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      background-color: #fff;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25), 0 2px 8px 0 rgba(32, 37, 50, 0.16);
      position: absolute;
      border-radius: 100%;
      top: 11px;
      transform: translate(2px, -50%);
    }
  }
}

.switch-collection {
  .switch-group {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.switch-group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.parent {
    border-bottom: 1px solid $borders;
    padding-bottom: 10px;
  }
}
