.list-window {
  background: white;
  border: 1px solid $gray;
  border-radius: 6px;
  position: absolute;
  z-index: $popoutWindowZ;
  width: 360px;
  right: 0;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  overflow: auto;
  max-height: 400px;

  table {
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
  }

  @include media($small) {
    width: 250px;
    right: auto;
  }
}

.list-window-sticky {
  position: sticky;
  top: 0;
  background: white;
  z-index: $popoutWindowZ;
  padding: 15px;
}

.list-window-body {
  padding: 0 15px 15px;
}

.create-list {
  @include truncate($primary);
  max-width: 270px;
  text-align: left;
  line-height: 32px;
}

.list-name {
  padding: 0 5px;
  max-width: 220px;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px;
  text-align: left;

  @include media($medium) {
    width: 160px;
  }
}

.checkbox-toggle {
  padding: 0;
  width: 24px;
  line-height: 30px;

  &.wider {
    width: 36px;
    text-align: center;
  }
}

.list-count {
  padding: 0;
  text-align: center;
  width: 24px;
  line-height: 30px;
}
