// the :before rules help define a placeholder for 404'ed avatars
// the subsequent hardcodes below are where we use avatars; we have to
// override the dimensions there for each usage

.campaign-avatar {
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100%); // not sure why this works
    background-color: $brown;
    background-image: -webkit-linear-gradient(135deg, $brown-lighter 50%, $brown 50%);
    width: 48px;
    height: 48px;
    border-radius: 48px;
  }

  &.small {
    &:before {
      width: 30px;
      height: 30px;
      border-radius: 30px;
    }
  }
}

// needs to define width, height, and border-radius (hardcode) for each instance. see below:
.rt-table .avatar-cell .campaign-avatar { // data table
  &:before {
    width: 30px;
    height: 30px;
    max-width: 30px;
    border-radius: 5px;
  }
}

.influencer-modal .influencer-modal-aside .campaign-avatar { // influencer modal
  &:before {
    width: 42px;
    height: 42px;
    max-width: 42px;
    border-radius: 42px;
  }
}

.media-grid-item-avatar-container .campaign-avatar, .media-grid-mobile-avatar-container .campaign-avatar { // media grid
  &:before {
    width: 22px;
    height: 22px;
    max-width: 22px;
    border-radius: 22px;
  }
}

.media-meta-container .campaign-avatar { // media summary
  &:before {
    width: 42px;
    height: 42px;
    border-radius: 42px;
    left: 4px;
    top: 4px;
  }
}

.story-avatar-container .campaign-avatar {
  &:before {
    left: 6px;
    top: 5px;
  }
}

.profile .user-basics .user-avatar { // profile card
  position: relative;

  .campaign-avatar {
    &:before {
      width: 42px;
      height: 42px;
      border-radius: 42px;
    }
  }
}

.tracking-avatar .campaign-avatar { // tracking cells
  &:before {
    width: 42px;
    height: 42px;
    border-radius: 42px;
  }
}
