// https://zalog.ro/placeholder-loading/
$ph-bg:                   #fff !default;
$ph-color:                #ced4da !default;
$ph-border-radius:        2px !default;
$ph-gutter:               30px !default;
$ph-spacer:               15px !default;
$ph-avatar-border-radius: 50% !default;
$ph-animation-duration:   .8s !default;

.ph-item {
  direction: ltr;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: $ph-bg;

  &.no-bg {
    background-color: transparent;
  }

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation $ph-animation-duration linear infinite;
    background: linear-gradient(to right, rgba($ph-bg, 0) 46%, rgba($ph-bg, .35) 50%, rgba($ph-bg, 0) 54%) 50% 50%;
  }

  > .ph {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
  }


  &.media-grid-item {
    padding: 6px;
  }
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ($ph-spacer / 2);

  .ph {
    height: 14px;
    margin-bottom: ($ph-spacer / 2);
    background-color: $ph-color;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .big,
  &.big div {
    height: 36px;
    margin-bottom: $ph-spacer;
  }

  .bigger,
  &.bigger div {
    height: 70px;
    margin-bottom: $ph-spacer;
  }
  .empty {
    background-color: rgba($ph-bg, 0);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.ph-col-1 {
  flex: 0 0 percentage(1 / 12);
}
.ph-col-2 {
  flex: 0 0 percentage(2 / 12);
}
.ph-col-4 {
  flex: 0 0 percentage(4 / 12);
}
.ph-col-6 {
  flex: 0 0 percentage(6 / 12);
}
.ph-col-7 {
  flex: 0 0 percentage(7 / 12);
}
.ph-col-8 {
  flex: 0 0 percentage(8 / 12);
}
.ph-col-10 {
  flex: 0 0 percentage(10 / 12);
}
.ph-col-12 {
  flex: 0 0 percentage(12 / 12);
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
  border-radius: $ph-avatar-border-radius;
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: $ph-color;

  &.chart {
    height: 400px;
  }

  &.faux-media-grid-item {
    width: 288px;
    height: 288px;
  }
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}
