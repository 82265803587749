.lookup-header-mobile-nav {
  cursor: pointer;
  font-weight: 600;
}

.more-nav-label {
  margin: 0;
}

.dropdown-open:checked ~ .dropdown-inner {
  transform: translateY(190px);
  display: none;

  @include media($desktop-min) {
    display: block;
  }
}

.nav-items-monitor {
  @include media($desktop-min){
    display: none;
  }
}

.mobile-nav-monitor {
  @include media($desktop-min){
    display: block;
  }
}

.inverted .mobile-nav-monitor {
  color: white;
}

.is-fixed .mobile-nav-monitor {
  color: $brown;
}

.mobile-logged-out-nav {
  position: relative;
  width: 0%;

  @include media($medium) {
    width: auto;
  }

  label {
    margin-bottom: 0;
  }

  .dropdown-inner {
    @include hidden-dropdown-inner;
  }
}
