.simple {
  border: 1px solid $gray;
  border-radius: 3px;
  width: 100%;
  line-height: 40px;
  text-indent: 15px;

  &::placeholder {
    color: $gray;
  }

  ~.input-addon {
    top: 0;
    left: 10px;
    line-height: 40px;
  }

  &:focus {
    outline: none;
    border-color: $primary;
  }

  &.simple-inline {
    width: 70%;
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.error {
    border-color: $warn;
  }

  &:disabled {
    background-color: #ddd;
  }

  &.editable-field-disabled {
    background-color: #ddd;
    display: inline-block;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.input-text-attachment {
    border: 1px solid $gray;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #f0f0f0;
    color: #a0a0a0;
    height: 42px;
  }
}

textarea.simple {
  text-indent: 0;
  line-height: 24px;
}

.button-simple-inline {
  height: 42px;
  width: calc(30% - 5px);
  margin-left: 5px;
  line-height: 20px;
}

.input-group {
  margin-bottom: 20px;

  &:last-of-type, &.no-margin {
    margin-bottom: 0;
  }

  &.with-addon {
    position: relative;

    input, select {
      padding-left: 32px;
      text-indent: 0;
    }

    .input-cta:not([type="date"]) {
      padding-left: 50px;
      text-indent: 0;
    }
  }

  &.embedded-button {
    position: relative;

    input[type="submit"], .embedded {
      position: absolute;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: 16px;
      top: 0;
      right: 0;
      width: auto;
      height: 50px;
      display: flex;
      align-items: center;

      @include media($medium) {
        display: block;
        margin: 0 auto;
        position: relative;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }
}

.input-addon {
  position: absolute;
  top: 14px;
  left: 25px;

  &.v-center {
    display: grid;
    place-items: center;
    height: 100%;
  }
}

label {
  margin-bottom: 5px;
  display: block;
}

.strong-label {
  margin-bottom: 10px;
  @include smallcaps;
}

.small-label {
  margin-bottom: 2px;
  font-weight: bold;
}

.sub-label {
  margin-bottom: 5px;
  opacity: 0.8;
}

textarea {
  padding: 10px;
  text-indent: 0;

  &.input-cta {
    height: inherit;
  }
}

.field {
  border-radius: 6px;
  font-size: 16px;
  border: 1px solid $light-gray;
  color: $black;
  background: white;
  padding: 12px;
  width: 100%;

  &.inline {
    display: inline-block;
  }

  &:focus {
    outline: none;
    border-color: $primary;
  }

  &.error {
    border-color: $warn;
  }

  &.editable-text {
    font-size: 14px;
    line-height: 14px;
    padding: 6px;
    width: auto;
  }
}

select.field {
  height: 42px;
}

.input-cta {
  width: 100%;
  border-radius: 3px;
  background: white;
  height: 50px;
  padding: 12px;
  font-size: 18px;
  border: 1px solid $light-gray;
  color: $black;
  margin-bottom: 15px;

  &:last-of-type, &.no-margin {
    margin-bottom: 0;
  }

  &:focus {
    outline: none;
    border-color: $primary;
  }

  &.inline-width {
    width: auto;
  }
}

.credit-card-form {
  text-align: left;
}

.checkbox-with-label {
  label {
    display: inline;
    cursor: pointer;
  }
}

input {
  &:disabled {
    cursor: not-allowed;
  }
}

.search-field-container {
  position: relative;

  input {
    border-radius: 3px;
    background: white;
    padding: 12px 4px;
    padding-left: 32px;
    font-size: 16px;
    border: 1px solid $light-gray;
    color: $black;
    text-indent: 0;
    min-width: 250px;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }

  .input-addon {
    position: absolute;
    top: 13px;
    left: 10px;
    height: 16px;
  }

  .search-helper {
    position: absolute;
    top: -20px;
    right: 0;
    opacity: 0.7;
    font-size: 14px;
  }
}
