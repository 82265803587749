div.tooltip-message {
  background: #222;
  border-radius: 3px;
  color: #fff;
  z-index: 999;
  max-width: 240px;
  text-align: center;
  white-space: normal;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
     -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
       -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);

  @include reset-smallcaps;
  font-size: 13px;
  font-weight: 600;
  word-break: break-word;
}

.tooltip-item {
  position: relative;
  z-index: 10;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.inline-tooltip {
  margin-left: 5px;
  font-size: 13px;
  display: inline-block;
}

.profile-snip-tooltip {
  font-size: 11px;
}

.trigger {
  cursor: pointer;

  &:hover span {
    border-bottom: none;
  }
}

.tooltip-list {
  text-align: left;
  display: inline-block;
}

.tooltip-list-item {
  display: block;
}
