.crm-column-popover {
  min-width: 350px;

  .border-top {
    border-top: 1px solid $light-bg;
  }
  .border-bottom {
    border-bottom: 1px solid $light-bg;
  }
}

.crm-popover-section {
  .actions {
    position: relative;
    min-height: 15px;

    .backer {
      position: absolute;
      left: 0px;
    }

    .closer {
      position: absolute;
      right: 0px;
    }
  }

  .small-input {
    line-height: 28px;
    width: 100px;
    padding: 0 8px;
    text-indent: 0;
  }
}
