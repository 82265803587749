.profile-conversations {
  .chatbox {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .chats {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  .conversation-list {
    display: flex;
    padding: 20px;
    gap: 20px;

    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--body {
      margin-top: 20px;
    }
  }

  .conversation-row {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &.failed .conversation-row--message {
      color: red;
    }

    &--message {
      display: flex;
      align-items: center;
      width: 0;
      flex-grow: 1;
      margin-right: 10px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &--date {
      margin-right: 10px;
    }
  }
}

.conversations-table {
  span.failed {
    color: red;
  }
}
