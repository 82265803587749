.welcome {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;
}

.welcome-title {
  font-size: 32px;

  @include media($medium) {
    font-size: 24px;
  }
}

.welcome-callout {
  font-size: 20px;

  @include media($medium) {
    font-size: 16px;
  }

  a {
    @include hyperlink($primary);
  }
}

.notification-item {
  display: flex;
  border-bottom: 1px solid $borders;
  padding: 10px 0;
  gap: 20px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}