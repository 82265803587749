.contact-sent-products {
  padding: 20px;
  display: flex;
  gap: 20px;

  .tab {
    font-weight: 600;
    cursor: pointer;
  }

  .tab-unselected {
    color: $brown-half;
  }

  .delivered-pill {
    background: $primary-lightest;
    color: $primary-darkest;
  }

  .undelivered-pill {
    background: mix($contrast, white, 20%);
    color: mix($contrast, black, 90%);
  }

  .draft-pill {
    background-color: #E6E6F2;
    color: #4A4BFF;
  }

  .error-pill {
    background: mix($warn, white, 20%);
    color: mix($warn, black, 90%);
  }

  .sent-products-table {
    max-width: calc(66vw - 415px);

    @include media($small) {
      max-width: calc(100vw - 100px);
    }
  }
}
