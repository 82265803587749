.dropdown-open:checked ~ .dropdown-inner {
  transform: translateY(190px);
  display: block;

  @include media($medium) {
    display: block;
  }
}

.hidden-dropdown {
  position: relative;

  @include media($desktop-min) {
    width: auto;
    display: block;
  }

  > .label {
    margin-bottom: 0;
  }

  > .dropdown-inner {
    @include hidden-dropdown-inner;
  }

  .active {
    border-bottom: 2px solid $primary;
    border-color: $primary;
  }
}

.hidden-dropdown-squeeze {
  display: none;
  @include media($desktop-min) {
    display: block;
  }
}

.popover {
  .popover-content {
    background: white;
    border: 1px solid $borders;
    border-radius: 6px;
    padding: 10px;
    transition: transform .3s;
    z-index: $modalZ;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);

    .list-item {
      display: block;
      padding: 10px;
      font-weight: 600;
      font-size: 16px;

      .link {
        @include hyperlink($brown);
        cursor: pointer;

        &.active {
          border-bottom: 6px solid $primary;
        }
      }
    }
  }
}
