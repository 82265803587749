.influencer-modal {
  font-weight: normal;
  text-align: left;
  font-size: 16px;
  padding: 0;
  letter-spacing: 0px;
  display: flex;

  @include media($medium) {
    flex-direction: column;
  }

  .profile-top-level {
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid $darker-borders;
    padding: 0;
    position: sticky;
    top: 0;
    z-index: $floatingNav;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: auto;

    .user-basics {
      @include sticky-header;
      padding: 15px 20px;
      width: 300px;
      min-width: 300px;
      background: $beige;
      border-right: 1px solid $darker-borders;
      border-bottom: 1px solid $darker-borders;
    }

    @include media($medium) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      position: relative;

      .user-basics {
        width: 100%;
        padding: 20px;
        justify-content: center;
        align-items: center;
      }

      .user-blurb {
        flex: none;
      }
    }
  }

  .influencer-modal-content {
    display: flex;
    min-height: calc(100vh - 75px);
    width: 100%;

    @include media($medium) {
      flex-direction: column;
    }
  }

  .influencer-modal-body-content {
    &.zoomed-in > *:not(.active-block) {
      display: none;
    }
  }

  .influencer-modal-aside {
    min-width: 300px;
    max-width: 300px;
    color: $brown;
    background-color: $beige;
    font-size: 16px;
    border-right: 1px solid $darker-borders;
    height: 100%;
    min-height: calc(100vh - 74px); // where 74 is the height of the profile header plus border

    .user-container {
      padding: 15px 20px;
      position: sticky;
      top: 73px;
    }

    .user-details, .averages, .traits {
      padding: 0;
      border: 0;
      box-shadow: none;
    }

    .traits {
      margin-top: 10px;
    }

    .stat-label {
      display: flex;
      align-items: center;
    }

    .profile-stat-labels {
      margin-bottom: 20px;
    }

    .stat-count {
      font-size: 18px;
    }

    .profile-stats .stat-count {
      font-size: 16px;
    }

    .engagement-percent-symbol {
      font-size: 16px;
    }

    .view-details-container {
      @include media($medium) {
        background-color: $beige;

        .detail-action-button {
          border: 2px solid $brown-lighter;
        }
      }
    }

    @include media($medium) {
      min-width: 100%;
      max-width: 100%;
      border: none;
      height: auto;
      min-height: auto;
    }
  }

  .influencer-modal-body {
    background-color: white;
    width: 100%;

    &-content {
      display: flex;
      flex-direction: column;

      > * {
        order: 1;
      }
    }
  }
}

.activity-feed {
  display: flex;
  flex-direction: column;
  width: 100%;

  .activity-feed-item {
    display: flex;
    border-bottom: 1px solid $borders;
    padding: 20px 0;
    gap: 20px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .activity-feed-item-media {
    .media-caption {
      margin-top: 0;
      -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
      mask-image: linear-gradient(to bottom, black 80%, transparent 100%);

      @include media($medium) {
        margin-top: 10px;
      }
    }

    @include media($medium) {
      flex-direction: column;
    }
  }
}

.activity-feed-zero-data {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-items: center;
  padding: 80px 40px;
  opacity: 0.7;

  .fa-folder-open {
    margin-bottom: 20px;
  }
}

.influencer-modal-body-header-wrapper {
  @include sticky-header;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background: $beige;
  height: 73px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $darker-borders;

  @include media($medium) {
    width: 100%;
    border-bottom: none;
    border-top: 1px solid $darker-borders;
  }

  @include media($small) {
    flex-direction: column;
  }

  .influencer-modal-body-header {
    @include media($small) {
      flex-direction: column;
    }

    .primary-nav-item {
      padding-right: 20px;
      font-weight: 600;

      &:last-child {
        @include media($small) {
          padding-right: 0;
        }
      }
    }
  }
}

.influencer-modal-body-item-aside {
  min-width: 36px;

  .icon-only {
    font-size: 32px;
  }

  .activity-feed-item-icon {
    display: inline-block;
    padding: 10px;
    line-height: 0px;
    color: white;
    background: $brown;
    border-radius: 100%;
  }
}

.download-file-wrapper {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 24px;

  .download-link {
    color: $brown;
  }
}

.activity-feed-actions {
  color: rgba($brown, 0.6);
  .posted-at-link {
    color: rgba($brown, 0.6);

    &:hover {
      color: rgba($brown, 0.6);
    }
  }

  .download-link {
    color: rgba($brown, 0.6);
    border-bottom: 1px dotted $brown;

    &:hover {
      color: rgba($brown, 0.6);
      border-bottom: none;
    }
  }
}
