.dropzone {
  display: flex;
  flex-direction: column;
  height: 250px;

  &-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 0;
    border: 2px dashed $gray;
    border-radius: 5px;
  }

  &-files {
    flex-grow: 0;
    max-height: 100px;
    overflow-y: auto;
    margin-top: 10px;

    .file-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;

      &:hover {
        .info {
          text-decoration: underline;
        }
      }
    }
  }
}
