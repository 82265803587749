nav.logged-in {
  display: block;
  background-color: #272727;
  float: left;
  height: 100%;
  position: fixed;
  left: 0px;
  width: 200px;
  z-index: $floatingNav;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  color: #fff;
  overflow: hidden;
  padding-bottom: 200px;
  overscroll-behavior: contain;

  &:hover {
    overflow-y: auto;
  }

  @include media($medium) {
    width: 80px;

    .mobile {
      .svg-inline--fa {
        font-size: 24px;
      }
    }
  }

  @include media($small) {
    bottom: 0;
    width: 100vw;
    height: 50px;
    padding: 5px 0;
    overflow-x: scroll;

    .mobile {
      .svg-inline--fa {
        font-size: 16px;
      }
    }

    &:hover {
      overflow-y: hidden;
    }
  }
}

nav.logged-in .nav-list {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  flex-direction: column;

  @include media($small) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  a {
    @include smallcaps;
    font-weight: 500;
    font-size: 13px;
    display: block;
    padding: 15px 20px;
    color: rgba(255, 255, 255, 0.5);
    transition: color 0.3s ease;

    @include media($medium) {
      text-align: center;
      padding: 15px 0;

      .label {
        margin-top: 5px;
      }
    }

    @include media($small) {
      padding: 5px 15px;
      line-height: 11px;
    }

    &:hover {
      color: #fff;
      background-color: scale-color(#272727, $lightness: -1%);
    }

    &.active {
      border-left: 3px solid $primary;
      color: #fff;
      padding: 15px 17px;

      @include media($medium) {
        padding: 15px 3px;
      }

      @include media($small) {
        border-left: none;
        color: $primary;
        padding: 5px 15px;
      }
    }

    &.sub-item {
      padding: 5px 20px;
      text-transform: none;
      font-size: 12px;
      font-weight: 400;

      &.active {
        padding-left: 17px;
      }
    }
  }

  .client-avatar {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.report-details {
  display: inline-block;
  margin-left: 21px;
  width: 110px;
  vertical-align: middle;
}

.report-count {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  text-align: right;
  font-weight: 600;
}
.nav-logo-link:hover {
  .nav-logo {
    transform: rotate(-10deg);
  }
}
.nav-logo {
  transition-duration: 0.15s;
}

.logo {
  margin: 20px 0 40px 20px;

  a {
    @include logo-typography;
  }

  @include media($small) {
    display: none;
  }
}

.whitelabel-logo-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.whitelabel-logo {
  max-width: 160px;
  max-height: 60px;
}

nav.logged-in {
  .whitelabel-logo {
    max-height: 160px;

    @include media($medium) {
      max-width: 40px;
    }
  }
}

nav.logged-out {
  width: 100%;
  max-width: 100%;
  min-height: 60px;
  height: 60px;
  z-index: $floatingNav;
  position: absolute;
  top: 0;
  padding: 0 15px;
  // background: white;

  .logged-out-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1170px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    @include media($medium) {
      margin: 0;
    }
  }

  .full-width-nav {
    max-width: 100%;
  }

  .home {
    font-weight: 700;

    a {
      color: $brown;
      display: flex;
      align-items: center;

      @include media($small) {
        height: auto;
      }
    }

    @include media($small) {
      float: none;
      display: block;
      text-align: center;
    }
  }

  .nav-items {
    .nav-item {
      line-height: 1;
      padding: 10px 10px;
      display: inline-block;
      font-weight: 600;

      &:last-of-type {
        padding-right: 0;
      }
    }

    a {
      color: $brown;

      &:hover {
        color: mix($brown, black, 60%);
      }

      &.active {
        border-bottom: 4px solid $primary;
      }
    }

    .nav-btn {
      color: #fff;
      @include button-pill($contrast, 15px);
    }
  }
}

nav.logged-out.inverted .nav-items a.active {
  border-bottom: 4px solid white;
}

nav.logged-out.inverted {
  .home a {
    color: white;
  }

  .nav-items a {
    color: white;

    &:hover {
      opacity: 0.7;
    }
  }
  .hover-dropdown a {
    color: $brown;
  }

  .nav-items a.nav-btn {
    color: white;

    &:hover {
      opacity: 1;
    }
  }
}

nav.logged-out.action-primary {

  .nav-btn {
    color: #fff;
    @include button-pill($primary, 15px);
  }

}

nav.logged-out.is-fixed {
  position: fixed;
  border-bottom: none;
  background: white;
  box-shadow: 0 1px 1px rgba(0,0,0,.15);
  animation: slide-down 420ms cubic-bezier(.165,.84,.44,1);
}

/*nav.logged-out.inverted .home a*/

nav.logged-out.is-fixed {
  .home a {
    color: $brown !important;
  }
  .nav-items a {
    color: $brown;
    &:hover {
      color: mix($brown, black, 60%);
    }
  }
  .nav-items a.nav-btn {
    color: #fff;
    @include button-pill($primary, 15px);
  }
}

nav.logged-out.is-fixed .nav-items a.active {
  border-bottom: 4px solid $primary !important;
}

nav.logged-out.inverted.is-fixed .nav-items .nav-btn:hover {
  opacity: 1;
}

.light-link {
  &:hover {
    color: #fff;
    opacity: 0.7;
  }
}

.dark-link {
  &:hover {
    color: #333;
    opacity: 0.7;
  }
}

.home {
  a {
    display: block;
    font-size: 18px;
    color: white;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
}

.user-detail {
  @include truncate(inherit);
  padding-left: 20px;
  font-size: 11px;
  font-weight: 300;
}

@keyframes slide-down {
  0% { 
    translateY: -100%; 
  }
  100% { 
    translateY: 0%;
  }
}

.hover-nav-item {
  position: relative;
}

.hover-nav-item:hover {
  .hover-dropdown {
    visibility: visible;
    opacity: 1;
  }
}

.hover-dropdown:hover {
  .hover-dropdown {
    visibility: visible;
    opacity: 1;
  }
}

.hover-bottom-padding {
  padding-bottom: 30px;
}

.hover-dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background: #fff;
  top: 38px;
  left: 0;
  width: 760px;
  border-radius: 10px;
  box-shadow: $card-box-shadow;
  transition: visibility 0s, opacity 0.15s ease-in-out;

  .dropdown-top-section {
    padding: 20px 20px 0px;
  }
  .dropdown-second-section {
    padding: 20px;
    background: $beige;
    border-radius: 0 0 10px 10px;
  }

  .dropdown-inner-section-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: column;
    grid-template-rows: repeat(4, 40px);
    margin-top: .75rem;
  }

  .dropdown-inner-section-bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: column;
    grid-template-rows: auto;
    grid-auto-flow: row;
    row-gap: 1rem;
    margin-top: .75rem;
  }

  .hover-dropdown-link {
    @include hyperlink($brown);
  }

  .c-header__description-top {
    text-align: left;
    font-size: 12px;
  }
}

.dropdown-inner-home-nav {
  overflow: hidden;
}
.dropdown-inner-container {
  width: 430px;
  display: flex;
  transition: ease-in-out 0.3s;
}
.dropdown-inner-container.slide-left {
  transform: translateX(-198px);
}

.dropdown-inner-seconardary-window{
  max-width: 200px;
  padding-top: 38px;
  padding-left: 10px;
  position: relative;
  height: 350px;
  overflow-y: scroll;
}
.fixed-menu-bar-item {
  position: fixed;
  top: 0;
  background: #fff;
  width: 100%;
}
.mobile-feature-nav-item {
  position: relative;
}
.feature-caret {
  position: absolute;
  right: 8px;
  top: 13px;
}