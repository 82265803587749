.magnet-call-out-container {
  display: flex;
  flex-direction: column;
  max-width: 210px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #eee;
  box-shadow: 0px 1px 5px rgba(0,0,0,.15);
  position: fixed;
  bottom: 100px;
  animation: transform .5s ease-in-out;
  transition-duration: .5s;
  z-index: 1;

  .flash {
    margin-bottom: .5rem;
    display: none;
  }
  .flash.show {
    display: block;
  }
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }
  .content-success-msg {
    display: none;
    color: #4DAF7C;
    font-weight: 600;
  }
  .content-success-msg.show {
    display: block;
  }
  .image-container {
    width: 100%;
    
    .image-inner-container {
      position: relative;
      overflow: hidden;
      height: 120px;
    }
    video {
      width: 208px;
      height: 120px;
      object-fit: fill;
    }
  }
  
  .content-body {
    padding: 12px;
  }
  
  .content-body p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  
  .content-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    opacity: .75;
  }
  
  .content-description {
    padding-top: 0;
    margin-top: .5em;
    font-weight: 500;
  }
  
  .cta-email-capture {
    margin-top: .5em;
  }

  .cta-email-capture.hide {
    display: none;
  }

  .cta-view-content {
    margin-top: .5em;
    display: none;
  }

  .cta-view-content.show {
    display: block;
  }
  
  .input-cta {
    width: 100%;
    margin: 0;
    margin-bottom: .25em;
    font-size: 16px;
    height: 30px;
  }
  .input-cta.invalid {
    border: 1px solid #ff0000;
  }
  
  .button {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 30px;
    line-height: 26px;
  }

  .button-marketing:disabled {
    opacity: .75;
  }

}

.magnet-left {
  left: 6px;
  transform: translateX(-110%);
  -webkit-transform: translateX(-110%);
}

.magnet-right {
  right: 6px;
  transform: translateX(110%);
  -webkit-transform: translateX(110%);
}

.magnet-call-out-container.show {
  transition-duration: .5s;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
}



@media screen and (max-width: 480px) {
  .magnet-call-out-container {
    max-width: 100%;
    border-radius: 0;
    bottom: 0;
    left: 0;
    .image-container {
      display: none;
    }
    .close-btn {
      color: #5B5147;
    }
  }   
}
